import React from "react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "antd";

// Icons
import { AlertIcon } from "../../../icons";
import "./confirmationModal.scss";
import { NbButton } from "../CustomButton";
import { persistor, store } from "../../../../store/createStore";

const { confirm, destroyAll } = Modal;

export default function ConfirmModal(props, cb) {
  const { title, des, style } = props;
  return confirm({
    className: "confirm-alert",
    title: (
      <div className="title">
        <div className="icon">
          <AlertIcon />
        </div>
        <span>{title}</span>
      </div>
    ),
    icon: true,
    content: des,
    closable: true,
    footer: (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="display-flex pt2 justify-content-center">
            <NbButton
              type="outlined"
              className="nb-button"
              key="back"
              onClick={() => {
                cb(true);
                destroyAll();
              }}
              btnText="Confirm"
            />
          </div>
        </PersistGate>
      </Provider>
    ),
    onCancel() {
      cb(false);
    },
  });
}

ConfirmModal.defaultProps = {
  icon: false,
};

ConfirmModal.propTypes = {
  icon: PropTypes.bool,
};
