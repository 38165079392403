import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, Alert, Checkbox } from "antd";

// scss
import "./loginForm.scss";
import { NbButton } from "../../assets/gobal/components/CustomButton";
import ComposeAlert from "../composeAlert/composeAlert";
import { formattedFromNow, getDateObject } from "../../utils";
import config from "../../config";

export default function LoginForm({
  onFinish,
  apiResponse,
  loading,
  className,
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    setShowAlert(
      ["suspended", "deactivated", "banned"].includes(
        apiResponse?.data?.user_state
      )
    );
    setRememberMe(localStorage.getItem("remember_me") === "true" || false);
  }, [apiResponse]);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    // console.log(getDateObject().unix() < getDateObject().add(5, "m").unix());
    // localStorage.setItem("remember_me", e.target.checked);
  };

  return (
    <div className={`login ${className}`}>
      <h4 className="login-title">Welcome to Communities</h4>
      <p className="inline-block mb5 login-link">Please login to continue</p>
      {apiResponse?.message &&
        !["suspended", "deactivated"].includes(
          apiResponse?.data?.user_state
        ) && (
          <Alert
            className="mb4"
            type="error"
            showIcon
            message={apiResponse?.message}
          />
        )}
      <Form
        layout="vertical"
        name="login"
        validateTrigger={["onSubmit"]}
        onFinish={onFinish}
      >
        <Form.Item
          label="Enter Email  Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email address",
            },
            {
              type: "email",
              message: "Email is invalid",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Email  Address"
            className="nb-text-input"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            className="nb-text-input"
            size="large"
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <NbButton
            className="nb-button"
            // loading={loading}
            block
            textCenter
            type="primary"
            htmlType="submit"
            btnText="Login"
          />
        </Form.Item>
      </Form>
      <div>
        <div>
          <Checkbox checked={rememberMe} onChange={handleRememberMe} /> Remember
          me
        </div>
      </div>
      <ComposeAlert
        status={apiResponse?.data?.user_state}
        visible={showAlert}
        days={formattedFromNow(
          apiResponse?.data?.user_state_end_period_date,
          config.suspensionOffset
        )}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    </div>
  );
}

LoginForm.defaultProps = {
  apiResponse: null,
  className: "",
};

LoginForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  apiResponse: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.object),
  ]),
  className: PropTypes.string,
};
