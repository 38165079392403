import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { AlertIcon } from "../../assets/icons";
import { NbButton } from "../../assets/gobal/components/CustomButton";

// scss
import "./composeAlert.scss";

function ComposeAlert({ status, days, visible, onCancel, destroyOnClose }) {
  const titles = {
    silence: "Attention you have been Silenced!",
    suspended: "Attention your account has been Suspended!",
    deactivated: "Attention your account has been Banned!",
  };

  const title = titles[status];

  const messages = {
    silence: `Your posting privileges have been temporarily suspended due to a breach of our community guidelines. For the next ${days}, you will be unable to post, comment, or respond to comments. After this period, your account functionalities will be automatically restored.`,
    suspended: `Your account has been temporarily restricted due to a violation of our community guidelines. In ${days}, full access to your account will be automatically restored.`,
    deactivated: `Your account has been permanently deactivated due to a violation of our community guidelines. You no longer have access to your Community Account.`,
  };

  const message = messages[status];

  return (
    <Modal
      className="compose-alert"
      title={
        <>
          <div className="icon">
            <AlertIcon />
          </div>
          {title}
        </>
      }
      open={visible}
      closable={false}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      footer={[
        <NbButton
          type="outlined"
          className="nb-button"
          key="back"
          onClick={onCancel}
          btnText="Close"
        />,
      ]}
    >
      <p>{message}</p>
      <p>
        If you have questions or need more information, please contact
        <a href="mailto:communitysupport@ccplatform.com">
          {" "}
          communitysupport@ccplatform.com
        </a>
        .
      </p>
    </Modal>
  );
}
export default ComposeAlert;

ComposeAlert.defaultProps = {
  status: "silenced",
  days: "7 days",
  onCancel: () => null,
  destroyOnClose: true,
};

ComposeAlert.propTypes = {
  status: PropTypes.string,
  days: PropTypes.string,
  onCancel: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  destroyOnClose: PropTypes.bool,
};
