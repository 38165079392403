import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// components
import AuthGuard from "../../authGuard/authGuard";

// icons
import {
  Like,
  Comment,
  LikeFilled,
  Heart,
  HeartFilled,
  Clap,
  Pensive,
} from "../../../assets/icons/post";

// scss
import "./reactionButtons.scss";

function ReactionButtons({
  style,
  className,
  onClickComment,
  isLiked,
  likesCount,
  commentCount,
  onReact,
  showComment,
  onUnReact,
  commentButton,
  uniqueReacts,
  commentsDisabled,
  children,
}) {
  const [reaction, setReaction] = useState(isLiked);
  const [count, setCount] = useState(likesCount);
  const topReacts = uniqueReacts?.sort((a, b) => b.count - a.count);
  const emojis = useRef([
    { icon: HeartFilled, key: "heart" },
    { icon: LikeFilled, key: "thumbs_up" },
    { icon: Clap, key: "clap" },
    // { icon: Tada, key: "tada" },
    { icon: Pensive, key: "pensive" },
  ]);
  const emoji = [
    { icon: HeartFilled, name: "heart" },
    { icon: LikeFilled, name: "thumbs_up" },
    { icon: Clap, name: "clap" },
    { icon: Pensive, name: "pensive" },
  ];
  const topReactsData = emoji.filter((data) =>
    topReacts?.some((topReact) => topReact.name === data.name)
  );

  const getReaction = (key) => {
    if (!key) {
      return <Heart className="post-reaction-button-icons mr1" />;
    } else {
      const selectedEmoji = emojis.current.find((value) => value.key === key);
      const Emoji = selectedEmoji?.icon;
      return <Emoji className="post-reaction-button-icons mr1" />;
    }
  };

  const onReactLocal = (key, reactionCase) => {
    if (reactionCase === "thumb_reaction") {
      if (reaction) {
        setCount(count - 1);
        setReaction(null);
        onUnReact();
      } else {
        setCount(count + 1);
        setReaction(key);
        onReact(key);
      }
    } else if (reaction) {
      setReaction(key);
      onReact(key);
    } else {
      setCount(count + 1);
      setReaction(key);
      onReact(key);
    }
  };
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <div className={`post-reaction-button ${className}`} style={style}>
      <div
        className="nb-faded-text fn8 c-pointer post-like-button"
        style={{ color: customTheme.primary_color }}
      >
        <button
          type="button"
          className="nb-proxy-button c-pointer"
          onClick={() => {
            onReactLocal("heart", "thumb_reaction");
          }}
        >
          {getReaction(reaction)}
          {topReactsData.length > 0 && (
            <div className="post-reacts-emoji display-flex align-items-center mx1">
              <span>{count === 0 ? null : count}</span>
            </div>
          )}
        </button>
        <div className="post-reacts-emojis">
          {emojis.current.map(({ icon: Emoji, key }) => {
            return (
              <Emoji
                key={`emoji-${key}`}
                onClick={() => {
                  onReactLocal(key, "emoji_reaction");
                }}
                className="post-reaction-button-icons mr1"
              />
            );
          })}
        </div>
      </div>
      {commentButton ||
        (showComment && (
          <div
            className="nb-faded-text fn8 ml1 c-pointer"
            style={{ color: customTheme.primary_color }}
          >
            <AuthGuard
              authActions={{
                not_verified: {
                  action: "BECOME_VERIFIED",
                },
              }}
            >
              <button
                type="button"
                onClick={onClickComment}
                className={` nb-proxy-button  ${
                  commentsDisabled ? "c-not-allowed" : "c-pointer"
                }  `}
                title={commentsDisabled ? "Comments has been Disabled" : ""}
              >
                <Comment className="post-reaction-button-icons mr1" />{" "}
                <span className="mx1">
                  {commentCount === 0 ? null : commentCount}
                </span>
              </button>
            </AuthGuard>
          </div>
        ))}
      {children}
    </div>
  );
}

export default ReactionButtons;

ReactionButtons.defaultProps = {
  style: {},
  className: "",
  isLiked: false,
  likesCount: 0,
  showComment: true,
  commentButton: undefined,
  commentsDisabled: false,
};

ReactionButtons.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  onClickComment: PropTypes.func.isRequired,
  onReact: PropTypes.func.isRequired,
  onUnReact: PropTypes.func.isRequired,
  isLiked: PropTypes.any,
  likesCount: PropTypes.number,
  showComment: PropTypes.bool,
  commentButton: PropTypes.element,
  commentsDisabled: PropTypes.bool,
};
