import React, { useState, useEffect, memo } from "react";
import { Dropdown, Badge, Menu } from "antd";
import { useSelector } from "react-redux";
// import { ReactComponent as BellIcon } from "../../../icons/notification.svg";
import BellIcon from "../../../icons/notification.svg";
import NotificationsService from "../../../../api/services/notifications/notification";
import { getEchoInstance } from "../../../../utils";
import NotificationListing from "./notificationListing";
import "./header.scss";
import { MobileMenu } from "../MobileMenu/MobileMenu";

function Notification() {
  const me = useSelector((state) => state.me);
  const [unreadCount, setUnReadCount] = useState(0);
  const NotificationCount = async () => {
    try {
      const res = await NotificationsService.getNotificationListing();
      setUnReadCount(res.data.unread_count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    NotificationCount();
  }, []);
  const handleNotificationCount = (data) => {
    NotificationCount();
  };
  const echo = getEchoInstance();
  useEffect(() => {
    echo
      ?.private(`notification-${me?.user_id}`)
      ?.listen(".new-notification", handleNotificationCount);
  }, []);

  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <Dropdown
      overlayClassName="notification-container"
      overlay={
        <NotificationListing
          setUnReadCount={setUnReadCount}
          unreadCount={unreadCount}
        />
      }
      trigger={["click"]}
      placement="bottomRight"
    >
      <a
        href="#"
        onClick={(e) => e.preventDefault()}
        className="notification-dropdown"
      >
        <Badge
          offset={[58, 3]}
          count={unreadCount}
          style={{ background: customTheme.secondary_color, height: "18px" }}
        >
          <div key="notification" className="">
            <img
              className="notification-icon"
              src={BellIcon}
              alt="notification_bell"
            />
          </div>
        </Badge>
      </a>
    </Dropdown>
  );
}

export default memo(Notification);
