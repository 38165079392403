import React from "react";
import { Layout } from "antd";
import "./listingLayout.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MobileNavigation from "../../../../pages/home/mobileNavigation/mobileNavigation";
import { slugify, useProfileSwitchingGuard } from "../../../../utils";
import { getMenuLists } from "../../../../pages/home/homeSider/homeSiderMenuLists";

const { Sider, Content } = Layout;

function ListingLayout({
  children,
  leftSider,
  header,
  className,
  contentClassName,
}) {
  const switchingGuard = useProfileSwitchingGuard();
  const me = useSelector((state) => state.me);
  const { menuListForAllVerified } = getMenuLists();
  const history = useHistory();

  const getFormattedMenuListCommunities = (communities) => {
    return communities?.map((community) => {
      const communitySlug = slugify(community.name);
      return {
        key: community.neighbourhood_id,
        label: community.name,
        to: `/home/${communitySlug}/${community.neighbourhood_id}`,
        icon: "",
        // antd menu Props
        moreOptions: {
          items: [
            {
              key: `0${community.neighbourhood_id}`,
              label: "View Community Members",
              onClick: () => {
                history.push({
                  pathname: `/community-members`,
                  search: `?community_id=${community.neighbourhood_id}`,
                });
              },
            },
            {
              key: `1${community.neighbourhood_id}`,
              label: "Leave Community",
              onClick: () => {
                leaveCommunity(community.neighbourhood_id);
              },
            },
          ],
        },
      };
    });
  };

  return (
    <Layout className={`nb-listing-layout ${className ?? ""}`}>
      {leftSider?.component && (
        <Sider
          className="nb-listing-layout-body-left"
          width="320px"
          breakpoint="xxl"
          collapsedWidth="0"
        >
          {leftSider?.component}
        </Sider>
      )}

      <Layout className="nb-listing-layout-body">
        {header}
        {children && (
          <div className="nb-listing-layout-main-content p0">
            <div className="global-shadow-background-page nb-full-screen display-flex justify-content-center">
              <Content
                className={`${leftSider ? "" : "pl0"} ${
                  contentClassName ?? ""
                }`}
              >
                {children}
              </Content>
            </div>
          </div>
        )}
      </Layout>
      {switchingGuard.isUser() && (
        <MobileNavigation
          menuListForAllVerified={menuListForAllVerified}
          communityList={getFormattedMenuListCommunities(me.communities)}
        />
      )}
    </Layout>
  );
}

export default ListingLayout;
