/* eslint-disable react/no-danger */
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import PropTypes from "prop-types";

function NbAvatar({ profileImage, firstName, lastName, size, className }) {
  const avatarName = `${firstName?.charAt(0)?.toUpperCase()}${lastName
    ?.charAt(0)
    ?.toUpperCase()}`;
  const customTheme = useSelector((state) => state.themeConfiguration);

  return profileImage?.trim()?.length > 0 ? (
    <Avatar
      style={{
        background: customTheme.secondary_color,
        marginRight: "5px",
      }}
      src={profileImage}
      size={size}
      className={className}
    />
  ) : (
    <Avatar
      style={{
        background: customTheme.secondary_color,
        marginRight: "5px",
      }}
      size={size}
      className={className}
    >
      {avatarName}
    </Avatar>
  );
}

export default NbAvatar;

NbAvatar.defaultProps = {
  profileImage: "",
  firstName: "",
  lastName: "",
  size: undefined,
  className: "",
};

NbAvatar.propTypes = {
  profileImage: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};
