import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Mention, MentionsInput } from "react-mentions";
import classNames from "./mentions.module.scss";
import postApis from "../../../../api/services/post/post";
import { checkIfScrolledToBottom } from "../../../../utils";

function Suggestions({ children, getSuggestions, pagination }) {
  const handleScroll = (e) => {
    if (e.target) {
      if (checkIfScrolledToBottom(e.target) && pagination.next_page_url) {
        getSuggestions((pagination?.current_page || 0) + 1);
      }
    }
  };

  return (
    <div
      style={{ maxHeight: "150px", overflowY: "auto" }}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
}

export default function Mentions({
  onChange,
  name,
  value,
  readOnly,
  maxLength,
}) {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const customTheme = useSelector((state) => state.themeConfiguration);
  const setSuggestions = useRef();
  const [pagination, setPagination] = useState(null);

  const getHostData = async (params, callback) => {
    try {
      const res = await postApis.getHostList(params);
      const { data, ...paginationData } = res.data;
      setPagination({ ...paginationData });
      const updatedUsers = [
        ...(paginationData.current_page > 1 ? users : []),
        ...data.map((u) => ({
          display: `@${u.first_name} ${u.last_name}`,
          id: u.user_id,
        })),
      ];
      setUsers(updatedUsers);
      callback(updatedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchingElement = (e, callback, page = 1) => {
    if (e.length > 2) {
      const searchUrl = `${"/user/neighbours"}?q=${e}&page=${page}`;
      setSearch(e);
      getHostData(searchUrl, callback).catch((error) => {
        console.log(error);
      });
    } else if (e.length === 0) {
      // clear the input
      setSearch("");
      getHostData("", callback).catch((error) => {
        console.log(error);
      });
    }
  };

  const displayTransform = (id, display) => {
    return users.find((u) => id === u?.id)?.display || display;
  };

  const customSuggestions = (children) => (
    <Suggestions
      getSuggestions={(page) => {
        console.log(setSuggestions.current);
        onSearchingElement(search, setSuggestions.current, page);
      }}
      pagination={pagination}
      users={users}
    >
      {children}
    </Suggestions>
  );

  return (
    <MentionsInput
      onChange={(event, newValue, newPlainTextValue, mentions) => {
        // console.log({ event, newValue, newPlainTextValue, mentions });
        if (maxLength && event.target.value?.length > maxLength) {
          return;
        }
        onChange({
          target: {
            value: event.target.value,
            name,
            mentions: mentions.map((m) => ({
              relatable_type: "user",
              relatable_id: m.id,
            })),
          },
        });
      }}
      value={value}
      style={{
        suggestions: {
          item: {
            "&focused": {
              backgroundColor: customTheme.primary_color || "primary_color",
            },
          },
        },
      }}
      classNames={classNames}
      customSuggestionsContainer={customSuggestions}
      allowSpaceInQuery
      readOnly={readOnly}
    >
      <Mention
        trigger="@"
        data={(searchValue, callback) => {
          setSuggestions.current = callback;
          onSearchingElement(searchValue, callback);
        }}
        markup="@[__display__](__id__)"
        displayTransform={displayTransform}
        className={classNames.mentions__mention}
        // style={{ color: customTheme.primary_color || "primary_color" }}
        style={{ color: "black" }}
      />
    </MentionsInput>
  );
}
