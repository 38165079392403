/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// components
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

// hooks
import { slugify } from "../../../utils/helpers";

// scss
import "../../../assets/gobal/components/nbVertocalSideMenu/nbVerticalSideMenu.scss";
import "./mobileNavigation.scss";
import { NbButton } from "../../../assets/gobal/components/CustomButton";

import { DirectionLeftIcon, DirectionRightIcon } from "../../../assets/icons";

function Header({ onBack }) {
  return (
    <div className="mobile-navigation-header">
      <NbButton type="link" icon={DirectionLeftIcon} onClick={onBack} />
    </div>
  );
}

function MobileNavigation({ menuListForAllVerified, communityList }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [more, setMore] = useState(false);
  const [moreItemData, setMoreItemData] = useState([]);

  const handleCommunityClick = () => {
    setOpen(!open);
  };

  const handleMore = (items) => {
    setMore(true);
    setMoreItemData([...items]);
  };

  return (
    <div className="navigation">
      {menuListForAllVerified[0].menuList.list.map((menuListItem, i) => {
        const { icon: Icon, to, label, mobileIcon: MobileIcon } = menuListItem;
        let isSelected = false;
        let menuData = label;
        if (menuData.toLowerCase() === "all activities") {
          menuData = "all";
        }
        const paths = history.location.pathname.split("/").slice(0, 3);
        paths.forEach((slug) => {
          if (!isSelected && slug.trim()) {
            isSelected = slugify(menuData.toLowerCase()).includes(
              slug.toLowerCase()
            );
          }
        });

        if (to === "/communities") {
          return (
            <div className={`${isSelected ? `nav-item-active` : ""}`}>
              <div
                role="button"
                className="nav-item"
                tabIndex={0}
                onClick={handleCommunityClick}
                onKeyUp={handleCommunityClick}
              >
                <Icon />
              </div>
            </div>
          );
        }

        return (
          <div className={`${isSelected ? `nav-item-active` : ""}`}>
            <Link to={to || "#"} className="nav-item">
              {MobileIcon ? <MobileIcon /> : <Icon />}
            </Link>
          </div>
        );
      })}
      <BottomSheet
        open={open}
        onDismiss={handleCommunityClick}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 5,
          maxHeight * 0.6,
        ]}
        expandOnContentDrag
        header={
          more && (
            <Header
              onBack={() => {
                setMore(false);
              }}
            />
          )
        }
      >
        {!more && (
          <>
            <div className="community-item">
              <Link className="nb-base-text" to="/communities">
                Communities
              </Link>
            </div>
            {communityList?.map((menuItem, index) => {
              const { moreOptions } = menuItem;
              return (
                <div className="community-item">
                  <Link
                    className="nb-base-text"
                    to={menuItem.to ? menuItem.to : "#"}
                  >
                    {menuItem.label}
                  </Link>
                  {moreOptions && (
                    <NbButton
                      type="link"
                      icon={DirectionRightIcon}
                      onClick={() => handleMore(moreOptions.items)}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
        {more &&
          moreItemData.map((options) => {
            return (
              <div className="community-item">
                <NbButton
                  btnText={options.label}
                  type="link"
                  onClick={() => {
                    handleCommunityClick();
                    options.onClick();
                  }}
                />
              </div>
            );
          })}
      </BottomSheet>
    </div>
  );
}

export default MobileNavigation;
