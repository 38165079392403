import React from "react";
import PropTypes from "prop-types";

// components
import PostSaveButton from "../../../assets/gobal/components/postSaveButton/postSaveButton";
import ReactionButtons from "../reactionButtons/reactionButtons";
import AuthGuard from "../../authGuard/authGuard";

function PostActions({
  style,
  className,
  onClickComment,
  isLiked,
  likesCount,
  onReact,
  onUnReact,
  onSavePost,
  onUnSavePost,
  isSaved,
  showSave,
  uniqueReacts,
  commentsDisabled,
  commentCount,
}) {
  return (
    <div
      className={`post-actions display-flex pt3 apply-top-border-grey ${className}`}
      style={style}
    >
      <ReactionButtons
        likesCount={likesCount}
        isLiked={isLiked}
        onClickComment={onClickComment}
        onReact={onReact}
        onUnReact={onUnReact}
        uniqueReacts={uniqueReacts}
        commentsDisabled={commentsDisabled}
        commentCount={commentCount}
      >
        {showSave && (
          <div className="display-flex align-items-end">
            <AuthGuard
              authActions={{
                not_verified: {
                  action: "BECOME_VERIFIED",
                },
              }}
            >
              <PostSaveButton
                onUnSave={() => onUnSavePost()}
                onSave={() => onSavePost()}
                save={isSaved}
              />
            </AuthGuard>
          </div>
        )}
      </ReactionButtons>
    </div>
  );
}

export default PostActions;

PostActions.defaultProps = {
  style: {},
  className: "",
  isLiked: false,
  likesCount: 0,
  showSave: true,
};

PostActions.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  onClickComment: PropTypes.func.isRequired,
  onReact: PropTypes.func.isRequired,
  onUnReact: PropTypes.func.isRequired,
  isLiked: PropTypes.any,
  likesCount: PropTypes.number,
  onSavePost: PropTypes.func.isRequired,
  onUnSavePost: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  showSave: PropTypes.bool,
};
