import React from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import PropTypes from "prop-types";

// components
import NbSimpleCard from "../../assets/gobal/components/nbSimpleCard/nbSimpleCard";
import { NbButton } from "../../assets/gobal/components/CustomButton";

function PostHidden({ className, message, onUnHide }) {
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <NbSimpleCard
      className={`nb-post-hidden p4 mb4 display-flex justify-content-space-between align-items-center ${className}`}
    >
      <p className="mb0">{message}</p>
      {onUnHide && (
        <NbButton type="primary" className="nb-button" onClick={onUnHide} btnText="Undo" />
      )}
    </NbSimpleCard>
  );
}

export default PostHidden;

PostHidden.defaultProps = {
  className: "",
  message: "This post has been hidden",
  onUnHide: undefined,
};

PostHidden.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onUnHide: PropTypes.func,
};
