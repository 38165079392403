import React from "react";
import { useProfileSwitchingGuard } from "../../../utils";

// components
import AuthGuard from "../../authGuard/authGuard";
import PostActions from "../postActions/postActions";
import PostCommentForm from "../postCommentForm/postCommentForm";
import PostComments from "../postComments/postComments";

function PostBottomSection({
  // * post actions
  isLiked,
  likesCount,
  commentCount,
  onClickComment,
  onReact,
  onUnReact,
  isSaved,
  onSavePost,
  onUnSavePost,
  showSave,
  uniqueReacts,
  // * PostCommentForm
  isShowComments,
  isCommentDisabledLocal,
  onComment,
  isLoading,
  apiResponse,
  // * PostComments
  onReplyComment,
  onReactComment,
  onUnReactComment,
  onDeleteComment,
  // added for delete single reply
  onDeleteReply,
  onReportComment,
  comments,
  onViewMore,
  isShowViewMore,
  onViewMoreReply,
  isShowViewMoreReplies,
  me,
  commentsDisabled,
}) {
  const switchingGuard = useProfileSwitchingGuard();

  return (
    <AuthGuard
      authActions={{
        public: {
          action: "HIDE",
        },
      }}
    >
      <PostActions
        isLiked={isLiked}
        likesCount={likesCount}
        onClickComment={onClickComment}
        onReact={onReact}
        onUnReact={onUnReact}
        isSaved={isSaved}
        onSavePost={onSavePost}
        onUnSavePost={onUnSavePost}
        className="px4 mt2"
        showSave={showSave}
        uniqueReacts={uniqueReacts}
        commentsDisabled={commentsDisabled}
        commentCount={commentCount}
      />
      {isShowComments && (
        <>
          {!isCommentDisabledLocal && (
            <PostCommentForm
              onComment={onComment}
              isLoading={isLoading}
              apiResponse={apiResponse}
              className="px4"
            />
          )}
          <PostComments
            onReplyComment={onReplyComment}
            onReactComment={onReactComment}
            onUnReactComment={onUnReactComment}
            onDeleteComment={onDeleteComment}
            // added for delete single reply
            onDeleteReply={onDeleteReply}
            onReportComment={onReportComment}
            comments={comments}
            onViewMore={onViewMore}
            isShowViewMore={isShowViewMore}
            onViewMoreReply={onViewMoreReply}
            isShowViewMoreReplies={isShowViewMoreReplies}
            me={me}
            commentsDisabled={commentsDisabled}
          />
        </>
      )}
    </AuthGuard>
  );
}

export default PostBottomSection;
