import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// scss
import "./nbSimpleCard.scss";

function NbSimpleCard({
  className,
  style,
  children,
  fluid,
  borderType,
  onClick,
}) {
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <div
      className={`nb-simple-card
      ${borderType === "simple" ? "" : "nb-simple-card-primary-border"}
      ${className}`}
      style={{
        ...style,
        ...(!fluid && { padding: "1rem" }),
        borderColor:
          borderType === "simple"
            ? ""
            : customTheme.primary_color || "nb-simple-card-primary-border",
      }}
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyDown={(event) => event.key === "Enter" && onClick()}
    >
      {children}
    </div>
  );
}

export default NbSimpleCard;

NbSimpleCard.defaultProps = {
  style: undefined,
  className: "",
  children: undefined,
  fluid: false,
  borderType: "simple",
  onClick: () => null,
};

NbSimpleCard.propTypes = {
  style: PropTypes.instanceOf(PropTypes.object),
  className: PropTypes.string,
  borderType: PropTypes.oneOf(["simple", "primary"]),
  children: PropTypes.any,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
};
