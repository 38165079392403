import React from 'react';

function CCIcon({ fillColor }) {
  return (
    <svg width="79" height="38" viewBox="0 0 79 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.8811 0H39.119V38H39.8811V0Z" fill={fillColor} />
      <path d="M76.0139 13.0764V12.4619C76.0139 9.02073 73.2652 6.14492 69.7669 6.09576C67.8803 6.03431 64.5444 6.03431 59.7966 6.03431H59.4593C56.5357 6.03431 54.0743 6.03431 52.1752 6.09576C48.6769 6.15721 45.9282 9.02073 45.9282 12.4619V25.489C45.9282 28.8687 48.2146 31.6339 53.4371 31.9166C56.0359 31.9043 55.0239 31.9043 59.7966 31.9166C64.5569 31.9289 67.9302 31.8551 69.8293 31.806C71.5535 31.7445 73.0403 31.13 74.2398 29.8888C75.4392 28.6475 76.0764 27.185 76.0764 25.489V25.4276H79C79 25.8577 78.9875 26.2879 78.9375 26.718C78.7626 28.8073 77.9005 30.663 76.3013 32.2361C74.702 33.7601 72.8654 34.6081 70.7414 34.7187C67.468 34.8907 63.8072 35.0013 59.9091 35.0013H59.7966C57.3353 35.0013 54.4616 34.8907 51.2507 34.7187C49.1267 34.6081 47.3025 33.7601 45.6908 32.187C44.0791 30.6139 43.2295 28.8073 43.1045 26.718C42.9296 24.1249 42.8796 21.7038 42.8796 19.3933V19C42.8796 14.9444 42.9421 12.6339 42.9921 12.0071C43.2795 9.63522 44.1415 7.66885 45.5159 6.14492C47.0652 4.39977 48.9518 3.49033 51.2507 3.33056C54.6366 3.10935 57.4352 2.98645 59.7966 2.98645C63.7572 2.98645 67.418 3.09706 70.6914 3.33056C72.8154 3.50262 74.6521 4.35062 76.2513 5.92371C77.863 7.44764 78.7126 9.25424 78.8875 11.3312C78.95 12.1178 78.95 12.6831 79 13.0764H76.0139Z" fill={fillColor} />
      <path d="M33.1343 13.0763V12.4618C33.1343 9.02067 30.3856 6.14486 26.8872 6.0957C25.0006 6.03425 21.6647 6.03425 16.917 6.03425H16.5796C13.656 6.03425 11.1947 6.03425 9.29559 6.0957C5.79725 6.15715 3.04855 9.02067 3.04855 12.4618V25.489C3.04855 28.8687 5.33497 31.6339 10.5575 31.9165C13.1563 31.9042 12.1442 31.9042 16.917 31.9165C21.6772 31.9288 25.0506 31.8551 26.9497 31.8059C28.6739 31.7445 30.1607 31.13 31.3601 29.8887C32.5595 28.6474 33.1967 27.185 33.1967 25.489V25.4275H36.1204C36.1204 25.8577 36.1079 26.2878 36.0579 26.718C35.883 28.8072 35.0209 30.663 33.4216 32.2361C31.8224 33.76 29.9858 34.608 27.8618 34.7186C24.5883 34.8907 20.9276 35.0013 17.0294 35.0013H16.917C14.4556 35.0013 11.582 34.8907 8.37103 34.7186C6.24704 34.608 4.4229 33.76 2.81117 32.1869C1.19943 30.6138 0.349834 28.8072 0.224894 26.718C0.0499767 24.1248 0 21.7037 0 19.3932V19C0 14.9443 0.0624705 12.6339 0.112447 12.0071C0.39981 9.63516 1.2619 7.66879 2.63625 6.14486C4.18551 4.39971 6.07212 3.49027 8.37103 3.3305C11.7569 3.10929 14.5556 2.98639 16.917 2.98639C20.8776 2.98639 24.5384 3.097 27.8118 3.3305C29.9358 3.50256 31.7724 4.35055 33.3717 5.92365C34.9834 7.44758 35.833 9.25418 36.0079 11.3311C36.0704 12.1177 36.0704 12.683 36.1204 13.0763H33.1343Z" fill={fillColor} />
    </svg>
  );
};
export default CCIcon;