import Message from "./Messages.svg";
import Classified from "./Classified.svg";
import Recommendation from "./Recommendation.svg";
import Poll from "./Poll.svg";
import Event from "./Event.svg";
import UrgentAlert from "./Urgent-Alert.svg";
import Smiley from "./smiley.svg";
import Image from "./image.svg";
import ForwardArrow from "./Forward-Arrow.svg";
import Back from "./back.svg";
import Tick from "./tick.svg";
import Add from "./add.svg";
import Cross from "./cross.svg";
import Category from "./category.svg";
import Trash from "./trash.svg";
import Location from "./location.svg";
import GPS from "./gps_icon.svg";
import UserMale from "./user_male.svg";
import DateTime from "./date.svg";
import DollarSymbol from "./dollar-symbol.svg";
import Paper from "./paper.svg";

export default {
  Message,
  Classified,
  Recommendation,
  Poll,
  Event,
  UrgentAlert,
  Smiley,
  Image,
  ForwardArrow,
  Back,
  Tick,
  Add,
  Cross,
  Category,
  Trash,
  Location,
  GPS,
  UserMale,
  DateTime,
  DollarSymbol,
  Paper,
};
export { ReactComponent as MessageIcon } from "./Messages.svg";
export { ReactComponent as ClassifiedIcon } from "./Classified.svg";
export { ReactComponent as RecommendationIcon } from "./Recommendation.svg";
export { ReactComponent as PollIcon } from "./Poll.svg";
export { ReactComponent as EventIcon } from "./Event.svg";
export { ReactComponent as UrgentAlertIcon } from "./Urgent-Alert.svg";
export { ReactComponent as SmileyeIcon } from "./smiley.svg";
export { ReactComponent as ImageIcon } from "./image.svg";
export { ReactComponent as BackIcon } from "./back.svg";
export { ReactComponent as TickIcon } from "./tick.svg";
export { ReactComponent as AddIcon } from "./add.svg";
export { ReactComponent as CrossIcon } from "./cross.svg";
export { ReactComponent as PeopleIcon } from "./people.svg";
export { ReactComponent as CategoryIcon } from "./category.svg";
export { ReactComponent as TrashIcon } from "./trash.svg";
export { ReactComponent as LocationIcon } from "./location.svg";
export { ReactComponent as GPSIcon } from "./gps_icon.svg";
export { ReactComponent as UserMaleIcon } from "./user_male.svg";
export { ReactComponent as DateTimeIcon } from "./date.svg";
export { ReactComponent as DollarSymbolIcon } from "./dollar-symbol.svg";
export { ReactComponent as PaperIcon } from "./paper.svg";
export { ReactComponent as MentionIcon } from "./mention.svg";
