import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Layout,
  Row,
  Menu,
  Popover,
  notification,
  Affix,
  Dropdown,
  Badge,
} from "antd";
import { UserOutlined, MessageOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

// components
import Search from "../search/search";
import AuthGuard from "../../../../components/authGuard/authGuard";
import Container from "../container/container";
import NBGlobalProgress from "../nbGlobalProgress";
import NbSimpleCard from "../nbSimpleCard/nbSimpleCard";
import GlobalSearchAPI from "../../../../api/services/globalSearch/globalSearch";
import PostHeadInfo from "../nbUserCardInfo/nbUserCardInfo";
import NbDivider from "../nbDivider/nbDivider";
import Notification from "./notification";

// hooks
import {
  formattedFromNow,
  getMentionUserFromTemplate,
  notify,
  useNbRoutes,
} from "../../../../utils";

// icons
import { ReactComponent as MessagesIcon } from "../../../icons/messages.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import CCIcon from "../../../icons/dynamicIcons/ccIcon";
import { ReactComponent as LightIcon } from "../../../icons/light.svg";
import { ReactComponent as DarkIcon } from "../../../icons/dark.svg";
import { ReactComponent as ShieldIcon } from "../../../icons/shield.svg";
import { ReactComponent as Logout } from "../../../icons/logout.svg";

// icons
import { CrossIcon } from "../../../icons/createPost";

// actions
import tokenActions from "../../../../store/auth/action";

// apis
import authApis from "../../../../api/services/auth/auth";

// scss
import "./header.scss";
import { ALL_POSTS_REMOVE } from "../../../../store/constants";
import { NbButton } from "../CustomButton";
import NbAvatar from "../nbAvatar/nbAvatar";
import config from "../../../../config";
import { AlertIcon } from "../../../icons";

const { Header } = Layout;

function Content() {
  const dispatch = useDispatch();
  const nbRoutes = useNbRoutes();
  const logout = async () => {
    try {
      await authApis.userLogout();
      dispatch(tokenActions.Token(null));
      dispatch(tokenActions.clearMe());
      window.localStorage.removeItem("signupAuthToken");
      if (nbRoutes.private) {
        nbRoutes.gotoLogin();
      }
      dispatch({
        type: ALL_POSTS_REMOVE,
      });
    } catch (err) {
      notify(err?.message, "error");
    }
  };

  const [modeColor, setModeColor] = useState(true);
  const toggleMode = () => {
    setModeColor(!modeColor);
  };

  const history = useHistory();
  return (
    <div className="pop-menu-content">
      <NbButton
        type="transparent"
        onClick={() => history.push("/profile")}
        className="pop-menu-content-item c-pointer nb-base-text w-100"
        btnText="My Profile"
      />
      <NbButton
        type="transparent"
        onClick={() => history.push("/account/settings")}
        className="pop-menu-content-item c-pointer nb-base-text w-100"
        btnText="Settings and Privacy"
      />
      <NbButton
        type="transparent"
        className="pop-menu-content-item nb-base-text w-100"
        onClick={() => logout()}
        icon={Logout}
        btnText="Logout"
      />
      {/* <NbButton 
        type="transparent"
        className="pop-menu-content-item c-pointer nb-base-text"
        icon={modeColor ? LightIcon : DarkIcon}
        onClick={toggleMode}
        btnText={modeColor ? "Light Mode" : "Dark Mode"}
      /> */}
    </div>
  );
}

function Avatar() {
  const me = useSelector((state) => state.me);
  const {
    user_id: userId,
    profile,
    default_display_id: defaultDisplayId,
    default_display: defaultDisplay,
  } = useSelector((state) => state.me || {});

  let userAvatar = userId === defaultDisplayId ? profile?.url : null;
  if (defaultDisplay?.logo?.url) {
    userAvatar = defaultDisplay.logo.url;
  }
  return (
    <div className="display-flex align-items-center">
      {userAvatar ? (
        <Badge
          size="default"
          style={{ backgroundColor: "#3155a6" }}
          text
          offset={[-12, 5]}
          count={me?.status === "temp_verified" ? "L" : null}
        >
          <NbAvatar
            size={40}
            profileImage={userAvatar}
            firstName={me?.first_name}
            lastName={me?.last_name}
          />
        </Badge>
      ) : (
        <Badge
          style={{ backgroundColor: "#3155a6" }}
          text
          offset={[-12, 5]}
          count={me?.status === "temp_verified" ? "L" : null}
        >
          <NbAvatar
            size="large"
            profileImage={userAvatar}
            firstName={me?.first_name}
            lastName={me?.last_name}
          />
        </Badge>
      )}
    </div>
  );
}
function AppHeaderRight() {
  const {
    user_id: userId,
    first_name: firstName,
    last_name: lastName,
    profile,
    default_display_id: defaultDisplayId,
    default_display: defaultDisplay,
  } = useSelector((state) => state.me || {});
  const me = useSelector((state) => state.me);
  let userAvatar = userId === defaultDisplayId ? profile?.url : null;
  if (defaultDisplay?.logo?.url) {
    userAvatar = defaultDisplay.logo.url;
  }

  return (
    <div className="app-header-menu">
      <div key="notification" className="app-header-menu-item item-2">
        <Notification />
      </div>
      <div key="setting" className="app-header-menu-item">
        <Popover
          overlayClassName="app-header-popover"
          content={<Content />}
          title={null}
          placement="bottomRight"
          trigger="click"
        >
          <div
            data-testid="more-menu-button"
            className="display-flex align-items-center"
          >
            <Avatar />
            <div className="header-profile-image-text">
              {defaultDisplay?.name
                ? defaultDisplay.name
                : `${firstName} ${lastName}`}
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

function AppHeader({ className }) {
  const customTheme = useSelector((state) => state.themeConfiguration);
  const [searchData, setSearchData] = useState(null);
  const [searchLoader, setSearchLoader] = useState(null);
  const [searchBar, setSearchBar] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [mobileSearch, setMobileSearch] = useState(true);
  const history = useHistory();
  const nbRoutes = useNbRoutes();
  const { progressLoading } = useSelector((state) => state.globalComponents);
  const me = useSelector((state) => state.me);
  const [open, setOpen] = useState(false);

  const searchChange = debounce(async (value) => {
    try {
      let searchValue = "";
      if (value.trim() !== "") {
        searchValue = value;
      } else if (!value || value === "") {
        setSearchBar(false);
        setMobileSearch(false);
        setSearchString("");
      }
      if (!searchValue) return;
      setSearchLoader(true);
      const res = await GlobalSearchAPI.globalSearching(searchValue);
      setSearchData(res);
      setSearchLoader(false);
      setSearchBar(true);
      setMobileSearch(true);
      if (
        (!value && res?.data?.posts.length) ||
        res?.data?.users.length ||
        res?.data?.comments.length
      ) {
        setSearchBar(false);
      }
    } catch (error) {
      setSearchLoader(false);
    }
  }, 300);

  const searchEnter = (e) => {
    if (!searchLoader) {
      history.push({
        pathname: "/global-search",
        state: { string: e.target.value },
      });
      setSearchBar(false);
    }
  };

  const searchOverLay = () => {
    return (
      <NbSimpleCard className="search-section nb-nice-scroll-style">
        {searchData?.data?.users?.data?.length ? (
          <>
            <div className="display-flex">
              <UserOutlined style={{ fontSize: "15px" }} className="mt1 mr2" />
              <h6
                className="nb-primary-texts m0"
                style={{ color: customTheme.primary_color }}
              >
                Community Members
              </h6>
            </div>
            <NbDivider className="apply-bottom-border-grey" />
          </>
        ) : null}
        {searchData?.data?.users?.data?.slice(0, 2)?.map((el) => {
          return (
            <>
              <div
                onClick={() => {
                  setSearchBar(false);
                }}
                role="button"
                tabIndex="0"
                onKeyPress={(event) => event.key === "enter" && onClick()}
              >
                <Link to={`/profile/${el?.user_id}`}>
                  <PostHeadInfo
                    avatarSize={40}
                    name={
                      <p className="mb0">{`${el.first_name} ${el.last_name}`}</p>
                    }
                    imageUrl={el?.profile?.url}
                    data={el}
                  />
                </Link>
              </div>
              <NbDivider className="apply-bottom-border-grey" />
            </>
          );
        })}
        {searchData?.data?.posts?.data?.length ? (
          <>
            <div className="display-flex">
              <MessageOutlined
                style={{ fontSize: "20px" }}
                className="mt1 mr2"
              />
              <h6
                className="nb-primary-texts m0"
                style={{ color: customTheme.primary_color }}
              >
                Posts
              </h6>
            </div>
            <NbDivider className="apply-bottom-border-grey" />
          </>
        ) : null}
        {searchData?.data?.posts?.data?.slice(0, 2)?.map((el) => {
          return (
            <div
              className="search-content-text"
              onClick={() => {
                setSearchBar(false);
              }}
              role="button"
              tabIndex="0"
              onKeyPress={(event) => event.key === "enter" && onClick()}
              key={el?.post_id}
            >
              <Link to={`/post/view/${el?.post_id}`}>
                <p className="fnw-700 mb0 truncate">{el?.title}</p>
                <p
                  className="nb-faded-text truncate mb0"
                  style={{ color: customTheme.primary_color }}
                >
                  {getMentionUserFromTemplate(el?.content)}
                </p>
              </Link>
              <NbDivider className="apply-bottom-border-grey" />
            </div>
          );
        })}
        {searchData?.data?.comments?.data.length ? (
          <>
            <div className="display-flex">
              <MailOutlined style={{ fontSize: "20px" }} className="mt1 mr2" />
              <h6
                className="nb-primary-texts m0"
                style={{ color: customTheme.primary_color }}
              >
                Replies
              </h6>
            </div>
            <NbDivider noStyle={false} />
          </>
        ) : null}
        <Link to="#">
          <>
            {searchData?.data?.comments?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                  key={el?.post?.post_id}
                >
                  <Link to={`/post/view/${el?.post?.post_id}`}>
                    <p className="fnw-700 mb0 truncate">{el?.post?.title}</p>
                    <p
                      className="nb-faded-text truncate"
                      style={{ color: customTheme.primary_color }}
                    >
                      {el?.body}
                    </p>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.business?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6
                    className="nb-primary-text m0"
                    style={{ color: customTheme.primary_color }}
                  >
                    Business
                  </h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.business?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                  key={el.organisation_id}
                >
                  <Link to={`/business/${el.organisation_id}/details`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p
                        className="nb-faded-text truncate"
                        style={{ color: customTheme.primary_color }}
                      >
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.organisation?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6
                    className="nb-primary-text m0"
                    style={{ color: customTheme.primary_color }}
                  >
                    Organisation
                  </h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.organisation?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  key={el.organisation_id}
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                >
                  <Link to={`/organisation/${el.organisation_id}/details`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p
                        className="nb-faded-text truncate"
                        style={{ color: customTheme.primary_color }}
                      >
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.publication?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6
                    className="nb-primary-text m0"
                    style={{ color: customTheme.primary_color }}
                  >
                    Publication
                  </h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.publication?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  key={el.organisation_id}
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                >
                  <Link to={`/publication/${el.organisation_id}`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p
                        className="nb-faded-text truncate"
                        style={{ color: customTheme.primary_color }}
                      >
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.users?.data?.length ||
            searchData?.data?.posts?.data?.length ? (
              <div
                style={{ textAlign: "center" }}
                className="show-more-btn pt3 fw-600"
              >
                <NbButton
                  onClick={() => {
                    setSearchBar(false);
                    localStorage.setItem("searchString", searchString);
                    history.push({
                      pathname: "/global-search",
                    });
                  }}
                  type="transparent"
                  className="nb-proxy-button c-pointer"
                  btnText="Show more"
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <p
                  className="mb0 nb-faded-text"
                  style={{ color: customTheme.primary_color }}
                >
                  No content found
                </p>
              </div>
            )}
          </>
        </Link>
      </NbSimpleCard>
    );
  };

  return (
    <>
      <NBGlobalProgress
        isAnimating={progressLoading.isAnimating}
        key={progressLoading.key}
      />
      <Header className={`app-header-mobile ${className}`}>
        <Menu
          mode="horizontal"
          className="app-header-mobile-menu"
          items={[
            {
              key: "1",
              className: "app-header-mobile-menu-item mobile-menu-item-1",
              label: (
                <div key="setting" className="app-header-menu-item">
                  <NbButton
                    type="link"
                    icon={Avatar}
                    onClick={() => setOpen(true)}
                  />
                </div>
              ),
            },
            {
              key: "2",
              className: "app-header-mobile-menu-item",
              style: { width: "72%", padding: "0px" },
              label: (
                <div
                  className="mobile-search-bar-block mobile-search-bar"
                  style={{ position: "unset" }}
                >
                  {searchString.length > 0 && (
                    <CrossIcon
                      onClick={() => {
                        searchChange("");
                      }}
                      className="iconCross"
                    />
                  )}
                  <Dropdown
                    placement="bottomRight"
                    open={searchBar}
                    overlayClassName={
                      mobileSearch
                        ? "overlay-search-mobile dropdown-open mnw300 search-dropdown"
                        : "overlay-search-mobile dropdown-close mnw300 search-dropdown"
                    }
                    overlay={searchOverLay()}
                    overlayStyle={{ position: "fixed" }}
                  >
                    <Search
                      onChange={(value) => {
                        setSearchString(value);
                        searchChange(value);
                      }}
                      onPressEnter={searchEnter}
                      searchLoader={searchLoader}
                      className=""
                      value={searchString}
                    />
                  </Dropdown>
                </div>
              ),
            },
            {
              key: "3",
              className: "app-header-mobile-menu-item",
              label: (
                <div>
                  <Notification />
                </div>
              ),
            },
          ]}
        />
        <BottomSheet
          open={open}
          onDismiss={() => {
            setOpen(false);
          }}
          snapPoints={({ maxHeight }) => [
            maxHeight - maxHeight / 5,
            maxHeight * 0.25,
          ]}
          expandOnContentDrag
          onClick={() => {
            setOpen(false);
          }}
        >
          <Content />
        </BottomSheet>
      </Header>
      <Header className={`app-header ${className}`}>
        <Row>
          <Col offset={6} span={12} className="app-header-left">
            <Dropdown
              placement="bottomLeft"
              open={searchBar}
              overlayClassName="mnw300 search-dropdown"
              overlayStyle={{ position: "fixed" }}
              overlay={searchOverLay()}
            >
              <Search
                onChange={(value) => searchChange(value)}
                onPressEnter={searchEnter}
                searchLoader={searchLoader}
                className=""
              />
            </Dropdown>
          </Col>
          <AuthGuard
            authActions={{
              public: {
                action: "HIDE",
              },
            }}
          >
            <Col span={6} className="app-header-right">
              <AppHeaderRight />
            </Col>
          </AuthGuard>
        </Row>
      </Header>
      {!["active"].includes(me?.user_state) && (
        <div className="profile-alert">
          Your account is {me?.user_state_name} for:{" "}
          {formattedFromNow(
            me?.user_state_end_period_date,
            config.suspensionOffset
          )}
        </div>
      )}
    </>
  );
}

function AffixHeader({ className, contianerStyle }) {
  return (
    <Affix offsetTop={0.1} className="affix-header">
      <Container style={{ padding: 0, ...contianerStyle }} fluid>
        <AppHeader className={className} />
      </Container>
    </Affix>
  );
}

export default AffixHeader;
