import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// components
import Comment from "../comment/comment";
import Reply from "./reply";

// context
import { PostCommentsContext } from "./context";
import { NbButton } from "../../../assets/gobal/components/CustomButton";

function PostComments({
  style,
  className,
  comments,
  onReactComment,
  onUnReactComment,
  onReplyComment,
  onDeleteComment,
  // added for delete single reply
  onDeleteReply,
  onViewMore,
  isShowViewMore,
  onViewMoreReply,
  isShowViewMoreReplies,
  me,
  commentsDisabled,
  onReportComment,
}) {
  const [activeCommetFormIndex, setActiveCommentForm] = useState(null);
  const postCommentsContextValue = useMemo(
    () => ({ activeCommetFormIndex, setActiveCommentForm }),
    [activeCommetFormIndex]
  );
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <PostCommentsContext.Provider value={postCommentsContextValue}>
      {comments?.length !== 0 && (
        <div className={`px4 mt8 ${className}`} style={style}>
          {comments?.map((comment, index) => {
            const {
              children,
              pagination,
              has_child: hasChild,
              comment_id: id,
            } = comment || {};
            return (
              <Comment
                key={id}
                index={index}
                onReactComment={onReactComment}
                onUnReactComment={onUnReactComment}
                onReplyComment={onReplyComment}
                onDeleteComment={onDeleteComment}
                comment={comment}
                me={me}
                commentsDisabled={commentsDisabled}
                onReportComment={onReportComment}
              >
                {children?.map((reply) => {
                  return (
                    <Reply
                      key={reply.comment_id}
                      isReply
                      parentId={comment.comment_id}
                      comment={reply}
                      onReactComment={onReactComment}
                      onUnReactComment={onUnReactComment}
                      onReplyComment={onReplyComment}
                      onDeleteComment={onDeleteReply}
                      onViewMoreReply={onViewMoreReply}
                      isShowViewMoreReplies={hasChild && isShowViewMoreReplies}
                      pagination={pagination}
                      me={me}
                      commentsDisabled={commentsDisabled}
                      onReportComment={onReportComment}
                    />
                  );
                })}
              </Comment>
            );
          })}
          {isShowViewMore && (
            <NbButton
              type="link"
              className="nb-proxy-button fn9 fnw-600 c-pointer"
              style={{ color: customTheme.primary_color }}
              onClick={onViewMore}
              btnText="view more comments"
            />
          )}
        </div>
      )}
    </PostCommentsContext.Provider>
  );
}

export default PostComments;

PostComments.defaultProps = {
  style: {},
  className: "",
  onViewMoreReply: undefined,
};

PostComments.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object))
    .isRequired,
  me: PropTypes.instanceOf(PropTypes.object).isRequired,
  onReactComment: PropTypes.func.isRequired,
  onUnReactComment: PropTypes.func.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onViewMore: PropTypes.func.isRequired,
  onViewMoreReply: PropTypes.func,
  isShowViewMore: PropTypes.bool.isRequired,
  onReportComment: PropTypes.bool.isRequired,
  isShowViewMoreReplies: PropTypes.bool.isRequired,
  commentsDisabled: PropTypes.bool.isRequired,
};
