import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";
import { useParams } from "react-router-dom";
// components
import JourneyImageUploader from "./journeyImageUploader";
import { PeopleIcon } from "../../../assets/icons/createPost";
import { CardFooter } from "../../../assets/gobal/components/Cards";
import { TextAreaField } from "../../../assets/gobal/components/FormFields";
import ChooseNeighbours from "./chooseNeighbours";
import { isArray, notify } from "../../../utils/functions";
import postApis from "../../../api/services/post/post";
import {
  getSelectedNeighoursData,
  getSelectedNeighoursIds,
  getSelectedNeighoursTitle,
} from "./shared";
import { ALL_POSTS_SUCCESS } from "../../../store/constants";

// scss
import "./MessageJourney.scss";

// utils
import { isPostToBeAppended, useMediaUploader } from "../../../utils";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import JourneyOptionBtn from "./journeyOptionBtn";
import { DirectionRightIcon } from "../../../assets/icons";

const formInitialState = () => ({
  selectedNeighbours: null,
  selectedNeighboursTitle: "",
  selectedCategory: null,
  selectedCategoryTitle: "",
  selectedBranchesTitle: "",
  subject: "",
  message: "",
  reactMorePeople: false,
  postDocs: [],
  linksList: [""],
  schedule_date: "",
  organisation_id: [],
  mentions: [],
});

export default function MessageJourney(props) {
  const { onCancel, editMode, dataSource, closeEditModal, onPostCreated } =
    props;

  const [showChooseNeighbours, setShowChooseNeighbours] = useState(false);
  const [messagePostLoader, setMessagePostLoader] = useState(false);
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const mediaUploader = useMediaUploader();

  const { categoryId, categorySlug } = useParams();

  const { neighbours, allPosts } = useSelector((state) => state.post);

  const meData = useSelector((state) => state.me);
  if (!meData || !meData.communities || !Array.isArray(meData.communities)) {
    return null;
  }
  const [messageJourneyData, updateMessageJourneyData] = useState(
    formInitialState()
  );

  const checkBtnDisabled = () => {
    const { subject, message } = messageJourneyData;
    return subject?.trim() === "" || message?.trim() === "";
  };

  /* istanbul ignore next */
  const prepareParamDetails = () => {
    const { selectedNeighbours, subject, message, mentions } =
      messageJourneyData;

    const paramsDetails = {
      neighbourhood_id: isArray(selectedNeighbours)
        ? selectedNeighbours
        : [selectedNeighbours],

      category_id: 2,
      title: subject,
      content: message,
      is_public: 1,
      url: [],
      media: [],
      mentions,
    };

    return paramsDetails;
  };

  /* istanbul ignore next */
  const handleOnClickPostBtn = async () => {
    const { postDocs } = messageJourneyData;

    const paramsDetails = prepareParamDetails();

    try {
      const oldPostDocs = postDocs
        .filter((postDoc) => postDoc.url)
        .map((postDoc) => postDoc.url);

      const oldPostDocsMedia = postDocs
        .filter((postDoc) => {
          if (typeof postDoc.uid === "number") {
            return postDoc.uid;
          } else return false;
        })
        .map((postDoc) => postDoc.uid);

      const newPostDocs = postDocs.filter((postDoc) => !postDoc.url);

      paramsDetails.url = [...paramsDetails.url, ...oldPostDocs];
      paramsDetails.media = [...paramsDetails.media, ...oldPostDocsMedia];

      setMessagePostLoader(true);
      if (newPostDocs.length) {
        const finalAttachments = newPostDocs
          .map((doc) => doc.originFileObj)
          .map((file) =>
            mediaUploader.upload({
              file,
              title: file?.name,
            })
          );
        const uploadedFiles = await Promise.all(finalAttachments);
        const fileLocations = uploadedFiles.map((file) => file.data.url);
        const mediaId = uploadedFiles.map((file) => file.data.media_id);
        paramsDetails.url = [...paramsDetails.url, ...fileLocations];
        paramsDetails.media = [...paramsDetails.media, ...mediaId];
      }
      await formInstance.validateFields();

      let res = null;
      // message api post
      if (editMode) {
        res = await postApis.updatePost(dataSource.post_id, paramsDetails);
      } else {
        res = await postApis.messagePost(paramsDetails);
      }
      notify(res.message, "success");
      updateMessageJourneyData(formInitialState());
      setMessagePostLoader(false);
      if (!editMode) {
        const isAppend = isPostToBeAppended(
          res.data?.post_category,
          params?.categorySlug
        );
        // only append post if the user is creating post form the respective category
        if (isAppend) {
          dispatch({
            type: ALL_POSTS_SUCCESS,
            payload: [res.data, ...allPosts],
          });
        }

        onPostCreated?.(res.data);
        closeEditModal?.();
      } else {
        const indexOfPost = allPosts.findIndex(
          (post) => post.post_id === dataSource.post_id
        );
        const updatedAllPosts = [...allPosts];
        updatedAllPosts[indexOfPost] = res.data;
        onPostCreated?.(res.data);
        dispatch({
          type: ALL_POSTS_SUCCESS,
          payload: updatedAllPosts,
        });
        closeEditModal();
      }
    } catch (err) {
      setMessagePostLoader(false);
      if (err?.errorFields?.length) {
        return;
      }
      notify(err?.message, "error");
    }
  };

  const handleInputChange = (ev) => {
    const { name, value, mentions = [] } = ev.target;
    const updatedMessageJourneyData = { ...messageJourneyData };
    updatedMessageJourneyData[name] = value;
    updatedMessageJourneyData.mentions = [...mentions];
    updateMessageJourneyData(updatedMessageJourneyData);
  };

  const handleUpdateSelectedNeighbours = (data) => {
    updateMessageJourneyData(
      getSelectedNeighoursData(messageJourneyData, data, meData)
    );
    setShowChooseNeighbours(false);
  };

  useEffect(() => {
    if (neighbours && !editMode) {
      const updatedMessageJourneyData = getSelectedNeighoursData(
        messageJourneyData,
        // neighbours.neighbourhood_id,
        categoryId === "all"
          ? meData.communities[0].neighbourhood_id
          : +categoryId,
        neighbours
      );

      updateMessageJourneyData(updatedMessageJourneyData);
    }
  }, [neighbours]);

  const getSelectedCommunities = (communities, selectedCommunities) => {
    const communitiesArray = [];
    if (selectedCommunities != null && isArray(selectedCommunities)) {
      selectedCommunities.forEach((selectedCommunityId) => {
        communities.forEach((community) => {
          if (selectedCommunityId === community.neighbourhood_id) {
            communitiesArray.push(community.name);
          }
        });
      });
    }
    if (!editMode) {
      if (categoryId === "all") {
        messageJourneyData.selectedNeighboursTitle = communities[0].name;
      } else {
        messageJourneyData.selectedNeighboursTitle = categorySlug;
      }

      messageJourneyData.selectedNeighboursTitle = communitiesArray.toString();
    }
    return communitiesArray.toString();
  };

  getSelectedCommunities(
    meData.communities,
    messageJourneyData.selectedNeighbours
  );

  useEffect(() => {
    if (editMode) {
      const {
        title,
        content,
        is_public: isPublic,
        post_category: postCategory,
        neighbourhoods,
        post_media: postMedia,
        mentions,
      } = dataSource;

      const mediaToEdit = postMedia?.map((pMedia) => {
        const { media } = pMedia;

        return {
          uid: media.media_id,
          name: `attachment_${media.media_id}`,
          status: "done",
          url: media.url,
        };
      });

      const editPayload = {
        selectedNeighbours: getSelectedNeighoursIds(neighbourhoods),
        selectedNeighboursTitle: getSelectedNeighoursTitle(neighbourhoods),
        selectedCategory: postCategory,
        // selectedCategoryTitle: postCategory.name,
        subject: title,
        message: content,
        reactMorePeople: !!isPublic,
        postDocs: mediaToEdit,
        mentions: mentions.map((m) => ({
          relatable_type: m?.type,
          relatable_id: m?.relatable?.user_id,
        })),
      };

      updateMessageJourneyData(editPayload);
    }

    return () => {
      updateMessageJourneyData(formInitialState());
    };
  }, [editMode]);

  return (
    <div className="message-journey-container">
      <Form.Item
        name="chooseaCommunity"
        label="Choose a Community"
        colon={false}
        className="mb0"
      />
      <JourneyOptionBtn
        title={messageJourneyData.selectedNeighboursTitle}
        placeholder="Choose Communities"
        leftIcon={PeopleIcon}
        rightIcon={DirectionRightIcon}
        rightIconClass="journey-option-right-icon"
        onClick={() => setShowChooseNeighbours(true)}
        showRightIcon
        containerClassName="nb-select-input"
      />
      <Form.Item name="subject" label="Subject" colon={false} className="mb0" />
      <TextAreaField
        placeholder="Subject"
        name="subject"
        maxLength={50}
        onChange={(ev) => {
          if (ev.nativeEvent.inputType !== "insertLineBreak") {
            handleInputChange(ev);
          }
        }}
        value={messageJourneyData.subject}
        required
        disabled={messagePostLoader}
      />
      <Form.Item name="message" label="Message" colon={false} className="mb0" />
      <TextAreaField
        placeholder="Message"
        name="message"
        minRows={5}
        maxRows={5}
        showEmojis
        showCharCount
        maxLength={30000}
        onChange={handleInputChange}
        value={messageJourneyData.message}
        required
        onEmojiChange={(ev) => {
          handleInputChange({
            target: {
              name: "message",
              value: messageJourneyData.message + ev.native,
            },
          });
        }}
        disabled={messagePostLoader}
        // isTextArea
        isMentions
      />
      <JourneyImageUploader
        className="mt2"
        disabled={messagePostLoader}
        files={messageJourneyData.postDocs}
        onUpload={
          /* istanbul ignore next */
          (files) => {
            updateMessageJourneyData((prevData) => ({
              ...prevData,
              postDocs: files,
            }));
          }
        }
        onDelete={
          /* istanbul ignore next */
          (files) => {
            updateMessageJourneyData((prevData) => ({
              ...prevData,
              postDocs: files,
            }));
          }
        }
      />

      {/* new card footer */}
      <CardFooter
        btnDisabled={messagePostLoader || checkBtnDisabled()}
        btnLoading={messagePostLoader}
        onClickBtn={handleOnClickPostBtn}
        btnText={editMode ? "Update" : "Post"}
      >
        <NbButton type="outlined" onClick={onCancel} btnText="Cancel" />
      </CardFooter>

      {showChooseNeighbours && (
        <ChooseNeighbours
          setShowChooseNeighbours={setShowChooseNeighbours}
          selectedNeighbours={messageJourneyData.selectedNeighbours}
          handleUpdateSelectedNeighbours={handleUpdateSelectedNeighbours}
          editMode={editMode}
        />
      )}
    </div>
  );
}
