import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "antd";

function NbButton({
  onClick,
  btnText,
  icon: Icon,
  disabled,
  textCenter,
  className,
  block,
  size,
  iconClassName,
  loading,
  type,
  htmlType,
}) {
  const customTheme = useSelector((state) => state.themeConfiguration) || {};
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const defaultBtnStyle = {
    background: isHovered
      ? customTheme.secondary_color
      : customTheme.primary_color,
    border: "none",
    color: "white",
  };
  const outlinedBtnStyle = {
    background: "white",
    border: "1px solid",
    borderColor: isHovered ? customTheme.primary_color : "grey",
    color: isHovered ? customTheme.primary_color : "grey",
  };
  const secondaryBtnStyle = {
    background: isHovered
      ? customTheme.primary_color
      : customTheme.secondary_color,
    border: "none",
    color: "white",
  };
  const disabledBtnStyle = {
    cursor: "not-allowed",
    background: "#dcddde",
    color: "white",
  };
  const transparentBtnStyle = {
    background: "transparent",
    color: isHovered ? customTheme.primary_color : "",
  };
  const loadMoreBtnStyle = {
    color: isHovered ? customTheme.secondary_color : "",
  };
  const dangerBtnStyle = {
    background: "red",
    color: "white",
  };
  const buttonBtnStyle = {
    background: isHovered ? customTheme.primary_color : "",
    color: isHovered ? "white" : "",
    padding: "5px 12px!important",
  };
  const linkBtnStyle = {
    color: isHovered ? customTheme.primary_color : customTheme.secondary_color,
  };

  let BtnStyle;
  switch (type) {
    case "secondary":
      BtnStyle = secondaryBtnStyle;
      break;
    case "disabled":
      BtnStyle = disabledBtnStyle;
      break;
    case "button":
      BtnStyle = buttonBtnStyle;
      break;
    case "transparent":
      BtnStyle = transparentBtnStyle;
      break;
    case "text":
      BtnStyle = transparentBtnStyle;
      break;
    case "outlined":
      BtnStyle = outlinedBtnStyle;
      break;
    case "load-more":
      BtnStyle = loadMoreBtnStyle;
      break;
    case "danger":
      BtnStyle = dangerBtnStyle;
      break;
    case "link":
      BtnStyle = linkBtnStyle;
      break;
    default:
      BtnStyle = defaultBtnStyle;
      break;
  }

  return (
    <Button
      type={type}
      className={`btn ${textCenter ? "btn-center" : ""} ${className ?? ""}`}
      onClick={onClick}
      disabled={disabled}
      size={size}
      block={block}
      loading={loading}
      htmlType={htmlType}
      icon={Icon && <Icon className={`btn-icon ${iconClassName ?? ""}`} />}
      style={{ ...BtnStyle, fontFamily: '"Poppins", sans-serif' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {btnText}
    </Button>
  );
}

export default NbButton;

NbButton.defaultProps = {
  onClick: () => {},
  btnText: "",
  icon: null,
  textCenter: false,
  className: "",
  iconClassName: "",
  loading: false,
  type: "default",
  htmlType: "",
  block: false,
};

NbButton.propTypes = {
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  icon: PropTypes.node,
  textCenter: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  block: PropTypes.bool,
};
