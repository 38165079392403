import { SET_THEME } from "../constants";

const initialState = {
  logo_id: null,
  logo_mobile_url: null,
  logo_url: null,
  mobile_logo_id: null,
  primary_color: "#6f88b8",
  secondary_color: "#3155a6",
};

// eslint-disable-next-line default-param-last
export default function themeConfigurationReducer(
  // eslint-disable-next-line default-param-last
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_THEME:
      if (action.payload) {
        return { ...state, ...action.payload };
      } else {
        return {
          ...initialState,
        }
      }


    default:
      return state;
  }
}
