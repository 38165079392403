import React from "react";
import { useSelector } from "react-redux";
import { Input, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";

// scss
import "./search.scss";

const Search = React.forwardRef(
  (
    {
      className,
      style,
      onChange,
      suffix,
      placeholder,
      allowClear,
      onPressEnter,
      searchLoader,
      value,
    },
    ref
  ) => {
    const customTheme = useSelector((state) => state.themeConfiguration);
    const antIcon = (
      <LoadingOutlined
        style={{ fontSize: 18, color: customTheme.primary_color }}
        spin
      />
    );
    const suffixJsx = suffix || (
      <button style={style} type="button" className="c-pointer nb-proxy-button">
        {searchLoader ? (
          <Spin className="mr2" indicator={antIcon} />
        ) : (
          <SearchOutlined className="mr2" style={{ fontSize: "18px" }} />
        )}
      </button>
    );
    return (
      <div className={`nb-search-input ${className}`}>
        <Input
          ref={ref}
          placeholder={placeholder}
          suffix={suffixJsx}
          onChange={(e) => {
            onChange?.(e?.target?.value);
          }}
          allowClear={allowClear}
          onPressEnter={onPressEnter}
          value={value}
          prefix={<SearchIcon className="mobile-search-icon" />}
        />
      </div>
    );
  }
);

export default Search;

Search.defaultProps = {
  className: "",
  style: {},
  onChange: undefined,
  suffix: undefined,
  placeholder: "Search",
  allowClear: false,
};

Search.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  suffix: PropTypes.func,
  allowClear: PropTypes.bool,
};
