import React from "react";
import { Checkbox } from "antd";

export function ListCheckboxOtpion({
  disabled,
  value,
  text,
  data,
  textClassName,
  checkBoxClass,
}) {
  return (
    <Checkbox
      className={`my5 p0 m0 px12 ${checkBoxClass ?? ""}`}
      disabled={disabled}
      value={value}
      data={data}
    >
      <span className={`neighbours-list-item-text ${textClassName ?? ""}`}>
        {text}
      </span>
    </Checkbox>
  );
}
