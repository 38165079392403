import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Row, Col, Select } from "antd";
import PropTypes from "prop-types";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import ReportIcon from "../../../assets/icons/dynamicIcons/reportAlert";

// scss
import "./reportPostModal.scss";
import { getMentionUserFromTemplate } from "../../../utils";
import RenderMobileComponent from "../../../assets/gobal/components/RenderMobileComponent/RenderMobileComponent";
import { DirectionRightIcon } from "../../../assets/icons";

function ReportPostModal({
  title,
  visible,
  onOk,
  onCancel,
  destroyOnClose,
  loading,
  hideOptions,
  content,
}) {
  const reportCasesDict = {
    spam: "Spam",
    explicit: "Explict",
    bulling_harassment: "Bullying / Harassment",
  };

  const reportCases = Object.keys(reportCasesDict).filter(
    (reportCase) => !hideOptions.includes(reportCasesDict[reportCase])
  );

  const [report, setReport] = useState(reportCases[0]);
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <RenderMobileComponent
      render={() => (
        <Modal
          title={
            <>
              <div className="icon">
                <ReportIcon
                  fillColorPrimary={customTheme.primary_color}
                  fillColorSecondary={customTheme.secondary_color}
                />
              </div>
              {title}
            </>
          }
          className="nb-report-modal"
          closable={false}
          open={visible}
          onOk={onOk}
          centered
          onCancel={onCancel}
          destroyOnClose={destroyOnClose}
          footer={[
            <NbButton
              type="outlined"
              className="nb-button"
              key="back"
              onClick={onCancel}
              btnText="Cancel"
            />,
            <NbButton
              key="submit"
              type="primary"
              loading={loading}
              className="nb-button"
              onClick={() => onOk(report)}
              style={{ background: customTheme.primary_color }}
              btnText="Submit"
            />,
          ]}
        >
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item label="Reported Content" name="reportedContent">
                <Input.TextArea
                  className="nb-text-input"
                  placeholder="Content"
                  rows={5}
                  readOnly
                  defaultValue={getMentionUserFromTemplate(content || "")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Severity Level" name="severityLevel">
                <Select
                  onChange={(value) => setReport(value)}
                  defaultValue={reportCases[0]}
                >
                  {reportCases?.map((value) => {
                    return (
                      <Select.Option
                        key={`report-cases-${value}`}
                        value={value}
                      >
                        {reportCasesDict[value]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      )}
      renderMobile={() => (
        <BottomSheet
          open={visible}
          onDismiss={() => {
            onCancel();
          }}
          snapPoints={({ maxHeight }) => [
            maxHeight - maxHeight / 5,
            maxHeight * 0.35,
          ]}
          expandOnContentDrag
          className="bottom-sheet-menu"
          onClick={() => {
            onCancel();
          }}
          header={<span>Report Content</span>}
        >
          <div className="px4">
            <div className="p4">Please Select A Problem*</div>
            <div>
              {reportCases?.map((value) => {
                return (
                  <div className=" apply-bottom-border-grey py2">
                    <NbButton
                      type="link"
                      key={`report-cases-${value}`}
                      className="w-100"
                      btnText={
                        <div className="w-100 display-flex align-items-center justify-content-space-between">
                          {reportCasesDict[value]} <DirectionRightIcon />
                        </div>
                      }
                      onClick={() => onOk(value)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
      )}
    />
  );
}

export default ReportPostModal;

ReportPostModal.defaultProps = {
  title: "Report Content",
  content: "",
  onOk: () => null,
  onCancel: () => null,
  destroyOnClose: true,
  loading: false,
  hideOptions: [],
};

ReportPostModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  destroyOnClose: PropTypes.bool,
  loading: PropTypes.bool,
  hideOptions: PropTypes.arrayOf(PropTypes.string),
};
