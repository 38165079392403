import { Col, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

function RenderMobileComponent({ renderMobile, render }) {
  const mobileGrid = useRef();
  const [isMobile, setIsMobile] = useState(false);

  const checkScreenReady = useCallback(() => {
    setIsMobile(mobileGrid.current?.clientWidth > 0);
  }, [mobileGrid.current]);

  useEffect(() => {
    checkScreenReady();
    window.addEventListener("resize", checkScreenReady);
    return () => {
      window.removeEventListener("resize", checkScreenReady);
    };
  }, []);

  return (
    <>
      <Row
        style={{ marginLeft: "0px", marginRight: "0px" }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
      >
        <Col ref={mobileGrid} xs={24} sm={24} md={0} lg={0} xl={0} />
      </Row>
      {isMobile && renderMobile()}
      {!isMobile && render()}
    </>
  );
}

export default RenderMobileComponent;

RenderMobileComponent.defaultProps = {
  render: undefined,
  renderMobile: undefined,
};
