import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// components

// scss
import "./postHeadInfo.scss";

// icons
import NbAvatar from "../../../assets/gobal/components/nbAvatar/nbAvatar";
import { NbButton } from "../../../assets/gobal/components/CustomButton";

function ProfileRedirection({ postOwner, userId, name }) {
  const history = useHistory();
  if (postOwner === "user") {
    return (
      <NbButton
        type="transparent"
        onClick={() => history.push(`/profile/${userId}`)}
        from={{ otherUser: true }}
        className="display-flex align-items-center m0 p0 nb-base-text w-fc"
        btnText={<h4 className="m0">{name}</h4>}
      />
    );
  } else {
    return (
      <NbButton
        type="transparent"
        onClick={() => history.push(`/profile/${userId}`)}
        from={{ otherUser: true }}
        className="display-flex align-items-center m0 p0 nb-base-text w-fc"
        btnText={name}
      />
    );
  }
}

function PostHeadInfo({
  style,
  className,
  name,
  imageUrl,
  alternateInfo,
  avatarSize,
  userId,
  data,
}) {
  return (
    <div
      className={`post-head-info display-flex post-header-details ${className}`}
      style={style}
    >
      <NbAvatar
        profileImage={imageUrl}
        size={avatarSize}
        firstName={data?.owner?.first_name || data?.first_name}
        lastName={data?.owner?.last_name || data?.last_name}
      />
      <div className="display-flex align-items-center flex1 post-head-title">
        <div className="flex1">
          <ProfileRedirection
            postOwner={data?.post_owner}
            userId={userId}
            name={name}
          />
          <p className="fn7 mb0 description display-flex align-items-center">
            {alternateInfo}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PostHeadInfo;

PostHeadInfo.defaultProps = {
  style: {},
  className: "",
  alternateInfo: undefined,
  avatarSize: 56,
  imageUrl: " ",
};

PostHeadInfo.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  alternateInfo: PropTypes.element,
  avatarSize: PropTypes.number,
};
