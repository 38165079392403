import React, { useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";
import NbAvatar from "../../../assets/gobal/components/nbAvatar/nbAvatar";

// components
import ReactionButtons from "../reactionButtons/reactionButtons";
// icons
import { More } from "../../../assets/icons";

// formatters
import { formattedFromNow } from "../../../utils";
import MetaTagsContent from "../metaTagsContent/metaTagsContent";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import { MobileMenu } from "../../../assets/gobal/components/MobileMenu/MobileMenu";

function Reply({
  style,
  className,
  children,
  comment: commentData,
  onReactComment,
  onUnReactComment,
  onDeleteComment,
  parentId,
  isReply,
  me,
  commentsDisabled,
  onReportComment,
}) {
  const ref = useRef(null);
  const customTheme = useSelector((state) => state.themeConfiguration);
  if (!commentData) {
    return null;
  }
  const {
    body,
    user,
    created_at: createdAt,
    comment_id: commentId,
    is_liked: isLiked,
    likes_count: likesCount,
    comment_count: commentCount,
    media,
    unique_reacts: uniqueReacts,
    meta_tags: metaTags,
  } = commentData;
  const isAuthor = me?.user_id === user?.user_id;

  return (
    <div
      className={`post-comment display-flex ${className}`}
      style={style}
      ref={ref}
    >
      <div>
        <NbAvatar
          profileImage={user?.profile?.url}
          size="large"
          firstName={user?.first_name}
          lastName={user?.last_name}
        />
      </div>
      <div className="post-comment-right display-flex flex1 flex-column">
        <div>
          <div className="post-comment-right-comment-content display-flex px4 pt1 pb2 flex1 mb2">
            <div className="flex1">
              <div className="display-flex align-items-center justify-content-space-between">
                <p className="display-flex align-items-center fn9 fnw-600">
                  {`${user?.first_name} ${user?.last_name}`}
                </p>
                <div className="display-flex mt1 justify-content-flex-end">
                  <span
                    className="fn6 nb-faded-text"
                    style={{ color: customTheme.primary_color }}
                  >
                    {formattedFromNow(createdAt)}
                  </span>
                  <MobileMenu>
                    <Dropdown
                      overlay={
                        <Menu>
                          {isAuthor && (
                            <NbButton
                              onClick={() =>
                                onDeleteComment(
                                  // parentId || commentId,
                                  // commentId
                                  parentId,
                                  commentId
                                )
                              }
                              type="button"
                              className="nb-proxy-button p4"
                              btnText="Delete"
                            />
                          )}
                          {!isAuthor && (
                            <NbButton
                              onClick={() =>
                                onReportComment(
                                  parentId || commentId,
                                  commentId,
                                  body
                                )
                              }
                              type="button"
                              className="nb-proxy-button"
                              btnText="Report"
                            />
                          )}
                        </Menu>
                      }
                      placement="bottomRight"
                      trigger="click"
                    >
                      <More className="ml3 c-pointer" height="12" />
                    </Dropdown>
                  </MobileMenu>
                </div>
              </div>

              <p className="o-wrap-any">{body}</p>
              {media && (
                <div className="comment-media mxw200 mt2">
                  <a href={media.url} target="_blank" rel="noreferrer">
                    <img className="w-p100" src={media.url} alt="" />
                  </a>
                </div>
              )}
              {metaTags && <MetaTagsContent metaTags={metaTags} />}
            </div>
          </div>
          <ReactionButtons
            showComment={!isReply && !commentsDisabled}
            onReact={(reactionKey) => onReactComment(reactionKey, commentId)}
            isLiked={isLiked}
            likesCount={likesCount}
            commentCount={commentCount}
            uniqueReacts={uniqueReacts}
            onUnReact={(reactionKey) =>
              onUnReactComment(reactionKey, commentId)
            }
          />
        </div>

        <div className="replies mt4">{children}</div>
      </div>
    </div>
  );
}

export default Reply;

Reply.defaultProps = {
  style: {},
  className: "",
  children: [],
  isReply: false,
};

Reply.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  comment: PropTypes.instanceOf(PropTypes.object).isRequired,
  onReactComment: PropTypes.func.isRequired,
  onUnReactComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
  me: PropTypes.instanceOf(PropTypes.object).isRequired,
  commentsDisabled: PropTypes.bool.isRequired,
  onReportComment: PropTypes.bool.isRequired,
};
