import React from "react";
import { useSelector } from "react-redux";

// components
import MessageJourney from "../../../components/createPost/journey/MessageJourney";
import AlertEditIcon from "../../icons/dynamicIcons/editIcon";

// scss
import "../../../components/nbEditModal/nbEditModal.scss";
import EditModal from "./editUserModal/editModal";

export default function EditPostModal({
  title,
  visible,
  onCancel,
  postData,
  editMode,
  maskClosable,
  closable,
  onPostCreated,
  children,
}) {
  if (!visible) return null;

  /* istanbul ignore next */
  const renderPostEditComponent = () => {
    return (
      <MessageJourney
        editMode={editMode}
        dataSource={postData?.data}
        closeEditModal={onCancel}
        onPostCreated={onPostCreated}
        onCancel={onCancel}
      />
    );
  };
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <EditModal
      icon={
        title && (
          <AlertEditIcon
            fillColorSecondary={customTheme.secondary_color}
            fillColorPrimary={customTheme.primary_color}
          />
        )
      }
      title={title}
      visible={visible}
      footer={false}
      centered
      className="update-post-modal nb-modal-content"
      destroyOnClose
      onCancel={onCancel}
      maskClosable={maskClosable}
      closable={closable}
    >
      <div className="update-post-modal-content">
        {children || renderPostEditComponent()}
      </div>
    </EditModal>
  );
}
/* istanbul ignore next */
EditPostModal.defaultProps = {
  editMode: true,
  maskClosable: false,
  title: undefined,
  closable: true,
  onPostCreated: () => null,
};
