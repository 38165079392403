import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// components
import Dropdown from "antd/es/dropdown/dropdown";
import { Collapse } from "antd";

// icons
import { LockRoundIcon } from "../../../icons/sider";

// scss
import "./nbVerticalSideMenu.scss";
import { slugify } from "../../../../utils";
import NbMoreButton from "../nbMoreButton/nbMoreButton";
import { DirectionDownIcon, DirectionUpIcon } from "../../../icons";

function MenuItem({
  className,
  menuItem,
  onClick,
  onKeyDown,
  itemClassName,
  primary,
  borderSimple,
  moreOptions,
}) {
  const { icon: Icon, disabled } = menuItem;
  const history = useHistory();
  const customTheme = useSelector((state) => state.themeConfiguration);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  let IconLocal = null;
  let isSelected = false;
  let menuData = menuItem.label;
  if (menuData.toLowerCase() === "all activities") {
    menuData = "all";
  }
  const paths = history.location.pathname.split("/").slice(0, 3);
  paths.forEach((slug) => {
    if (!isSelected && slug.trim()) {
      isSelected = slugify(menuData.toLowerCase()).includes(slug.toLowerCase());
    }
  });

  const isMainMenu = typeof Icon === "object";
  const isSubMenu = typeof Icon === "string";

  if (disabled) {
    IconLocal = <LockRoundIcon className="mr2 w2 side-menu-lock-icon" />;
  } else if (isSubMenu) {
    IconLocal = <img className="mr2 w40" src={Icon} alt="" />;
  } else if (isMainMenu) {
    IconLocal = Icon && (
      <Icon
        className="mr2 w25"
        style={{ background: isSelected ? customTheme.primary_color : "" }}
      />
    );
  }

  const expandIcon = (panelProps) => {
    return (
      <div className="mt1">
        {panelProps.isActive ? <DirectionUpIcon /> : <DirectionDownIcon />}
      </div>
    );
  };

  return (
    <div
      className={`nb-vertical-side-menu-item ${className} ${
        // isSelected || active === menuItem.key
        isSelected ? `nb-vertical-side-menu-item-active` : ""
      } ${primary ? "nb-vertical-side-menu-item-primary" : ""} ${
        borderSimple ? "nb-vertical-side-menu-item-border-simple" : ""
      } ${isSubMenu ? "side-menu-sub-container" : ""}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex="0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="display-flex">
        {!menuItem.subMenu ? (
          <Link
            to={menuItem.to ? menuItem.to : "#"}
            className={`nb-base-text px3 ${itemClassName ?? ""} ${
              primary ? "nb-vertical-side-menu-text-primary" : ""
            } ${isSubMenu ? "side-menu-sub" : "side-menu-main"}`}
            style={{ color: isHovered ? customTheme.primary_color : "" }}
          >
            {IconLocal}
            {menuItem.label}
          </Link>
        ) : (
          <Collapse
            // defaultActiveKey={["1"]}
            items={[
              {
                label: (
                  <Link
                    to={menuItem.to ? menuItem.to : "#"}
                    className={`nb-base-text px3 ${itemClassName ?? ""} ${
                      primary ? "nb-vertical-side-menu-text-primary" : ""
                    } ${isSubMenu ? "side-menu-sub" : "side-menu-main"}`}
                    style={{
                      color: isHovered ? customTheme.primary_color : "",
                    }}
                  >
                    {IconLocal}
                    {menuItem.label}
                  </Link>
                ),
                children: menuItem.subMenu || "",
              },
            ]}
            expandIconPosition="end"
            expandIcon={expandIcon}
            // onChange={onChange}
          />
        )}
        {moreOptions && (
          <Dropdown
            // menu={{ items: [{ key: 1, label: "Community Members" }] }}
            menu={moreOptions}
            placement="bottomRight"
            trigger="click"
            overlayStyle={{ width: "200px", zIndex: 9 }}
            overlayClassName="dropdown-wrapper"
          >
            <div>
              <NbMoreButton className="ml1" />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
}

function GuardedMenuItem({
  menuItem,
  index,
  onItemClick,
  itemClassName,
  primary,
  borderSimple,
  className,
  moreOptions,
}) {
  const { disabled } = menuItem;

  return disabled ? (
    <MenuItem
      menuItem={menuItem}
      onClick={() => onItemClick(menuItem, index)}
      onKeyDown={(event) => {
        if (event.key === "Enter") onItemClick(menuItem, index);
      }}
      className="menu-item-for-lite-user"
      primary={primary}
      borderSimple={borderSimple}
      itemClassName={itemClassName}
      moreOptions={moreOptions}
    />
  ) : (
    <MenuItem
      menuItem={menuItem}
      onClick={() => onItemClick(menuItem, index)}
      onKeyDown={(event) => {
        if (event.key === "Enter") onItemClick(menuItem, index);
      }}
      primary={primary}
      borderSimple={borderSimple}
      itemClassName={itemClassName}
      className={`menu-item-${index} ${className}`}
      moreOptions={moreOptions}
    />
  );
}

function NbVerticalSideMenu({
  menuList,
  onItemClick,
  title,
  itemClassName,
  primary,
  borderSimple,
  className,
}) {
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <div className="nb-vertical-side-menu ">
      {title && (
        <div
          className="nb-faded-text fn8 fnw-600 mb1"
          style={{ color: customTheme.primary_color }}
        >
          {title}
        </div>
      )}
      {menuList?.map((menuItem, index) => {
        return (
          <GuardedMenuItem
            key={menuItem.to}
            menuItem={menuItem}
            index={index}
            primary={primary}
            borderSimple={borderSimple}
            itemClassName={itemClassName}
            className={className}
            onItemClick={onItemClick}
            moreOptions={menuItem.moreOptions}
          />
        );
      })}
    </div>
  );
}

export default NbVerticalSideMenu;

NbVerticalSideMenu.defaultProps = {
  title: "",
  // moreMenuList: undefined,
};

NbVerticalSideMenu.propTypes = {
  menuList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // moreMenuList: PropTypes.arrayOf(PropTypes.object),
  onItemClick: PropTypes.func.isRequired,
  // active: PropTypes.string.isRequired,
  title: PropTypes.string,
};
