import React from 'react';

function ReportIcon({ fillColorPrimary, fillColorSecondary }) {
  return (
    <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="Report-Alert">
        <rect id="Bounding box" x="0" y="0" width="97" height="96" fill={fillColorSecondary} opacity="0.2" />
        <g mask="url(#mask0_1896_13597)">
          <path id="campaign" d="M59.7292 47.8948V43.5614H68.3958V47.8948H59.7292ZM62.3292 63.0614L55.3958 57.8614L57.9958 54.3948L64.9292 59.5948L62.3292 63.0614ZM57.9958 37.0614L55.3958 33.5948L62.3292 28.3948L64.9292 31.8614L57.9958 37.0614ZM31.5625 60.8948V52.2281H29.3958C28.2042 52.2281 27.184 51.8038 26.3354 50.9552C25.4868 50.1066 25.0625 49.0864 25.0625 47.8948V43.5614C25.0625 42.3698 25.4868 41.3496 26.3354 40.501C27.184 39.6524 28.2042 39.2281 29.3958 39.2281H38.0625L48.8958 32.7281V58.7281L38.0625 52.2281H35.8958V60.8948H31.5625ZM51.0625 52.9864V38.4698C52.0375 39.3364 52.8229 40.3927 53.4188 41.6385C54.0146 42.8844 54.3125 44.2476 54.3125 45.7281C54.3125 47.2087 54.0146 48.5719 53.4188 49.8177C52.8229 51.0635 52.0375 52.1198 51.0625 52.9864ZM29.3958 43.5614V47.8948H39.2542L44.5625 51.0364V40.4198L39.2542 43.5614H29.3958Z" fill={fillColorPrimary}/>
        </g>
      </g>
    </svg>
  );
};
export default ReportIcon;