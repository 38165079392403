import React from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";
import { BackIcon } from "../../../icons/createPost";
import { NbButton } from "../CustomButton";

export function ReadMoreCheckbox(props) {
  const { checked, onChange, neighboursLoader, btnDisabled } = props;
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <div className="rmp-check-container">
      <Checkbox
        checked={checked}
        onChange={onChange}
        className="rmp-check"
        disabled={neighboursLoader || btnDisabled}
      />

      <div className="rmp-label-container">
        <p className="m0 rmp-label">
          <span
            className="fnw-bold text-primary rmp-label-primary"
            style={{ color: customTheme.primary_color }}
          >
            Reach more people
          </span>
          Public post
        </p>
      </div>
    </div>
  );
}

export function AllowRSVPRadio(props) {
  const { checked, onChange, neighboursLoader, btnDisabled } = props;
  const customTheme = useSelector((state) => state.themeConfiguration);
  return (
    <div className="rmp-check-container">
      <Checkbox
        checked={checked}
        onChange={onChange}
        className="rmp-check"
        disabled={neighboursLoader || btnDisabled}
      />

      <div className="rmp-label-container">
        <span
          className="fnw-bold text-primary rmp-label-primary"
          style={{ color: customTheme.primary_color }}
        >
          Allow RSVPs for this event
        </span>
      </div>
    </div>
  );
}

export function PreviousBtn(props) {
  const { onClick, disabled, loading } = props;
  return (
    <NbButton
      type="default"
      className="rmp-check-container prev-btn-container pl1"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      btnText={
        <div className="display-flex align-items-center">
          <BackIcon className="rotate-90-neg prev-btn-icon" />

          <div className="rmp-label-container">
            <p className="m0 rmp-label prev-btn-text">Previous</p>
          </div>
        </div>
      }
    />
  );
}

export default function CardFooter(props) {
  const {
    containerClass,
    btnDisabled,
    onClickBtn,
    btnLoading,
    btnText,
    children,
    skipBtnDisabled,
    onSkipBtnClick,
    skipBtnLoading,
    showSkipBtn,
    onPreview,
    showPreviewBtn,
  } = props;

  return (
    <div
      className={`display-flex align-items-center card-footer bg-light-grey card-footer-radio-btn ${
        containerClass ?? ""
      }`}
    >
      {children}
      <div className="next-btn-container display-flex">
        {showSkipBtn && (
          <NbButton
            type="primary"
            className={`btn btn-transparent mr3 ${
              skipBtnDisabled ? "btn-disabled" : ""
            }`}
            disabled={skipBtnDisabled}
            onClick={onSkipBtnClick}
            loading={skipBtnLoading}
            btnText="Skip"
          />
        )}

        {showPreviewBtn && (
          <NbButton
            onClick={onPreview}
            type="primary"
            className={`btn ml2 ${btnDisabled ? "btn-disabled" : ""}`}
            disabled={btnDisabled}
            btnText="Preview"
          />
        )}

        <NbButton
          type="primary"
          className={`btn ml2 ${btnDisabled ? "btn-disabled" : ""}`}
          disabled={btnDisabled}
          onClick={onClickBtn}
          loading={btnLoading}
          btnText={btnText}
        />
      </div>
    </div>
  );
}
