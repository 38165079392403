import { combineReducers } from "redux";
import auth from "./auth/reducer";
import me from "./auth/me";
import {
  postReducer,
  widgetsReducer,
  globalComponentsReducer,
  themeConfigurationReducer,
} from "./Reducers";

const rootReducer = combineReducers({
  auth,
  me,
  post: postReducer,
  widgets: widgetsReducer,
  globalComponents: globalComponentsReducer,
  themeConfiguration: themeConfigurationReducer,
});

export default rootReducer;
