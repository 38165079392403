import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Input, Upload, Form, notification, Badge } from "antd";
import EmojiMart from "../../../assets/gobal/components/emojiMart/emojiMart";

// icons
import Icons from "../../../assets/icons/createPost";

// utils
import { notify, useMediaUploader } from "../../../utils";

// scss
import "./postCommentForm.scss";
import Info from "../../../assets/gobal/components/Info/Info";
import config from "../../../config";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import NbAvatar from "../../../assets/gobal/components/nbAvatar/nbAvatar";
import { AlertIcon } from "../../../assets/icons";

function PostCommentForm({
  style,
  className,
  onComment,
  isLoading,
  apiResponse,
  placeholder,
}) {
  let inputRef = React.createRef();
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [isShowMessage, setShowMessage] = useState(false);
  const [mediaId, setMediaId] = useState("");
  const [form] = Form.useForm();
  const me = useSelector((state) => state.me);

  const mediaUploader = useMediaUploader();

  useEffect(() => {
    if (apiResponse?.statusCode === 201) {
      setText("");
      setImage(null);
      setMediaId(null);
      form?.current?.resetFields();
    }
  }, [apiResponse]);

  const addEmoji = (e) => {
    setText(text + e.native);
  };

  /* istanbul ignore next */
  const customRequest = async (data) => {
    try {
      const res = await mediaUploader.upload({
        file: data.file,
        title: data.file?.name,
      });
      data.onSuccess();
      setImage(res.data.url);
      setMediaId(res.data.media_id);
      notify(res.message, "success");
    } catch (err) {
      notify(err?.message, "error");
    }
  };

  /* istanbul ignore next */
  const deleteImg = async (del) => {
    try {
      const res = await mediaUploader.deleteMedia(del.uid);
      setImage(null);
      notify(res.message, "success");
    } catch (err) {
      notify(err?.message, "error");
    }
  };
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <div
      className={`post-comment-form display-flex mt10 ${className}`}
      style={style}
    >
      <div className="mr4 mt2">
        {me?.profile?.url ? (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
            count={me?.status === "temp_verified" ? "L" : null}
          >
            <NbAvatar
              profileImage={me?.profile?.url}
              firstName={me?.first_name}
              lastName={me?.last_name}
              size={42}
            />
          </Badge>
        ) : (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
            count={me?.status === "temp_verified" ? "L" : null}
          >
            <NbAvatar
              profileImage={me?.profile?.url}
              firstName={me?.first_name}
              lastName={me?.last_name}
              size="large"
            />
          </Badge>
        )}
      </div>
      <div className="flex1">
        <Input
          onFocus={() => setShowMessage(true)}
          onChange={(e) => setText(e.target.value)}
          value={text}
          showCount
          maxLength={8000}
          className="nb-text-input"
          placeholder={placeholder}
          suffix={
            <div className="display-flex align-items-center">
              <span>
                <EmojiMart onClick={addEmoji} />
              </span>
              <span className="ml2">
                <button
                  type="button"
                  onClick={() => inputRef.click()}
                  className="nb-proxy-button"
                >
                  <img
                    className="post-comment-form-icon-button c-pointer"
                    src={Icons.Image}
                    alt=""
                  />
                </button>
              </span>
            </div>
          }
        />
        <Form name="form" ref={form} initialValues={{ dragger: "" }}>
          <Form.Item name="dragger" noStyle>
            <Upload
              className="post-comment-form-uploader mt2"
              customRequest={customRequest}
              maxCount={1}
              listType="picture-card"
              onRemove={deleteImg}
            >
              <input
                type="hidden"
                ref={(r) => {
                  inputRef = r;
                }}
              />
            </Upload>
          </Form.Item>
        </Form>
        {isShowMessage && (
          <div className="display-flex">
            <div className="flex1 display-flex justify-content-flex-end align-items-center ml4">
              <Info
                title={`Max upload size is ${config.uploadSize}`}
                style={{
                  margin: "0px 15px",
                  borderColor: customTheme.primary_color,
                }}
              />
              <NbButton
                type="primary"
                className="nb-button"
                onClick={() => {
                  /* istanbul ignore next */
                  if (text || image || mediaId) {
                    onComment({ text, image, mediaId });
                  }
                }}
                loading={isLoading}
                btnText="Post"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PostCommentForm;

PostCommentForm.defaultProps = {
  style: {},
  className: "",
  isLoading: false,
  apiResponse: undefined,
  placeholder: "Add your comment",
};

PostCommentForm.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  onComment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  apiResponse: PropTypes.instanceOf(PropTypes.object),
  placeholder: PropTypes.string,
};
