import PropTypes from "prop-types";
import ReactDOM from "react-dom";

function Portal({ children, mountNode }) {
  return ReactDOM.createPortal(children, mountNode);
}

export default Portal;

Portal.defaultProps = {
  mountNode: window.document.body,
};

Portal.propTypes = {
  children: PropTypes.element.isRequired,
  mountNode: PropTypes.element.isRequired,
};
