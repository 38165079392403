import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Switch } from "antd";

function NbSwitch({ className, defaultChecked, name, valuePropName }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  return (
    <Form.Item name={name} valuePropName={valuePropName}>
      <Switch
        className={className}
        checked={isChecked}
        onChange={handleSwitchChange}
      />
    </Form.Item>
  );
}
export default NbSwitch;

NbSwitch.defaultProps = {
  className: "",
  defaultChecked: false,
  name: "",
  valuePropName: "checked",
};

NbSwitch.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  name: PropTypes.string,
  valuePropName: PropTypes.string,
};
