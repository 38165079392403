import React from 'react';

function FullLogoIcon({ fillColor }) {
  return (
    <svg width="201" height="64" viewBox="0 0 201 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.2884 7.27609H45.5332V45.5375H46.2884V7.27609Z" fill={fillColor}/>
      <path d="M82.1282 20.4424V19.8237C82.1282 16.3588 79.4047 13.4633 75.9383 13.4138C74.0689 13.3519 70.7635 13.3519 66.0591 13.3519H65.7249C62.828 13.3519 60.3891 13.3519 58.5074 13.4138C55.041 13.4756 52.3174 16.3588 52.3174 19.8237V32.9404C52.3174 36.3434 54.583 39.1276 59.7578 39.4122C62.3328 39.3998 61.33 39.3998 66.0591 39.4122C70.7759 39.4246 74.1184 39.3503 76.0002 39.3008C77.7086 39.239 79.1818 38.6202 80.3703 37.3704C81.5588 36.1206 82.1901 34.6481 82.1901 32.9404V32.8786H85.087C85.087 33.3117 85.0747 33.7448 85.0251 34.1779C84.8518 36.2815 83.9976 38.15 82.413 39.7339C80.8284 41.2684 79.0085 42.1222 76.9039 42.2335C73.6604 42.4068 70.0331 42.5182 66.1706 42.5182H66.0591C63.6203 42.5182 60.7729 42.4068 57.5913 42.2335C55.4867 42.1222 53.6792 41.2684 52.0822 39.6844C50.4852 38.1005 49.6434 36.2815 49.5196 34.1779C49.3463 31.5669 49.2968 29.1291 49.2968 26.8028V26.4068C49.2968 22.3233 49.3587 19.9969 49.4082 19.3658C49.6929 16.9776 50.5471 14.9977 51.9089 13.4633C53.444 11.7061 55.3134 10.7904 57.5913 10.6295C60.9462 10.4068 63.7193 10.2831 66.0591 10.2831C69.9836 10.2831 73.6109 10.3944 76.8544 10.6295C78.959 10.8028 80.7788 11.6566 82.3635 13.2405C83.9605 14.7749 84.8023 16.594 84.9756 18.6852C85.0375 19.4772 85.0375 20.0464 85.087 20.4424H82.1282Z" fill={fillColor}/>
      <path d="M39.5661 20.4424V19.8237C39.5661 16.3588 36.8425 13.4633 33.3762 13.4138C31.5068 13.3519 28.2014 13.3519 23.497 13.3519H23.1627C20.2658 13.3519 17.827 13.3519 15.9453 13.4138C12.4789 13.4756 9.75531 16.3588 9.75531 19.8237V32.9404C9.75531 36.3434 12.0208 39.1276 17.1956 39.4122C19.7707 39.3998 18.7679 39.3998 23.497 39.4122C28.2137 39.4246 31.5563 39.3503 33.4381 39.3008C35.1465 39.239 36.6197 38.6202 37.8082 37.3704C38.9966 36.1206 39.628 34.6481 39.628 32.9404V32.8786H42.5249C42.5249 33.3117 42.5125 33.7448 42.463 34.1779C42.2897 36.2815 41.4355 38.15 39.8508 39.7339C38.2662 41.2684 36.4464 42.1222 34.3418 42.2335C31.0983 42.4068 27.4709 42.5182 23.6084 42.5182H23.497C21.0582 42.5182 18.2108 42.4068 15.0292 42.2335C12.9246 42.1222 11.1171 41.2684 9.5201 39.6844C7.92309 38.1005 7.08126 36.2815 6.95746 34.1779C6.78414 31.5669 6.73462 29.1291 6.73462 26.8028V26.4068C6.73462 22.3233 6.79652 19.9969 6.84604 19.3658C7.13078 16.9776 7.98499 14.9977 9.34678 13.4633C10.8819 11.7061 12.7512 10.7904 15.0292 10.6295C18.3841 10.4068 21.1572 10.2831 23.497 10.2831C27.4214 10.2831 31.0487 10.3944 34.2923 10.6295C36.3969 10.8028 38.2167 11.6566 39.8013 13.2405C41.3983 14.7749 42.2402 16.594 42.4135 18.6852C42.4754 19.4772 42.4754 20.0464 42.5249 20.4424H39.5661Z" fill={fillColor}/>
      <path d="M102.258 17.3241V17.1137C102.27 16.5445 102.047 16 101.664 15.5917C101.156 15.0596 99.9553 14.8244 98.0364 14.8616C96.1175 14.8987 94.8548 14.9234 94.2482 14.9358C93.6787 14.9358 93.1216 15.1709 92.7254 15.5793C92.3293 15.9876 92.1064 16.5445 92.1188 17.1137V21.5808C92.1188 22.2738 92.3417 22.8059 92.775 23.1524C93.2082 23.5112 93.6168 23.6968 93.9758 23.734C94.3348 23.7711 95.2757 23.7711 96.7984 23.7587C98.4326 23.7587 99.5468 23.7463 100.141 23.734C101.317 23.7092 102.27 22.7564 102.27 21.5685V21.5437H103.261C103.261 21.6551 103.248 21.8036 103.236 21.9892C103.186 22.7069 102.877 23.3751 102.357 23.8701C101.862 24.3898 101.193 24.6992 100.475 24.7239C99.2992 24.7858 98.0859 24.8229 96.8479 24.8229H96.8108C96.068 24.8229 95.1024 24.7858 93.9263 24.7239C93.2082 24.6868 92.5397 24.3774 92.0569 23.8701C91.5493 23.3751 91.2398 22.6945 91.2027 21.9892C91.1532 21.0364 91.1284 20.1949 91.1284 19.4772V19.3411C91.1284 17.9552 91.1408 17.1508 91.1656 16.9405C91.2646 16.1238 91.5493 15.4555 91.995 14.9358C92.4778 14.3542 93.1711 14.0077 93.9263 13.9706C95.0776 13.8964 96.1794 13.8593 97.2565 13.8593C98.3335 13.8593 99.3982 13.8964 100.475 13.9706C101.156 14.0077 101.812 14.28 102.332 14.7255C102.865 15.1709 103.174 16.0371 103.273 17.3117H102.258V17.3241Z" fill={fillColor}/>
      <path d="M114.316 20.7641C114.316 21.7293 114.304 22.3109 114.279 22.5213C114.229 23.0781 114.006 23.5978 113.647 24.0309C113.288 24.464 112.781 24.7239 112.224 24.7486C111.469 24.7981 110.565 24.8229 109.525 24.8229C108.411 24.8229 107.495 24.7981 106.801 24.7486C106.269 24.6991 105.786 24.464 105.427 24.0804C105.043 23.7216 104.808 23.2266 104.771 22.6945C104.734 22.1748 104.722 21.5684 104.722 20.8631V20.7641C104.722 19.7123 104.734 19.1183 104.746 19.007C104.796 18.4377 105.019 17.8933 105.39 17.4602C105.749 17.0394 106.257 16.792 106.801 16.7672C109.24 16.693 111.048 16.693 112.224 16.7672C113.313 16.8786 114.167 17.7324 114.266 18.809C114.304 19.3411 114.328 19.9722 114.328 20.727L114.316 20.7641ZM113.387 22.3233V19.1431C113.387 18.7347 113.227 18.3511 112.942 18.0665C112.669 17.7695 112.286 17.6087 111.877 17.6087C109.933 17.5963 108.733 17.5963 108.25 17.5963C107.779 17.6087 107.371 17.6087 107.024 17.6087C106.678 17.6087 106.368 17.7571 106.071 18.0665C105.786 18.3511 105.625 18.7347 105.625 19.1431V22.3233C105.625 22.7316 105.786 23.1152 106.071 23.3998C106.343 23.6968 106.727 23.8577 107.136 23.8577C108.906 23.8701 110.132 23.8824 110.812 23.8824C111.246 23.8948 111.691 23.8701 112.125 23.8206C112.323 23.7834 112.583 23.6226 112.905 23.3503C113.214 23.1029 113.387 22.7193 113.387 22.3233Z" fill={fillColor}/>
      <path d="M125.73 20.7641C125.73 21.7293 125.718 22.3109 125.693 22.5213C125.644 23.0781 125.421 23.5978 125.062 24.0309C124.703 24.464 124.195 24.7239 123.638 24.7486C122.883 24.7981 121.979 24.8229 120.939 24.8229C119.825 24.8229 118.909 24.7981 118.216 24.7486C117.683 24.6991 117.2 24.464 116.841 24.0804C116.458 23.7216 116.222 23.2266 116.185 22.6945C116.148 22.1748 116.136 21.5684 116.136 20.8631V20.7641C116.136 19.7123 116.148 19.1183 116.161 19.007C116.21 18.4377 116.433 17.8933 116.804 17.4602C117.163 17.0394 117.671 16.792 118.216 16.7672C120.654 16.693 122.462 16.693 123.638 16.7672C124.727 16.8786 125.582 17.7324 125.681 18.809C125.718 19.3411 125.743 19.9722 125.743 20.727L125.73 20.7641ZM124.802 22.3233V19.1431C124.802 18.7347 124.641 18.3511 124.356 18.0665C124.084 17.7695 123.7 17.6087 123.291 17.6087C121.348 17.5963 120.147 17.5963 119.664 17.5963C119.194 17.6087 118.785 17.6087 118.438 17.6087C118.092 17.6087 117.782 17.7571 117.485 18.0665C117.2 18.3511 117.039 18.7347 117.039 19.1431V22.3233C117.039 22.7316 117.2 23.1152 117.485 23.3998C117.758 23.6968 118.141 23.8577 118.55 23.8577C120.32 23.8701 121.546 23.8824 122.227 23.8824C122.66 23.8948 123.106 23.8701 123.539 23.8206C123.737 23.7834 123.997 23.6226 124.319 23.3503C124.628 23.1029 124.814 22.7193 124.802 22.3233Z" fill={fillColor}/>
      <path d="M136.575 20.7641C136.575 21.8159 136.55 22.5336 136.513 22.8925C136.476 23.2514 136.278 23.635 135.931 24.0309C135.572 24.464 135.052 24.7239 134.483 24.7486C131.673 24.7981 129.853 24.7981 129.048 24.7486C128.627 24.7239 128.219 24.5507 127.897 24.2908V27.7061H126.968V16.8291H127.897V17.2498C128.219 16.9652 128.627 16.8043 129.048 16.7672C129.853 16.7177 130.794 16.693 131.858 16.693C132.923 16.693 133.802 16.7177 134.495 16.7672C135.028 16.8167 135.51 17.0518 135.869 17.4354C136.253 17.7943 136.488 18.2893 136.526 18.8213C136.563 19.3287 136.575 19.9722 136.575 20.7641ZM135.647 22.3233V19.1555C135.647 18.7595 135.498 18.3635 135.213 18.0789C134.941 17.7819 134.557 17.621 134.161 17.621H129.382C128.986 17.621 128.615 17.7695 128.342 18.0418C128.058 18.3016 127.897 18.6729 127.897 19.0688V22.3975C127.897 22.7811 128.058 23.1647 128.342 23.4246C128.615 23.6968 128.999 23.8577 129.382 23.8453C131.957 23.8577 133.505 23.8577 134.025 23.8453C134.545 23.8329 134.941 23.6721 135.226 23.3875C135.498 23.1029 135.647 22.7193 135.647 22.3233Z" fill={fillColor}/>
      <path d="M147.68 21.2096H139.026V22.3357C139.026 22.7316 139.175 23.1276 139.459 23.4122C139.732 23.7092 140.116 23.8701 140.512 23.8701H145.278C145.649 23.8824 146.008 23.734 146.268 23.4617C146.516 23.1895 146.64 22.8059 146.64 22.2985L147.667 22.3233C147.68 22.5831 147.655 22.843 147.605 23.1029C147.556 23.4493 147.333 23.7958 146.962 24.1547C146.603 24.5135 146.12 24.7239 145.625 24.7486C144.931 24.7981 144.015 24.8229 142.901 24.8229C141.775 24.8229 140.871 24.7981 140.178 24.7486C139.645 24.6991 139.162 24.464 138.791 24.0804C138.407 23.7216 138.172 23.2266 138.135 22.6945C138.098 22.1748 138.085 21.4695 138.085 20.5538C138.085 19.6381 138.098 19.1307 138.11 19.007C138.16 18.4377 138.382 17.8933 138.754 17.4602C139.113 17.0394 139.62 16.792 140.178 16.7672C140.933 16.7177 141.861 16.693 142.938 16.693C144.015 16.693 144.919 16.7177 145.625 16.7672C146.714 16.8786 147.568 17.7448 147.667 18.8337C147.692 19.3534 147.692 20.1454 147.68 21.2096ZM146.751 20.2815V19.1555C146.751 18.7595 146.603 18.3635 146.318 18.0789C146.046 17.7819 145.662 17.621 145.266 17.621H140.512C140.116 17.621 139.732 17.7819 139.459 18.0789C139.175 18.3635 139.026 18.7471 139.026 19.1555V20.2815H146.751Z" fill={fillColor}/>
      <path d="M151.084 17.5592C150.923 17.5592 150.762 17.6087 150.626 17.6705C150.02 17.9428 149.71 18.3882 149.698 19.0193C149.685 19.3163 149.685 21.222 149.722 24.761H148.794L148.819 16.8167H149.722V17.1261C150.131 16.8167 150.527 16.6682 150.899 16.6682H151.901V17.5592H151.084Z" fill={fillColor}/>
      <path d="M163.006 24.5135V24.761L162.078 24.7363V24.2413C161.657 24.5754 161.298 24.7487 161.001 24.761C160.716 24.7858 159.8 24.7858 158.265 24.7858H156.135C155.454 24.8105 154.786 24.563 154.278 24.0928C153.783 23.635 153.498 22.9915 153.523 22.3233C153.535 20.9992 154.588 19.9227 155.925 19.8856C156.581 19.8732 157.336 19.8608 158.203 19.8608H158.24C159.8 19.8608 161.075 19.8732 162.065 19.8856V19.1431C162.065 18.7471 161.917 18.3511 161.632 18.0665C161.36 17.7695 160.976 17.5963 160.58 17.6087H156.234C155.875 17.6087 155.529 17.7448 155.269 17.9923C154.996 18.2274 154.811 18.5615 154.749 18.9204H153.857C153.882 18.3511 154.117 17.819 154.526 17.4231C154.885 17.0395 155.38 16.8043 155.912 16.7548C156.606 16.7054 157.373 16.6806 158.19 16.6806H158.215C159.305 16.6806 160.208 16.7054 160.926 16.7548C161.471 16.792 161.991 17.0271 162.375 17.4107C162.783 17.7572 163.006 18.2645 163.018 18.7966L163.006 24.5135ZM162.078 22.1872V20.7889H158.896C157.547 20.8013 156.606 20.8136 156.086 20.8136C155.665 20.8013 155.244 20.9374 154.922 21.222C154.625 21.4942 154.464 21.8654 154.464 22.3357C154.452 22.7564 154.625 23.1524 154.947 23.4246C155.269 23.7092 155.69 23.8701 156.123 23.8701C156.581 23.8825 157.299 23.8948 158.277 23.8948C159.552 23.9072 160.233 23.9072 160.344 23.8948C161.508 23.7463 162.078 23.1771 162.078 22.1872Z" fill={fillColor}/>
      <path d="M167.822 17.6705H166.522V24.761H165.593V17.6705H164.294V16.7672H165.593V13.9088H166.522V16.7672H167.822V17.6705Z" fill={fillColor}/>
      <path d="M170.409 15.5793V13.884H171.338V15.5793H170.409ZM170.409 24.761V16.8167H171.338V24.761H170.409Z" fill={fillColor}/>
      <path d="M178.345 24.761H177.268L173.269 16.8167H174.297L177.812 23.7711L181.328 16.8167H182.368L178.345 24.761Z" fill={fillColor}/>
      <path d="M193.733 21.2096H185.079V22.3357C185.079 22.7316 185.228 23.1276 185.513 23.4122C185.785 23.7092 186.169 23.8701 186.565 23.8701H191.331C191.703 23.8824 192.062 23.734 192.322 23.4617C192.569 23.1895 192.693 22.8059 192.693 22.2985L193.721 22.3233C193.733 22.5831 193.708 22.843 193.659 23.1029C193.609 23.4493 193.386 23.7958 193.015 24.1547C192.656 24.5135 192.173 24.7239 191.678 24.7486C190.985 24.7981 190.069 24.8229 188.954 24.8229C187.828 24.8229 186.924 24.7981 186.231 24.7486C185.698 24.6991 185.216 24.464 184.844 24.0804C184.46 23.7216 184.225 23.2266 184.188 22.6945C184.151 22.1748 184.139 21.4695 184.139 20.5538C184.139 19.6381 184.151 19.1307 184.163 19.007C184.213 18.4377 184.436 17.8933 184.807 17.4602C185.166 17.0394 185.674 16.792 186.231 16.7672C186.986 16.7177 187.914 16.693 188.991 16.693C190.069 16.693 190.972 16.7177 191.678 16.7672C192.767 16.8786 193.622 17.7448 193.721 18.8337C193.733 19.3534 193.745 20.1454 193.733 21.2096ZM192.804 20.2815V19.1555C192.804 18.7595 192.656 18.3635 192.371 18.0789C192.099 17.7819 191.715 17.621 191.319 17.621H186.565C186.169 17.621 185.785 17.7819 185.513 18.0789C185.228 18.3635 185.079 18.7471 185.079 19.1555V20.2815H192.804Z" fill={fillColor}/>
      <path d="M102.258 31.4431V31.2328C102.27 30.6636 102.047 30.1191 101.664 29.7107C101.156 29.1786 99.9553 28.9435 98.0364 28.9807C96.1175 29.0178 94.8548 29.0425 94.2482 29.0549C93.6787 29.0549 93.1216 29.29 92.7254 29.6984C92.3293 30.1067 92.1064 30.6636 92.1188 31.2328V35.6999C92.1188 36.3929 92.3417 36.925 92.775 37.2715C93.2082 37.6303 93.6168 37.8159 93.9758 37.853C94.3348 37.8902 95.2757 37.8902 96.7984 37.8778C98.4326 37.8778 99.5468 37.8654 100.141 37.853C101.317 37.8283 102.27 36.8755 102.27 35.6875V35.6628H103.261C103.261 35.7742 103.248 35.9226 103.236 36.1083C103.186 36.826 102.877 37.4942 102.357 37.9892C101.862 38.5089 101.193 38.8182 100.475 38.843C99.2992 38.9049 98.0859 38.942 96.8479 38.942H96.8108C96.068 38.942 95.1024 38.9049 93.9263 38.843C93.2082 38.8059 92.5397 38.4965 92.0569 37.9892C91.5493 37.4942 91.2398 36.8136 91.2027 36.1083C91.1532 35.1554 91.1284 34.314 91.1284 33.5963V33.4602C91.1284 32.0742 91.1408 31.2699 91.1656 31.0595C91.2398 30.3171 91.5246 29.6241 91.995 29.0549C92.4778 28.4733 93.1711 28.1268 93.9263 28.0897C95.0776 28.0155 96.1794 27.9783 97.2565 27.9783C98.3335 27.9783 99.3982 28.0155 100.475 28.0897C101.156 28.1268 101.812 28.3991 102.332 28.8445C102.865 29.29 103.174 30.1562 103.273 31.4308L102.258 31.4431Z" fill={fillColor}/>
      <path d="M115.628 34.8708C115.628 35.836 115.616 36.4176 115.591 36.628C115.542 37.1848 115.319 37.7045 114.96 38.1377C114.601 38.5708 114.093 38.8306 113.536 38.8554C112.781 38.9049 111.877 38.9296 110.837 38.9296C109.723 38.9296 108.807 38.9049 108.114 38.8554C107.581 38.8059 107.098 38.5708 106.739 38.1871C106.356 37.8283 106.12 37.3333 106.083 36.8012C106.046 36.2815 106.034 35.6752 106.034 34.9698V34.8708C106.034 33.819 106.046 33.225 106.059 33.1137C106.108 32.5445 106.331 32 106.702 31.5669C107.061 31.1462 107.569 30.8987 108.114 30.8739C110.552 30.7997 112.36 30.7997 113.536 30.8739C114.625 30.9853 115.48 31.8391 115.579 32.9157C115.616 33.4478 115.641 34.0789 115.641 34.8337L115.628 34.8708ZM114.7 36.43V33.2498C114.7 32.8414 114.539 32.4578 114.254 32.1732C113.982 31.8762 113.598 31.7154 113.189 31.7154C111.246 31.703 110.045 31.703 109.562 31.703C109.092 31.7154 108.683 31.7154 108.336 31.7154C107.99 31.7154 107.68 31.8639 107.383 32.1732C107.098 32.4578 106.938 32.8414 106.938 33.2498V36.43C106.938 36.8383 107.098 37.2219 107.383 37.5066C107.656 37.8035 108.039 37.9644 108.448 37.9644C110.218 37.9768 111.444 37.9892 112.125 37.9892C112.558 38.0015 113.004 37.9768 113.437 37.9273C113.635 37.8902 113.895 37.7293 114.217 37.4571C114.526 37.2096 114.712 36.8383 114.7 36.43Z" fill={fillColor}/>
      <path d="M134.52 34.8708L134.495 38.8554H133.567V33.2745C133.567 32.8662 133.406 32.4826 133.121 32.198C132.849 31.901 132.452 31.7401 132.056 31.7401C129.506 31.7401 128.219 31.7525 128.181 31.7649C127.81 31.802 127.463 31.9752 127.203 32.2599C126.956 32.5321 126.82 32.9033 126.82 33.2745V38.8554H125.817V33.2745C125.817 32.8786 125.668 32.4826 125.384 32.198C125.111 31.901 124.727 31.7401 124.331 31.7401C121.806 31.7401 120.518 31.7525 120.481 31.7649C120.11 31.7896 119.763 31.9505 119.503 32.2227C119.243 32.4826 119.095 32.8291 119.107 33.2003V38.8554H118.178V30.9358H119.107V31.3565C119.429 31.0719 119.837 30.9111 120.271 30.8739C121.075 30.8244 121.942 30.7997 122.833 30.7997C123.737 30.7997 124.232 30.8121 124.344 30.8244C125.26 30.9358 125.916 31.2947 126.337 31.8886C126.683 31.3937 127.216 31.0472 127.81 30.9234C128.144 30.8492 128.825 30.8121 129.84 30.8121C130.855 30.8121 131.722 30.8368 132.428 30.8863C132.96 30.9234 133.455 31.1585 133.839 31.5421C134.223 31.901 134.458 32.4083 134.483 32.9281C134.508 33.5715 134.52 34.215 134.52 34.8708Z" fill={fillColor}/>
      <path d="M146.85 34.8708C146.85 35.9227 146.826 36.6404 146.788 36.9992C146.751 37.3581 146.553 37.7417 146.207 38.1377C145.848 38.5708 145.328 38.8306 144.758 38.8554C141.948 38.9049 140.128 38.9049 139.323 38.8554C138.902 38.8306 138.494 38.6574 138.172 38.3975V41.8128H137.244V30.9358H138.172V31.3565C138.494 31.0719 138.902 30.9111 139.323 30.8739C140.128 30.8244 141.069 30.7997 142.134 30.7997C143.198 30.7997 144.077 30.8244 144.771 30.8739C145.303 30.9234 145.786 31.1585 146.145 31.5421C146.528 31.901 146.764 32.396 146.801 32.9281C146.838 33.4354 146.85 34.0913 146.85 34.8708ZM145.922 36.4424V33.2745C145.922 32.8786 145.773 32.4826 145.489 32.198C145.216 31.901 144.832 31.7401 144.436 31.7401H139.658C139.261 31.7401 138.89 31.8886 138.618 32.1609C138.333 32.4207 138.172 32.792 138.172 33.1879V36.5042C138.172 36.8879 138.333 37.2715 138.618 37.5313C138.89 37.8035 139.274 37.9644 139.658 37.952C142.233 37.9644 143.78 37.9644 144.3 37.952C144.82 37.9397 145.216 37.7788 145.501 37.4942C145.773 37.222 145.934 36.8384 145.922 36.4424Z" fill={fillColor}/>
      <path d="M158.079 38.8554H157.15V38.4347C156.816 38.7069 156.408 38.8678 155.974 38.8925C155.132 38.942 154.588 38.9668 154.315 38.9668C153.535 38.9668 152.619 38.942 151.592 38.8925C151.059 38.8678 150.564 38.6327 150.205 38.249C149.821 37.8778 149.586 37.3828 149.561 36.8507C149.524 36.3187 149.512 35.6876 149.512 34.9327V30.9111H150.44V36.529C150.44 36.9374 150.601 37.321 150.886 37.6056C151.159 37.9026 151.542 38.0634 151.951 38.0634H154.749C155.306 38.0511 155.652 38.0387 155.789 38.0263C156.16 38.0016 156.507 37.8407 156.779 37.5685C157.039 37.3086 157.175 36.9621 157.163 36.5909V30.9111H158.091L158.079 38.8554Z" fill={fillColor}/>
      <path d="M164.529 31.7896H163.229V38.8801H162.3V31.7896H161V30.8863H162.3V28.0278H163.229V30.8863H164.529V31.7896Z" fill={fillColor}/>
      <path d="M168.441 29.6984V28.0031H169.369V29.6984H168.441ZM168.441 38.8677V30.9358H169.369V38.8801H168.441V38.8677Z" fill={fillColor}/>
      <path d="M181.167 34.8708L181.143 38.8554H180.214V33.2622C180.214 32.8662 180.066 32.4702 179.781 32.1856C179.509 31.8886 179.125 31.7278 178.729 31.7278C176.203 31.7278 174.928 31.7401 174.878 31.7525C174.507 31.7773 174.16 31.9505 173.9 32.2104C173.64 32.4702 173.492 32.8167 173.504 33.1879V38.8554H172.576V30.9358H173.504V31.3442C173.826 31.0719 174.235 30.8987 174.668 30.8739C175.485 30.8244 176.24 30.7997 176.946 30.7997C177.652 30.7997 178.357 30.8244 179.075 30.8739C179.608 30.9111 180.103 31.1462 180.474 31.5174C180.858 31.8762 181.093 32.3712 181.118 32.9033C181.155 33.5468 181.167 34.2026 181.167 34.8708Z" fill={fillColor}/>
      <path d="M194.253 31.6906C193.956 31.7401 193.683 31.8639 193.473 32.0619C193.671 32.4207 193.77 33.1384 193.77 34.2026V38.2738C193.783 38.8306 193.77 39.3875 193.708 39.9319C193.634 40.4888 193.386 40.9961 192.99 41.4045C192.619 41.8005 192.099 42.0108 191.567 41.9985H185.129V41.1075H191.43C191.814 41.1199 192.198 40.959 192.458 40.6744C192.73 40.3898 192.866 40.0062 192.866 39.6226C192.879 39.4988 192.891 39.3751 192.891 39.2514C192.891 39.1895 192.879 38.8925 192.866 38.3852C192.532 38.6574 192.136 38.8183 191.703 38.843C188.769 38.8925 186.949 38.8925 186.256 38.843C185.736 38.8059 185.24 38.5708 184.869 38.1872C184.485 37.8283 184.25 37.3333 184.225 36.8136C184.188 36.0588 184.163 35.4277 184.163 34.9203V34.8585C184.163 33.8933 184.176 33.3117 184.2 33.1013C184.25 32.5445 184.473 32.0248 184.819 31.5917C185.166 31.1586 185.686 30.8863 186.256 30.8616C187.011 30.8121 187.914 30.7873 188.942 30.7873H188.979C189.957 30.7873 190.861 30.8121 191.703 30.8616C192.148 30.8987 192.582 31.0843 192.916 31.3813C193.287 31.0596 193.758 30.8492 194.253 30.7873V31.6906ZM192.842 36.43V33.2498C192.842 32.8538 192.693 32.4579 192.408 32.1732C192.136 31.8763 191.752 31.7154 191.356 31.7154H186.577C186.181 31.7154 185.797 31.8763 185.525 32.1732C185.24 32.4579 185.092 32.8415 185.092 33.2498V36.43C185.092 36.826 185.24 37.222 185.525 37.5066C185.797 37.8036 186.181 37.9644 186.577 37.9644L188.979 37.9892H189.437C190.638 37.9892 191.356 37.9768 191.616 37.9644C191.864 37.9521 192.136 37.7912 192.421 37.4942C192.693 37.222 192.854 36.8384 192.842 36.43Z" fill={fillColor}/>
      <path d="M9.73059 54.7935V48.3712H11.6247C12.318 48.3712 12.8379 48.5073 13.197 48.7919C13.556 49.0766 13.7293 49.4973 13.7293 50.0417C13.7417 50.3511 13.6426 50.6605 13.4569 50.908C13.2712 51.1554 13.0113 51.3411 12.7141 51.44C13.4446 51.5885 13.9645 52.232 13.9645 52.9745C13.9645 53.5437 13.7788 53.9892 13.4198 54.2985C13.0484 54.6202 12.5408 54.7811 11.8971 54.7811L9.73059 54.7935ZM10.2629 51.2544H11.7485C12.2066 51.2421 12.5532 51.1431 12.8008 50.9327C13.0484 50.7223 13.1722 50.4254 13.1722 50.0417C13.1722 49.6334 13.0484 49.324 12.7884 49.1261C12.5284 48.9281 12.1447 48.8291 11.6247 48.8291H10.2629V51.2544ZM10.2629 51.7123V54.3356H11.8971C12.3675 54.3356 12.7265 54.2119 12.9989 53.9768C13.2712 53.7293 13.4322 53.3704 13.4074 52.9868C13.4198 52.628 13.2836 52.2815 13.0113 52.0464C12.7513 51.8113 12.3923 51.6999 11.9342 51.6999H10.2629V51.7123Z" fill={fillColor}/>
      <path d="M18.8174 54.1872C18.4955 54.645 17.9879 54.8801 17.2947 54.8801C16.7871 54.8801 16.4033 54.7316 16.1309 54.4347C15.871 54.1377 15.7348 53.7046 15.7224 53.123V50.017H16.2424V53.0611C16.2424 53.9644 16.6138 54.4223 17.3442 54.4223C18.1117 54.4223 18.5945 54.1006 18.7926 53.4695V50.017H19.325V54.7935H18.8174V54.1872Z" fill={fillColor}/>
      <path d="M24.1903 53.5808C24.1903 53.3333 24.0789 53.0982 23.8808 52.9497C23.5961 52.7641 23.2742 52.628 22.9276 52.5785C22.5933 52.5166 22.259 52.4176 21.9372 52.2691C21.7267 52.1701 21.5534 52.034 21.4172 51.8484C21.3058 51.6628 21.2439 51.4524 21.2439 51.2421C21.2439 50.8708 21.4172 50.5244 21.7143 50.314C22.061 50.0541 22.4943 49.9304 22.9276 49.9428C23.4599 49.9428 23.8932 50.0789 24.2151 50.3387C24.5246 50.5862 24.7103 50.9698 24.6979 51.3658H24.1656C24.1656 51.0936 24.0418 50.8337 23.8189 50.6729C23.299 50.314 22.6304 50.3016 22.0981 50.6234C21.9 50.7595 21.7762 50.9822 21.7886 51.2297C21.7762 51.4524 21.8753 51.6504 22.0486 51.7865C22.2219 51.9227 22.5438 52.034 23.0018 52.1454C23.3609 52.2196 23.7075 52.331 24.0418 52.4919C24.2522 52.5909 24.4255 52.7517 24.5617 52.9374C24.8712 53.4694 24.735 54.1624 24.2398 54.5336C23.918 54.7688 23.4847 54.8925 22.9647 54.8925C22.4076 54.8925 21.9619 54.7564 21.6153 54.4841C21.281 54.249 21.0953 53.8654 21.0953 53.4571H21.6277C21.6401 53.7417 21.7762 54.0139 22.0238 54.1748C22.3086 54.3604 22.6428 54.447 22.9771 54.4346C23.2866 54.447 23.6085 54.3604 23.8684 54.1872C24.0541 54.0634 24.1903 53.8283 24.1903 53.5808Z" fill={fillColor}/>
      <path d="M26.4929 48.6435C26.4929 48.5445 26.5301 48.4579 26.592 48.396C26.6662 48.3217 26.7529 48.2846 26.8519 48.297C26.951 48.297 27.05 48.3341 27.1243 48.396C27.1862 48.4579 27.2233 48.5569 27.2233 48.6435C27.2233 48.7425 27.1862 48.8291 27.1243 48.891C27.05 48.9652 26.951 49.0023 26.8519 48.99C26.6662 49.0023 26.5053 48.8662 26.4929 48.6682C26.4929 48.6682 26.4929 48.6558 26.4929 48.6435ZM27.1119 54.7935H26.5796V50.017H27.1119V54.7935Z" fill={fillColor}/>
      <path d="M29.6622 50.017L29.6746 50.8337C29.8355 50.5615 30.0707 50.3264 30.3431 50.1655C30.6154 50.0046 30.9249 49.9304 31.2344 49.9304C31.742 49.9304 32.1258 50.0789 32.3734 50.3635C32.621 50.6481 32.7448 51.0812 32.7572 51.6628V54.7935H32.2372V51.6628C32.2372 51.2421 32.1382 50.9203 31.9649 50.71C31.7915 50.4996 31.5068 50.4006 31.1106 50.4006C30.7888 50.4006 30.4916 50.4996 30.244 50.71C29.9841 50.9327 29.786 51.2173 29.6869 51.5391V54.8059H29.167V50.0294H29.6622V50.017Z" fill={fillColor}/>
      <path d="M36.5826 54.8801C36.1988 54.8801 35.815 54.7811 35.4808 54.5832C35.1465 54.3852 34.8865 54.0882 34.7132 53.7417C34.5275 53.3705 34.4285 52.9621 34.4409 52.5414V52.3558C34.4285 51.9227 34.5275 51.5019 34.7132 51.1183C34.8741 50.7719 35.1341 50.4625 35.456 50.2521C35.7655 50.0418 36.1245 49.9304 36.4835 49.9428C37.0035 49.918 37.5234 50.1408 37.8577 50.5367C38.192 50.9327 38.3653 51.4772 38.3653 52.1578V52.4548H34.9608V52.5538C34.9484 53.0487 35.1094 53.5313 35.4312 53.9026C35.716 54.249 36.1493 54.447 36.6073 54.447C36.8673 54.447 37.1149 54.3975 37.3501 54.2985C37.5853 54.1872 37.7834 54.0139 37.9444 53.8036L38.2786 54.0511C37.8825 54.5955 37.3254 54.8801 36.5826 54.8801ZM36.4835 50.3759C36.0998 50.3635 35.7407 50.5244 35.4808 50.809C35.196 51.1307 35.0227 51.5514 34.9856 51.9845H37.8453V51.9227C37.8453 51.5143 37.7091 51.1183 37.4492 50.7966C37.214 50.5244 36.8673 50.3635 36.4835 50.3759Z" fill={fillColor}/>
      <path d="M42.8839 53.5808C42.8839 53.3333 42.7725 53.0982 42.5744 52.9497C42.2897 52.7641 41.9678 52.628 41.6212 52.5785C41.2869 52.5166 40.9527 52.4176 40.6308 52.2691C40.4203 52.1701 40.247 52.034 40.1108 51.8484C39.9994 51.6628 39.9375 51.4524 39.9375 51.2421C39.9375 50.8708 40.1108 50.5244 40.4079 50.314C40.7546 50.0541 41.1879 49.9304 41.6212 49.9428C42.1535 49.9428 42.5868 50.0789 42.9087 50.3387C43.2182 50.5862 43.4039 50.9698 43.3915 51.3658H42.8592C42.8592 51.0936 42.7354 50.8337 42.5125 50.6729C41.9926 50.314 41.324 50.3016 40.7917 50.6234C40.5936 50.7595 40.4698 50.9822 40.4822 51.2297C40.4698 51.4524 40.5689 51.6504 40.7422 51.7865C40.9155 51.9227 41.2374 52.034 41.6954 52.1454C42.0545 52.2196 42.4011 52.331 42.7354 52.4919C42.9458 52.5909 43.1191 52.7517 43.2553 52.9374C43.5648 53.4694 43.4286 54.1624 42.9334 54.5336C42.6116 54.7688 42.1783 54.8925 41.6583 54.8925C41.1012 54.8925 40.6555 54.7564 40.3089 54.4841C39.9746 54.249 39.7889 53.8654 39.7889 53.4571H40.3213C40.3337 53.7417 40.4698 54.0139 40.7174 54.1748C41.0022 54.3604 41.3364 54.447 41.6707 54.4346C41.9802 54.447 42.3021 54.3604 42.562 54.1872C42.7477 54.0634 42.8715 53.8283 42.8839 53.5808Z" fill={fillColor}/>
      <path d="M48.0712 53.5808C48.0712 53.3333 47.9598 53.0982 47.7617 52.9497C47.4769 52.7641 47.1551 52.628 46.8084 52.5785C46.4742 52.5166 46.1399 52.4176 45.818 52.2691C45.6076 52.1701 45.4343 52.034 45.2981 51.8484C45.1867 51.6628 45.1248 51.4524 45.1248 51.2421C45.1248 50.8708 45.2981 50.5244 45.5952 50.314C45.9418 50.0541 46.3751 49.9304 46.8084 49.9428C47.3408 49.9428 47.7741 50.0789 48.0959 50.3387C48.4054 50.5862 48.5911 50.9698 48.5787 51.3658H48.0464C48.0464 51.0936 47.9226 50.8337 47.6998 50.6729C47.1798 50.314 46.5113 50.3016 45.979 50.6234C45.7809 50.7595 45.6571 50.9822 45.6695 51.2297C45.6571 51.4524 45.7561 51.6504 45.9294 51.7865C46.1028 51.9227 46.4246 52.034 46.8827 52.1454C47.2417 52.2196 47.5884 52.331 47.9226 52.4919C48.1331 52.5909 48.3064 52.7517 48.4426 52.9374C48.7521 53.4694 48.6159 54.1624 48.1207 54.5336C47.7988 54.7688 47.3655 54.8925 46.8456 54.8925C46.2885 54.8925 45.8428 54.7564 45.4962 54.4841C45.1619 54.249 44.9762 53.8654 44.9762 53.4571H45.5085C45.5209 53.7417 45.6571 54.0139 45.9047 54.1748C46.1894 54.3604 46.5237 54.447 46.8579 54.4346C47.1674 54.447 47.4893 54.3604 47.7493 54.1872C47.935 54.0634 48.0712 53.8283 48.0712 53.5808Z" fill={fillColor}/>
      <path d="M53.3326 54.7935V48.3712H55.1277C55.6229 48.3589 56.1181 48.4826 56.539 48.7301C56.96 48.9652 57.2942 49.3241 57.5171 49.7572C57.7523 50.2274 57.8761 50.7471 57.8637 51.2792V51.8732C57.8761 52.4053 57.7523 52.925 57.5171 53.3952C57.2942 53.8283 56.96 54.1872 56.539 54.4223C56.0934 54.6698 55.5982 54.7935 55.0906 54.7811L53.3326 54.7935ZM53.8774 48.8291V54.3357H55.0906C55.7715 54.3357 56.3038 54.1129 56.7247 53.6674C57.1333 53.222 57.3437 52.6156 57.3437 51.8484V51.2792C57.3437 50.5367 57.1457 49.9428 56.7371 49.5097C56.3286 49.0642 55.7467 48.8167 55.1401 48.8415L53.8774 48.8291Z" fill={fillColor}/>
      <path d="M61.949 50.4749C61.8252 50.4501 61.7138 50.4377 61.59 50.4377C61.2928 50.4254 60.9957 50.5244 60.7481 50.71C60.5005 50.9203 60.3396 51.1926 60.2653 51.5143V54.7935H59.7454V50.017H60.2653L60.2777 50.7718C60.5624 50.2026 61.0081 49.9304 61.6271 49.9304C61.7509 49.9304 61.8623 49.9428 61.9737 49.9923L61.949 50.4749Z" fill={fillColor}/>
      <path d="M63.3604 48.6435C63.3604 48.5445 63.3975 48.4579 63.4594 48.396C63.5337 48.3217 63.6203 48.2846 63.7194 48.297C63.8184 48.297 63.9174 48.3341 63.9917 48.396C64.0536 48.4579 64.0908 48.5569 64.0908 48.6435C64.0908 48.7425 64.0536 48.8291 63.9917 48.891C63.9174 48.9652 63.8184 49.0023 63.7194 48.99C63.5337 49.0023 63.3727 48.8662 63.3604 48.6682C63.3604 48.6682 63.3604 48.6558 63.3604 48.6435ZM63.9917 54.7935H63.4594V50.017H63.9917V54.7935Z" fill={fillColor}/>
      <path d="M67.52 54.0634L68.9684 50.017H69.5132L67.7305 54.7935H67.2972L65.5021 50.017H66.0468L67.52 54.0634Z" fill={fillColor}/>
      <path d="M72.7814 54.8801C72.3977 54.8801 72.0139 54.7811 71.6796 54.5832C71.3454 54.3852 71.0854 54.0882 70.9121 53.7417C70.7264 53.3705 70.6273 52.9621 70.6397 52.5414V52.3558C70.6273 51.9227 70.7264 51.5019 70.9121 51.1183C71.073 50.7719 71.333 50.4625 71.6549 50.2521C71.9644 50.0418 72.3234 49.9304 72.6824 49.9428C73.2023 49.918 73.7223 50.1408 74.0566 50.5367C74.3908 50.9327 74.5641 51.4772 74.5641 52.1578V52.4548H71.1597V52.5538C71.1473 53.0487 71.3082 53.5313 71.6301 53.9026C71.9148 54.249 72.3481 54.447 72.8062 54.447C73.0662 54.447 73.3138 54.3975 73.549 54.2985C73.7842 54.1872 73.9823 54.0139 74.1432 53.8036L74.4775 54.0511C74.0937 54.5955 73.5242 54.8801 72.7814 54.8801ZM72.6948 50.3759C72.311 50.3635 71.952 50.5244 71.692 50.809C71.4073 51.1307 71.2339 51.5514 71.1968 51.9845H74.0566V51.9227C74.0566 51.5143 73.9204 51.1183 73.6604 50.7966C73.4252 50.5244 73.0662 50.3635 72.6948 50.3759Z" fill={fillColor}/>
      <path d="M76.7182 50.017L76.7306 50.8337C76.8915 50.5615 77.1268 50.3264 77.3991 50.1655C77.6715 50.0046 77.981 49.9304 78.2905 49.9304C78.798 49.9304 79.1818 50.0789 79.4294 50.3635C79.677 50.6481 79.8008 51.0812 79.8132 51.6628V54.7935H79.2932V51.6628C79.2932 51.2421 79.1942 50.9203 79.0209 50.71C78.8476 50.4996 78.5628 50.4006 78.1667 50.4006C77.8448 50.4006 77.5477 50.4996 77.3001 50.71C77.0401 50.9327 76.842 51.2173 76.743 51.5391V54.8059H76.223V50.0294H76.7182V50.017Z" fill={fillColor}/>
      <path d="M88.9125 48.8291H86.7089V54.7935H86.1641V48.8291H83.9729V48.3712H88.9125V48.8291Z" fill={fillColor}/>
      <path d="M91.8588 54.8801C91.475 54.8801 91.0913 54.7811 90.757 54.5832C90.4228 54.3852 90.1628 54.0882 89.9895 53.7417C89.8038 53.3705 89.7047 52.9621 89.7171 52.5414V52.3558C89.7047 51.9227 89.8038 51.5019 89.9895 51.1183C90.1504 50.7719 90.4104 50.4625 90.7323 50.2521C91.0418 50.0418 91.4008 49.9304 91.7598 49.9428C92.2797 49.918 92.7997 50.1408 93.134 50.5367C93.4682 50.9327 93.6415 51.4772 93.6415 52.1578V52.4548H90.2371V52.5538C90.2247 53.0487 90.3856 53.5313 90.7075 53.9026C90.9922 54.249 91.4255 54.447 91.8836 54.447C92.1436 54.447 92.3912 54.3975 92.6264 54.2985C92.8616 54.1872 93.0597 54.0139 93.2206 53.8036L93.5549 54.0511C93.1587 54.5955 92.5892 54.8801 91.8588 54.8801ZM91.7598 50.3759C91.376 50.3635 91.017 50.5244 90.757 50.809C90.4723 51.1307 90.299 51.5514 90.2618 51.9845H93.1216V51.9227C93.1216 51.5143 92.9854 51.1183 92.7254 50.7966C92.4902 50.5244 92.1312 50.3635 91.7598 50.3759Z" fill={fillColor}/>
      <path d="M97.0584 54.4347C97.3926 54.447 97.7269 54.3357 97.9869 54.1253C98.2345 53.9273 98.383 53.6303 98.3954 53.321H98.903C98.8906 53.6056 98.7916 53.8778 98.6306 54.1129C98.4573 54.3604 98.2345 54.546 97.9621 54.6698C97.6898 54.8059 97.3803 54.8801 97.0708 54.8678C96.4394 54.8678 95.9442 54.645 95.5852 54.2119C95.2138 53.7788 95.0405 53.1972 95.0405 52.4548V52.2939C95.0281 51.8608 95.1147 51.4401 95.2881 51.0441C95.4366 50.6976 95.6842 50.413 95.9937 50.2026C96.3156 50.0047 96.687 49.8933 97.0708 49.9057C97.5412 49.8933 98.0116 50.0542 98.3707 50.3759C98.7173 50.6976 98.9154 51.1431 98.9154 51.6009H98.4078C98.3954 50.8956 97.8136 50.3388 97.1079 50.3511C97.0955 50.3511 97.0832 50.3511 97.0832 50.3511C96.6499 50.3264 96.2413 50.5244 95.969 50.8709C95.709 51.2173 95.5728 51.6999 95.5728 52.3187V52.4671C95.5728 53.0735 95.709 53.5561 95.969 53.8902C96.2042 54.2614 96.6251 54.4594 97.0584 54.4347Z" fill={fillColor}/>
      <path d="M101.082 50.8214C101.243 50.5491 101.478 50.3264 101.75 50.1655C102.023 50.0047 102.32 49.9304 102.642 49.9304C103.149 49.9304 103.533 50.0789 103.781 50.3635C104.028 50.6481 104.152 51.0812 104.164 51.6628V54.7935H103.644V51.6628C103.644 51.2421 103.545 50.9204 103.372 50.71C103.199 50.4996 102.914 50.4006 102.518 50.4006C102.196 50.4006 101.899 50.4996 101.651 50.71C101.391 50.9327 101.193 51.2173 101.094 51.5391V54.8059H100.574V48.0124H101.094V50.8214H101.082Z" fill={fillColor}/>
      <path d="M106.64 50.017L106.653 50.8337C106.814 50.5615 107.049 50.3264 107.321 50.1655C107.594 50.0046 107.903 49.9304 108.213 49.9304C108.72 49.9304 109.104 50.0789 109.352 50.3635C109.599 50.6481 109.723 51.0812 109.735 51.6628V54.7935H109.215V51.6628C109.215 51.2421 109.116 50.9203 108.943 50.71C108.77 50.4996 108.485 50.4006 108.089 50.4006C107.767 50.4006 107.47 50.4996 107.222 50.71C106.962 50.9327 106.764 51.2173 106.665 51.5391V54.8059H106.145V50.0294H106.64V50.017Z" fill={fillColor}/>
      <path d="M111.419 52.331C111.407 51.8979 111.506 51.4772 111.691 51.0936C112.026 50.3759 112.756 49.918 113.548 49.9304C114.143 49.9057 114.7 50.1531 115.096 50.5986C115.492 51.0441 115.69 51.6381 115.69 52.3681V52.4795C115.702 52.9126 115.603 53.3333 115.418 53.7293C115.257 54.0758 114.997 54.3728 114.663 54.5832C114.328 54.7811 113.945 54.8925 113.561 54.8801C112.966 54.9049 112.409 54.6574 112.013 54.2119C111.617 53.7664 111.419 53.1725 111.419 52.4424V52.331ZM111.951 52.4795C111.927 52.9869 112.088 53.4818 112.397 53.8778C112.917 54.5213 113.87 54.6079 114.502 54.0882C114.576 54.0263 114.65 53.9521 114.712 53.8778C115.009 53.5066 115.158 53.024 115.158 52.43V52.331C115.158 51.9845 115.096 51.6504 114.96 51.3287C114.836 51.0441 114.638 50.7966 114.39 50.6234C114.143 50.4625 113.845 50.3635 113.548 50.3759C113.103 50.3635 112.669 50.5739 112.397 50.9327C112.1 51.304 111.951 51.7866 111.951 52.3805V52.4795Z" fill={fillColor}/>
      <path d="M117.98 54.7935H117.448V48.0124H117.98V54.7935Z" fill={fillColor}/>
      <path d="M119.738 52.331C119.726 51.8979 119.825 51.4772 120.011 51.0936C120.345 50.3759 121.075 49.918 121.868 49.9304C122.462 49.9057 123.019 50.1531 123.415 50.5986C123.811 51.0441 124.009 51.6381 124.009 52.3681V52.4795C124.022 52.9126 123.923 53.3333 123.737 53.7293C123.576 54.0758 123.316 54.3728 122.982 54.5832C122.648 54.7811 122.264 54.8925 121.88 54.8801C121.286 54.9049 120.729 54.6574 120.333 54.2119C119.936 53.7664 119.738 53.1725 119.738 52.4424V52.331ZM120.258 52.4795C120.234 52.9869 120.394 53.4818 120.704 53.8778C121.224 54.5213 122.177 54.6079 122.809 54.0882C122.883 54.0263 122.957 53.9521 123.019 53.8778C123.316 53.5066 123.465 53.024 123.465 52.43V52.331C123.465 51.9845 123.403 51.6504 123.267 51.3287C123.143 51.0441 122.945 50.7966 122.697 50.6234C122.45 50.4625 122.152 50.3635 121.855 50.3759C121.41 50.3635 120.976 50.5739 120.704 50.9327C120.407 51.304 120.258 51.7866 120.258 52.3805V52.4795Z" fill={fillColor}/>
      <path d="M125.483 52.3558C125.483 51.6133 125.644 51.0193 125.978 50.5862C126.287 50.1531 126.795 49.918 127.327 49.9304C127.921 49.9056 128.491 50.2026 128.813 50.71L128.838 50.017H129.333V54.6945C129.333 55.3132 129.159 55.7958 128.813 56.1547C128.466 56.5135 128.008 56.6868 127.414 56.6991C127.08 56.6991 126.758 56.6249 126.461 56.4764C126.176 56.3403 125.928 56.1423 125.743 55.8824L126.04 55.5607C126.411 56.0186 126.857 56.2537 127.377 56.2537C127.822 56.2537 128.157 56.1176 128.404 55.8577C128.652 55.5978 128.776 55.239 128.788 54.7688V54.1748C128.454 54.645 127.959 54.8801 127.315 54.8801C126.782 54.8925 126.287 54.645 125.978 54.2243C125.644 53.7788 125.47 53.1848 125.47 52.4424V52.3558H125.483ZM126.027 52.4548C126.027 53.0611 126.151 53.5437 126.399 53.8902C126.622 54.2367 127.018 54.4346 127.426 54.4223C128.082 54.4223 128.54 54.1253 128.813 53.5437V51.3287C128.714 51.0441 128.528 50.809 128.28 50.6234C128.033 50.4625 127.736 50.3759 127.439 50.3882C127.03 50.3759 126.634 50.5739 126.399 50.908C126.139 51.2668 126.027 51.7742 126.027 52.4548Z" fill={fillColor}/>
      <path d="M132.836 54.0387L134.235 50.0294H134.805L132.762 55.6102L132.651 55.8577C132.391 56.4393 131.982 56.7239 131.437 56.7239C131.301 56.7239 131.165 56.6991 131.029 56.662V56.2289L131.301 56.2537C131.524 56.266 131.747 56.1918 131.933 56.0557C132.131 55.8824 132.267 55.6473 132.341 55.3998L132.576 54.7564L130.781 50.0294H131.363L132.836 54.0387Z" fill={fillColor}/>
      <path d="M142.715 51.7123H139.707V54.3356H143.161V54.7935H139.162V48.3712H143.136V48.8291H139.707V51.2544H142.715V51.7123Z" fill={fillColor}/>
      <path d="M145.303 50.017L145.315 50.8337C145.476 50.5615 145.711 50.3264 145.984 50.1655C146.256 50.0046 146.566 49.9304 146.875 49.9304C147.383 49.9304 147.766 50.0789 148.014 50.3635C148.262 50.6481 148.385 51.0812 148.398 51.6628V54.7935H147.878V51.6628C147.878 51.2421 147.779 50.9203 147.605 50.71C147.432 50.4996 147.147 50.4006 146.751 50.4006C146.429 50.4006 146.132 50.4996 145.885 50.71C145.625 50.9327 145.427 51.2173 145.328 51.5391V54.8059H144.808V50.0294H145.303V50.017Z" fill={fillColor}/>
      <path d="M153.387 54.7935C153.325 54.5831 153.288 54.348 153.288 54.1253C153.102 54.3728 152.855 54.5584 152.582 54.6821C152.297 54.8183 151.988 54.8801 151.666 54.8801C151.258 54.8925 150.861 54.7564 150.552 54.4965C150.267 54.249 150.119 53.9026 150.131 53.5313C150.119 53.0859 150.341 52.6651 150.713 52.43C151.097 52.1578 151.641 52.0217 152.335 52.0217H153.288V51.4772C153.3 51.1802 153.189 50.8832 152.978 50.6729C152.768 50.4749 152.458 50.3759 152.062 50.3759C151.74 50.3635 151.418 50.4625 151.146 50.6605C150.923 50.809 150.787 51.0688 150.787 51.3411H150.255C150.255 50.9575 150.428 50.6357 150.787 50.3511C151.159 50.0665 151.617 49.918 152.087 49.9304C152.619 49.9304 153.04 50.0665 153.35 50.3264C153.659 50.611 153.833 51.0193 153.82 51.4401V53.7046C153.82 54.1624 153.87 54.5089 153.969 54.744V54.7935H153.387ZM151.728 54.4099C152.062 54.4099 152.384 54.3233 152.669 54.15C152.941 53.9892 153.152 53.7541 153.275 53.4695V52.4176H152.335C151.802 52.4176 151.394 52.5166 151.097 52.7023C150.812 52.8631 150.651 53.1477 150.651 53.4695C150.651 53.7293 150.75 53.9644 150.948 54.1377C151.171 54.3357 151.443 54.4223 151.728 54.4099Z" fill={fillColor}/>
      <path d="M159.676 52.4548C159.676 53.1972 159.515 53.7912 159.181 54.2243C158.871 54.645 158.376 54.8925 157.856 54.8801C157.188 54.8801 156.692 54.6327 156.346 54.1377L156.321 54.7935H155.826V48.0124H156.346V50.71C156.68 50.1903 157.175 49.9304 157.831 49.9304C158.351 49.9057 158.859 50.1531 159.168 50.5739C159.49 51.007 159.664 51.6009 159.664 52.3682V52.4548H159.676ZM159.144 52.3558C159.144 51.7247 159.02 51.2421 158.772 50.8956C158.537 50.5615 158.153 50.3635 157.745 50.3759C157.435 50.3635 157.138 50.4501 156.89 50.6234C156.643 50.809 156.457 51.0565 156.358 51.3535V53.5066C156.618 54.1006 157.089 54.4099 157.757 54.4099C158.166 54.4223 158.549 54.2243 158.785 53.8902C159.02 53.5561 159.144 53.0487 159.144 52.3558Z" fill={fillColor}/>
      <path d="M162.041 54.7935H161.508V48.0124H162.041V54.7935Z" fill={fillColor}/>
      <path d="M165.94 54.8801C165.556 54.8801 165.173 54.7811 164.838 54.5832C164.504 54.3852 164.244 54.0882 164.071 53.7417C163.885 53.3705 163.786 52.9621 163.798 52.5414V52.3558C163.786 51.9227 163.885 51.5019 164.071 51.1183C164.232 50.7719 164.492 50.4625 164.814 50.2521C165.123 50.0418 165.482 49.9304 165.841 49.9428C166.361 49.918 166.881 50.1408 167.215 50.5367C167.55 50.9327 167.723 51.4772 167.723 52.1578V52.4548H164.318V52.5538C164.306 53.0487 164.467 53.5313 164.789 53.9026C165.074 54.249 165.507 54.447 165.965 54.447C166.225 54.447 166.472 54.3975 166.708 54.2985C166.943 54.1872 167.141 54.0139 167.302 53.8036L167.636 54.0511C167.24 54.5955 166.683 54.8801 165.94 54.8801ZM165.841 50.3759C165.457 50.3635 165.098 50.5244 164.838 50.809C164.554 51.1307 164.38 51.5514 164.343 51.9845H167.203V51.9227C167.203 51.5143 167.067 51.1183 166.807 50.7966C166.572 50.5244 166.212 50.3635 165.841 50.3759Z" fill={fillColor}/>
      <path d="M169.852 50.017L169.865 50.7966C170.026 50.5244 170.248 50.3016 170.521 50.1531C170.793 50.0046 171.09 49.9304 171.4 49.9428C172.155 49.9428 172.625 50.2521 172.836 50.8708C172.997 50.5862 173.232 50.3511 173.517 50.1903C173.801 50.0294 174.136 49.9428 174.458 49.9551C175.485 49.9551 176.005 50.512 176.03 51.6257V54.8059H175.497V51.6628C175.497 51.2421 175.398 50.9203 175.225 50.71C175.039 50.4996 174.755 50.4006 174.346 50.4006C173.987 50.4006 173.653 50.5244 173.38 50.7718C173.121 50.9946 172.972 51.3039 172.947 51.6504V54.8059H172.415V51.6257C172.415 51.2173 172.316 50.908 172.13 50.71C171.944 50.512 171.647 50.413 171.251 50.413C170.942 50.4006 170.645 50.512 170.397 50.6976C170.137 50.9203 169.951 51.2173 169.865 51.5391V54.8059H169.332V50.0294H169.852V50.017Z" fill={fillColor}/>
      <path d="M179.843 54.8801C179.459 54.8801 179.075 54.7811 178.741 54.5832C178.407 54.3852 178.147 54.0882 177.973 53.7417C177.788 53.3705 177.689 52.9621 177.701 52.5414V52.3558C177.689 51.9227 177.788 51.5019 177.973 51.1183C178.134 50.7719 178.394 50.4625 178.716 50.2521C179.026 50.0418 179.385 49.9304 179.744 49.9428C180.264 49.918 180.784 50.1408 181.118 50.5367C181.452 50.9327 181.625 51.4772 181.625 52.1578V52.4548H178.221V52.5538C178.209 53.0487 178.37 53.5313 178.691 53.9026C178.976 54.249 179.409 54.447 179.867 54.447C180.127 54.447 180.375 54.3975 180.61 54.2985C180.845 54.1872 181.044 54.0139 181.204 53.8036L181.539 54.0511C181.143 54.5955 180.585 54.8801 179.843 54.8801ZM179.744 50.3759C179.36 50.3635 179.001 50.5244 178.741 50.809C178.456 51.1307 178.283 51.5514 178.246 51.9845H181.105V51.9227C181.105 51.5143 180.969 51.1183 180.709 50.7966C180.474 50.5244 180.127 50.3635 179.744 50.3759Z" fill={fillColor}/>
      <path d="M183.78 50.017L183.792 50.8337C183.953 50.5615 184.188 50.3264 184.461 50.1655C184.733 50.0046 185.042 49.9304 185.352 49.9304C185.859 49.9304 186.243 50.0789 186.491 50.3635C186.738 50.6481 186.862 51.0812 186.875 51.6628V54.7935H186.355V51.6628C186.355 51.2421 186.256 50.9203 186.082 50.71C185.909 50.4996 185.624 50.4006 185.228 50.4006C184.906 50.4006 184.609 50.4996 184.361 50.71C184.101 50.9327 183.903 51.2173 183.804 51.5391V54.8059H183.284V50.0294H183.78V50.017Z" fill={fillColor}/>
      <path d="M189.672 48.792V50.017H190.663V50.4501H189.672V53.6303C189.66 53.8407 189.71 54.0387 189.821 54.2243C189.945 54.3604 190.118 54.4347 190.304 54.4223C190.452 54.4223 190.588 54.3975 190.737 54.3728L190.762 54.8059C190.576 54.8678 190.39 54.8925 190.192 54.8801C189.833 54.8801 189.573 54.7811 189.4 54.5708C189.239 54.3604 189.152 54.0511 189.152 53.6427V50.4625H188.273V50.0294H189.152V48.8043L189.672 48.792Z" fill={fillColor}/>
    </svg>
  );
};
export default FullLogoIcon;