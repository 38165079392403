import React from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// scss
import "./fallbackLoader.scss";

function FallbackLoader({ containerClassName }) {
  const customTheme = useSelector((state) => state.themeConfiguration);
  const antIcon = <LoadingOutlined spin style={{color: customTheme.primary_color}}/>;
  return (
    <div className={`nb-lazy-load-fallback ${containerClassName ?? ""}`}>
      <Spin size="large" indicator={antIcon} />
    </div>
  );
}

export default FallbackLoader;
