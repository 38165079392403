import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Spin, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  SimpleCard,
  CardHeader,
} from "../../../../assets/gobal/components/Cards";
import { PeopleIcon } from "../../../../assets/icons/createPost";
import NeighboursList from "./neighboursList";
import { isArray } from "../../../../utils/functions";

function Wrapper({ children }) {
  return (
    <div className="display-flex justify-content-space-center align-items-center h-75">
      {children}
    </div>
  );
}

function RenderNeighboursList({ neighboursLoader, neighboursList }) {
  const customTheme = useSelector((state) => state.themeConfiguration);
  const { communities } = useSelector((state) => state.me);

  if (neighboursLoader) {
    const antIcon = (
      <LoadingOutlined spin style={{ color: customTheme.primary_color }} />
    );
    return (
      <Wrapper>
        <Spin indicator={antIcon} />
      </Wrapper>
    );
  } else if (communities?.length > 0) {
    return neighboursList();
    // <NeighboursList
    //   selectedNeighbour={selectedNearbyNeighbour}
    //   selectedNearbyNeighbour={selectedNearbyNeighbour}
    //   updatedSelectedNeighbour={updatedSelectedNeighbour}
    //   updatedSelectedNearbyNeighbour={updatedSelectedNearbyNeighbour}
    //   panelActive={panelActive}
    //   setPanelActive={setPanelActive}
    // />
  } else {
    return (
      <Wrapper>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Community Data Not Found"
        />
      </Wrapper>
    );
  }
}

export default function ChooseNeighbours(props) {
  const {
    setShowChooseNeighbours,
    handleUpdateSelectedNeighbours,
    selectedNeighbours,
    editMode,
  } = props;
  const me = useSelector((state) => state.me);
  const { categoryId } = useParams();

  const [_, updatedSelectedNeighbour] = useState("");

  const [selectedNearbyNeighbour, updatedSelectedNearbyNeighbour] = useState();
  const [panelActive, setPanelActive] = useState([]);

  const { neighboursLoader } = useSelector((state) => state.post);

  useEffect(() => {
    if (editMode) {
      updatedSelectedNearbyNeighbour([selectedNeighbours]);
    } else if (categoryId === "all" && !editMode) {
      updatedSelectedNearbyNeighbour([me?.communities[0]?.neighbourhood_id]);
    } else {
      updatedSelectedNearbyNeighbour([+categoryId]);
    }
  }, [categoryId]);

  useEffect(() => {
    if (isArray(selectedNeighbours)) {
      updatedSelectedNearbyNeighbour(selectedNeighbours);
      updatedSelectedNeighbour(0);
      setPanelActive(["1"]);
    } else {
      updatedSelectedNeighbour(selectedNeighbours);
    }
  }, [selectedNeighbours]);

  const neighboursList = useCallback(
    () => (
      <NeighboursList
        selectedNeighbour={selectedNearbyNeighbour}
        selectedNearbyNeighbour={selectedNearbyNeighbour}
        updatedSelectedNeighbour={updatedSelectedNeighbour}
        updatedSelectedNearbyNeighbour={updatedSelectedNearbyNeighbour}
        panelActive={panelActive}
        setPanelActive={setPanelActive}
      />
    ),
    [
      selectedNearbyNeighbour,
      selectedNearbyNeighbour,
      updatedSelectedNeighbour,
      updatedSelectedNearbyNeighbour,
      panelActive,
      setPanelActive,
    ]
  );

  return (
    <SimpleCard containerClass="journey-card-container choose-neighbours-container">
      <CardHeader
        title="Choose Communities"
        IconComp={PeopleIcon}
        onBackBtnClick={() => setShowChooseNeighbours(false)}
        btnDisabled={!selectedNearbyNeighbour?.length}
        onDoneClick={() =>
          handleUpdateSelectedNeighbours(selectedNearbyNeighbour)
        }
      />

      <RenderNeighboursList
        neighboursLoader={neighboursLoader}
        neighboursList={neighboursList}
      />
    </SimpleCard>
  );
}
