import React, { useState, useEffect } from "react";
import { Avatar, Checkbox, Input, Menu, Modal } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import NotificationsService from "../../../../api/services/notifications/notification";
import {
  getInitialChars,
  formattedFromNow,
  getEchoInstance,
  checkIfScrolledToBottom,
  getMentionUserFromTemplate,
} from "../../../../utils";
import NbDivider from "../nbDivider/nbDivider";
import NotificationLoading from "./notificationLoading";
import NbAvatar from "../nbAvatar/nbAvatar";
import { NbButton } from "../CustomButton";
import { AlertIcon } from "../../../icons";

function NotificationListing(props) {
  const { unreadCount, setUnReadCount } = props;
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const [notificationListing, setNotificationListing] = useState({});
  const [isNotification, setIsNotification] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [notificationBody, setNotificationBody] = useState(null);

  const [pagination, setPagination] = useState("");

  const getNotificationListing = async (page) => {
    try {
      setNotificationLoading(true);
      const res = await NotificationsService.getNotificationListing(page);
      if (res?.data?.data?.length <= 0) {
        setIsNotification(false);
        setNotificationLoading(false);
      }
      setUnReadCount(res.data.unread_count);
      setNotificationListing((prev) => ({ ...prev, ...res?.data }));
      setPagination(res.data);

      const finalResponse = {
        ...notificationListing,
        ...res.data,
        data: [
          ...(notificationListing?.data || []),
          ...(res?.data?.data || []),
        ],
      };
      setNotificationListing(finalResponse);
      setNotificationLoading(false);
    } catch (error) {
      setNotificationLoading(false);
    }
  };
  const handleUpdateMessagesList = () => {
    getNotificationListing();
  };

  const echo = getEchoInstance();
  useEffect(() => {
    getNotificationListing();
    echo
      .private(`notification-${me?.user_id}`)
      .listen(".new-notification", handleUpdateMessagesList);
  }, []);

  const getName = (user) => {
    return getInitialChars(`${user?.first_name}  ${user?.last_name}`);
  };

  const NotificationDetails = async (data) => {
    // eslint-disable-next-line camelcase
    const { notification_id, entity_type, entity_id } = data;
    const isRead = data.is_read;

    try {
      await NotificationsService.readNotification(notification_id, {
        action: "read",
      });

      const updatedNotificationListing = notificationListing.data.map((noc) => {
        // eslint-disable-next-line camelcase
        if (noc.notification_id === notification_id) {
          return { ...noc, is_read: true };
        }
        return noc;
      });
      setNotificationListing({
        ...notificationListing,
        data: updatedNotificationListing,
      });

      let currentCount;
      if (unreadCount > 0 && isRead === true) {
        currentCount = unreadCount;
      } else if (unreadCount > 0 && isRead === false) {
        currentCount = unreadCount - 1;
      } else {
        currentCount = 0;
      }

      setUnReadCount(currentCount);
      // eslint-disable-next-line camelcase
      if (entity_type === "post" || entity_type === "comment") {
        // eslint-disable-next-line camelcase
        history.push(`/post/view/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "user") {
        // eslint-disable-next-line camelcase
        history.push(`/profile/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "business") {
        // eslint-disable-next-line camelcase
        history.push(`/business/${entity_id}/details`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "organisation") {
        // eslint-disable-next-line camelcase
        history.push(`/organisation/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "message") {
        // eslint-disable-next-line camelcase
        history.push(`/message?id=${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "connectionrequest") {
        // eslint-disable-next-line camelcase
        history.push(`/connections/requests`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "acceptconnectionrequest") {
        // eslint-disable-next-line camelcase
        history.push(`/connections/my-connections`);
        // eslint-disable-next-line camelcase
      } else if (["userwarning", "default"].includes(entity_type)) {
        setNotificationTitle(data?.full_title);
        setNotificationBody(data?.full_body);
        setVisible(true);
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.resolve();
  };

  const handleScroll = (e) => {
    if (e.target) {
      if (checkIfScrolledToBottom(e.target) && pagination.next_page_url) {
        getNotificationListing((pagination?.current_page || 0) + 1);
      }
    }
  };
  const customTheme = useSelector((state) => state.themeConfiguration);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedNotifications([]);
    } else {
      const allNotificationIds = notificationListing.data.map(
        (el) => el.notification_id
      );
      setSelectedNotifications(allNotificationIds);
    }
    setSelectAll(!selectAll);
  };

  const handleDeleteSelected = () => {
    const updatedNotificationListing = notificationListing.data.filter(
      (el) => !selectedNotifications.includes(el.notification_id)
    );
    setNotificationListing({
      ...notificationListing,
      data: updatedNotificationListing,
    });
    setSelectedNotifications([]);
  };

  const handleCheckboxChange = (notificationId) => {
    if (selectedNotifications.includes(notificationId)) {
      setSelectedNotifications(
        selectedNotifications.filter((id) => id !== notificationId)
      );
    } else {
      setSelectedNotifications([...selectedNotifications, notificationId]);
    }
  };

  return (
    <>
      <Menu className="p2 h450">
        <div className="display-flex t-center">
          <p className="mb0 flex1"> Notification </p>
          {/* <CloseOutlined style={{ marginLeft: "8px", cursor: "pointer" }} /> */}
        </div>
        <NbDivider
          className="mb1"
          style={{ borderBottom: `1px solid lightgrey` }}
        />
        {/* <div className="display-flex t-center m1">
          <div className="flex1 t-right display-flex align-items-center">
            <Checkbox
              size="large"
              type="checkbox"
              className="t-right pr1"
              checked={selectAll}
              onChange={handleSelectAll}
            />{" "}
            Select All
          </div>
          <NbButton
            type="text"
            onClick={handleDeleteSelected}
            btnText="Delete"
          />
        </div> */}
        <div
          className="d-flex h-p99 flex1 o-y-auto nb-nice-scroll-style"
          onScroll={handleScroll}
          style={{ maxHeight: "440px" }}
        >
          {notificationListing?.data
            ?.filter(
              (obj, index, self) =>
                index ===
                self.findIndex((o) => o.notification_id === obj.notification_id)
            )
            .map((el) => {
              return (
                <div
                  key={el.notification_id}
                  style={
                    el?.is_read
                      ? { backgroundColor: "#fff" }
                      : { backgroundColor: "#f9f9f9" }
                  }
                  className="nb-item-notification p2 mx1 mt2 mb3 display-flex"
                >
                  {/* <Checkbox
                    checked={selectedNotifications.includes(el.notification_id)}
                    onChange={() => handleCheckboxChange(el.notification_id)}
                  /> */}
                  <Menu.Item
                    style={{ backgroundColor: "transparent", width: "100%" }}
                    onClick={() => {
                      NotificationDetails(el);
                    }}
                    key={el.notification_id}
                  >
                    <div
                      className="display-flex"
                      style={{ alignItems: "center" }}
                    >
                      <NbAvatar
                        profileImage={el?.user?.profile?.url}
                        firstName={el?.user?.first_name}
                        lastName={el?.user?.last_name}
                      />
                      <div style={{ flex: 1 }}>
                        <a>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{ flex: 1 }}
                              className={`${
                                el.is_read ? "fnw-400" : "fnw-600"
                              } px2 truncate mb0  nb-base-text`}
                            >
                              {el?.title}
                            </span>
                            <span
                              className="nb-faded-text fn7"
                              style={{ color: customTheme.primary_color }}
                            >
                              {formattedFromNow(el.created_at)}
                            </span>
                          </div>
                          <p
                            className="px2 truncate mb0 nb-faded-text"
                            style={{ color: customTheme.primary_color }}
                          >
                            {getMentionUserFromTemplate(el?.body)}
                          </p>
                        </a>
                      </div>
                    </div>
                  </Menu.Item>
                </div>
              );
            })}
          {!isNotification && (
            <div className="px38  ">
              <p className="fnw600 py10">No Notification</p>
            </div>
          )}

          {notificationLoading && (
            <NotificationLoading loading={notificationLoading} />
          )}
        </div>
      </Menu>
      <Modal
        title={
          <div className="icon">
            <AlertIcon />
          </div>
        }
        className="compose-alert"
        open={visible}
        closable={false}
        onCancel={() => setVisible(false)}
        destroyOnClose={() => setVisible(false)}
        footer={[
          <NbButton
            type="outlined"
            className="nb-button"
            key="back"
            onClick={() => setVisible(false)}
            btnText="Close"
          />,
        ]}
      >
        <h4>{notificationTitle}</h4>
        <p>{notificationBody}</p>
        <br />
        <p>
          If you have questions or need more information, please contact
          <a href="mailto:communitysupport@ccplatform.com">
            {" "}
            communitysupport@ccplatform.com
          </a>
          .
        </p>
      </Modal>
    </>
  );
}

export default NotificationListing;
