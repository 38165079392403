import { Button, notification } from "antd";
import { AlertIcon, Check } from "../assets/icons";

export const debounce = (...params) => {
  const [func, delay] = params;
  let inDebounce;
  return function x() {
    const context = this;
    const args = params;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const throttle = (...params) => {
  const [func, limit] = params;

  let inThrottle;
  return function x() {
    const args = params;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

export const isArray = (data) => Array.isArray(data);

export function notify(message, type) {
  const key = `open${Date.now()}`;
  const btn = (
    <Button size="large" onClick={() => notification.destroy(key)}>
      Close
    </Button>
  );
  switch (type) {
    case "success":
      notification.success({
        message,
        duration: 5,
        icon: <Check />,
        btn,
        key,
        closeIcon: true,
      });
      break;
    case "error":
      notification.error({
        message: message || "Something went wrong!",
        duration: 5,
        icon: <AlertIcon />,
        btn,
        key,
        closeIcon: true,
      });
      break;

    default:
      break;
  }
}

export const checkIfScrolledToBottom = (container) => {
  let subtractingResult =
    Math.round(container?.scrollHeight) - Math.round(container?.scrollTop);
  subtractingResult = Math.round(subtractingResult);

  return (
    subtractingResult - Math.round(container?.clientHeight) === 0 ||
    (subtractingResult - Math.round(container?.clientHeight) <= 1 &&
      subtractingResult - Math.round(container?.clientHeight) >= -1)
  );
};
