import React from "react";
// scss
import "./Info.scss";
import { Popover } from "antd";
import infoIcon from "../../../icons/info.svg";

function Info({ title, style, className, isPopover }) {
  return isPopover ? (
    <div style={style}>
      <Popover
        overlayStyle={{ width: "200px" }}
        placement="Top"
        content={<span>{title}</span>}
      >
        <img src={infoIcon} alt="" style={{ height: "10px" }} />
      </Popover>
    </div>
  ) : (
    <div className={`info-container ${className}`} style={style}>
      <img src={infoIcon} alt="" />
      <span>{title}</span>
    </div>
  );
}

export default Info;
