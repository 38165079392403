import React from "react";
import { Modal } from "antd";

// sccs
import "../../../../components/nbEditModal/nbEditModal.scss";
import "./editModal.scss";
import RenderMobileComponent from "../RenderMobileComponent/RenderMobileComponent";
import { MobileSheet } from "../MobileSheet/MobileSheet";

export default function EditModal({
  icon,
  title,
  visible,
  onCancel,
  closable,
  children,
  destroyOnClose,
  footer = false,
  className,
}) {
  if (!visible) return null;
  return (
    <RenderMobileComponent
      render={() => (
        <Modal
          title={
            <div className="nb-edit-modal-title">
              {icon && <span className="nb-edit-modal-icon">{icon}</span>}
              <h4>{title}</h4>
            </div>
          }
          open={visible}
          destroyOnClose={destroyOnClose}
          footer={footer}
          centered
          className={`nb-edit-modal ${className}`}
          onCancel={onCancel}
          closable={closable}
        >
          {children}
        </Modal>
      )}
      renderMobile={() => (
        <MobileSheet open={visible} setOpen={onCancel} headerTitle={title}>
          <div className="p5">
            {children}
            {footer && <div className="ant-modal-footer">{footer}</div>}
          </div>
        </MobileSheet>
      )}
    />
  );
}
/* istanbul ignore next */
EditModal.defaultProps = {
  editMode: true,
  maskClosable: false,
  title: undefined,
  closable: true,
  onUserCreated: () => null,
};
