import React, { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import { NbButton } from "../CustomButton";
import "./MobileMenu.scss";
import RenderMobileComponent from "../RenderMobileComponent/RenderMobileComponent";

export function MobileMenu({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mobile-menu-container">
      <RenderMobileComponent
        renderMobile={() => (
          <NbButton
            onClick={() => {
              setOpen(true);
            }}
            type="link"
            icon={() => children.props.children}
          />
        )}
        render={() => children}
      />
      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false);
        }}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 5,
          maxHeight * 0.2,
        ]}
        expandOnContentDrag
        onClick={() => {
          setOpen(false);
        }}
      >
        {children.props.overlay}
      </BottomSheet>
    </div>
  );
}
