import React from "react";
import { useSelector } from "react-redux";
import { Upload, notification } from "antd";

// components
import JourneyOptionBtn from "./journeyOptionBtn";
import { ImageIcon } from "../../../assets/icons/createPost";
import { uploadAccept } from "../../../utils/helpers";

// utils
import { notify, useMediaUploader } from "../../../utils";
import Info from "../../../assets/gobal/components/Info/Info";
import config from "../../../config";
import { AlertIcon } from "../../../assets/icons";

export default function JourneyImageUploader(props) {
  const { files, onUpload, onDelete } = props;

  const mediaUploader = useMediaUploader();

  const uploadDisabled = false;

  /* istanbul ignore next */
  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  /* istanbul ignore next */
  const deleteImg = async (data) => {
    let updateFilesList = [...files];
    const filesToDelete = updateFilesList.filter(
      (file) => file.uid === data.uid
    );
    if (filesToDelete.length) {
      try {
        await mediaUploader.deleteMedia(filesToDelete[0].uid);
        updateFilesList = updateFilesList.filter(
          (file) => file.uid !== data.uid
        );
        onDelete(updateFilesList);
      } catch (error) {
        notify(error?.message, "error");
      }
    }
  };

  /* istanbul ignore next */
  const handleChange = ({ fileList }) => {
    onUpload(fileList);
  };

  const uploadProps = {
    customRequest,
    multiple: true,
    maxCount: 10,
    className: "journey-upload-container",
    onChange: handleChange,
    showUploadList: false,
    accept: uploadAccept,
  };
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <>
      <div className="nb-upload-input mt7 mb4">
        {files?.length ? (
          <div className="p1 pb0">
            <Upload
              customRequest={customRequest}
              listType="picture-card"
              fileList={files}
              onRemove={deleteImg}
              showUploadList={{
                showPreviewIcon: false,
              }}
              disabled={uploadDisabled}
            />
          </div>
        ) : null}
        <Upload {...uploadProps} fileList={files} disabled={uploadDisabled}>
          <JourneyOptionBtn
            containerClassName="nb-attach-files"
            title="Attach Files"
            leftIcon={ImageIcon}
            leftIconClass="fill-primary"
            rightIconClass="nb-fill"
            showRightIcon={false}
            disabled={uploadDisabled}
            style={{ fill: customTheme.primary_color }}
          />
        </Upload>
      </div>
      <Info
        title={`Max upload size is ${config.uploadSize}`}
        style={{ borderColor: customTheme.primary_color }}
      />
    </>
  );
}
