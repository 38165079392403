import React, { useRef, useContext, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Dropdown, Menu, Badge } from "antd";

// components
import ReactionButtons from "../reactionButtons/reactionButtons";
import PostCommentForm from "../postCommentForm/postCommentForm";
import Portal from "../../../assets/gobal/components/portal/portal";

// icons
import { More } from "../../../assets/icons";
// scss
import "./comment.scss";

// formatters
import { formattedFromNow } from "../../../utils";

// context
import { PostCommentsContext } from "../postComments/context";

// icons
import { Comment as CommentIcon } from "../../../assets/icons/post";
import MetaTagsContent from "../metaTagsContent/metaTagsContent";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import NbAvatar from "../../../assets/gobal/components/nbAvatar/nbAvatar";
import { MobileMenu } from "../../../assets/gobal/components/MobileMenu/MobileMenu";

function Comment({
  index,
  style,
  className,
  children,
  comment: commentData,
  onReactComment,
  onUnReactComment,
  onReplyComment,
  onDeleteComment,
  parentId,
  isReply,
  me,
  commentsDisabled,
  onReportComment,
}) {
  const {
    body,
    user,
    created_at: createdAt,
    comment_id: commentId,
    isReplyFormLoading,
    apiResponse,
    is_liked: isLiked,
    likes_count: likesCount,
    comment_count: commentCount,
    media,
    unique_reacts: uniqueReact,
    meta_tags: metaTags,
  } = commentData || {};

  const SHOW_MORE_LIMIT = 300;
  const ref = useRef(null);
  const [showMore, setShowMore] = useState(body?.length < SHOW_MORE_LIMIT);
  const { activeCommetFormIndex, setActiveCommentForm } =
    useContext(PostCommentsContext);
  const customTheme = useSelector((state) => state.themeConfiguration);

  const isAuthor = me?.user_id === user?.user_id;
  const avatarName = `${user?.first_name
    ?.charAt(0)
    ?.toUpperCase()}${user?.last_name?.charAt(0)?.toUpperCase()}`;

  if (!commentData) {
    return null;
  }

  return (
    <div
      className={`post-comment display-flex ${className}`}
      style={style}
      ref={ref}
    >
      <div>
        {user?.profile?.url ? (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
          >
            <NbAvatar
              profileImage={user?.profile?.url}
              firstName={user?.first_name}
              lastName={user?.last_name}
              size={42}
            />
          </Badge>
        ) : (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
          >
            <NbAvatar
              profileImage={user?.profile?.url}
              firstName={user?.first_name}
              lastName={user?.last_name}
              size="large"
            />
          </Badge>
        )}
      </div>
      <div className="post-comment-right display-flex flex1 flex-column">
        <div>
          <div className="post-comment-right-comment-content display-flex px4 pt1 pb2 flex1 mb2">
            <div className="flex1">
              <div className="display-flex align-items-center justify-content-space-between">
                <p className="display-flex align-items-center fn9 fnw-600">
                  {`${user?.first_name} ${user?.last_name}`}
                </p>
                <div className="display-flex mt1 justify-content-flex-end">
                  <span
                    className="fn6 nb-faded-text"
                    style={{ color: customTheme.primary_color }}
                  >
                    {formattedFromNow(createdAt)}
                  </span>
                  <MobileMenu>
                    <Dropdown
                      overlay={
                        <Menu>
                          {isAuthor && (
                            <NbButton
                              onClick={() => onDeleteComment(commentId)}
                              type="button"
                              className=""
                              btnText="Delete"
                            />
                          )}
                          {!isAuthor && (
                            <NbButton
                              onClick={() =>
                                onReportComment(
                                  parentId || commentId,
                                  commentId,
                                  body
                                )
                              }
                              type="button"
                              className=""
                              btnText="Report"
                            />
                          )}
                        </Menu>
                      }
                      placement="bottomRight"
                      trigger="click"
                    >
                      <More className="ml3 c-pointer" height="12" />
                    </Dropdown>
                  </MobileMenu>
                </div>
              </div>
              <p className="o-wrap-any">
                {showMore || body === undefined
                  ? body
                  : `${body?.substring(0, SHOW_MORE_LIMIT)}...`}
                {!showMore && body?.length > SHOW_MORE_LIMIT && (
                  <NbButton
                    type="link"
                    onClick={() => {
                      setShowMore(true);
                    }}
                    btnText="show more"
                  />
                )}
                {showMore && body?.length > SHOW_MORE_LIMIT && (
                  <NbButton
                    type="link"
                    onClick={() => {
                      setShowMore(false);
                      window.scroll({
                        top: ref.current.offsetTop - 300,
                        behavior: "smooth",
                      });
                    }}
                    className="w-25"
                    btnText="show less"
                  />
                )}
              </p>
              {media && (
                <div className="comment-media mxw200 mt2">
                  <a href={media.url} target="_blank" rel="noreferrer">
                    <img className="w-p100" src={media.url} alt="" />
                  </a>
                </div>
              )}
              {metaTags && <MetaTagsContent metaTags={metaTags} />}
            </div>
          </div>
          <ReactionButtons
            showComment={!isReply && !commentsDisabled}
            onReact={(reactionKey) => onReactComment(reactionKey, commentId)}
            isLiked={isLiked}
            likesCount={likesCount}
            commentCount={commentCount}
            uniqueReacts={uniqueReact}
            onUnReact={(reactionKey) =>
              onUnReactComment(reactionKey, commentId)
            }
            commentButton={
              <div
                className="nb-faded-text fn8 ml4 c-pointer"
                style={{ color: customTheme.primary_color }}
              >
                <button
                  type="button"
                  onClick={() => setActiveCommentForm(index)}
                  className={` nb-proxy-button  ${
                    commentsDisabled ? "c-not-allowed" : "c-pointer"
                  }  `}
                  title={commentsDisabled ? "Replies has been Disabled" : ""}
                >
                  <CommentIcon className="post-reaction-button-icons mr1" />
                  <span className=""> Reply</span>
                </button>
              </div>
            }
          />
        </div>

        <div className="replies mt4">{children}</div>
        {activeCommetFormIndex === index && !isReply && !commentsDisabled && (
          <Portal mountNode={ref?.current?.children[1]}>
            <PostCommentForm
              placeholder="Add your reply"
              className="mb4"
              style={{ marginTop: ".25rem" }}
              showMessage={isReply}
              isLoading={isReplyFormLoading}
              apiResponse={apiResponse}
              onComment={(commentReplyData) => {
                onReplyComment(commentReplyData, parentId || commentId);
              }}
            />
          </Portal>
        )}
      </div>
    </div>
  );
}

export default Comment;

Comment.defaultProps = {
  style: {},
  className: "",
  children: [],
  isReply: false,
};

Comment.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  comment: PropTypes.instanceOf(PropTypes.object).isRequired,
  onReactComment: PropTypes.func.isRequired,
  onUnReactComment: PropTypes.func.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
  me: PropTypes.instanceOf(PropTypes.object).isRequired,
  commentsDisabled: PropTypes.bool.isRequired,
  onReportComment: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
};
