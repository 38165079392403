import React from "react";
import { Skeleton } from "antd";
import { skeletonFill } from "../../../../../utils";

export default function NotificationSkeleton() {
  return (
    <div className="neighbour-listing-card-body nb-nice-scroll-style d-flex mt4 ">
      {skeletonFill(2).map((s) => (
        <div
          key={2}
          style={{ width: "360px" }}
          className="event-listing-skeleton neighbours-single px4 my4 display-flex"
        >
          <Skeleton.Avatar shape="circle" loading size={40} />
          <Skeleton
            loading
            title
            active
            className="px4"
            paragraph={{
              rows: 0,
            }}
          />
        </div>
      ))}
    </div>
  );
}
