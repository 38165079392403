// icons
import HomeIcon from "../../../assets/icons/dynamicIcons/homeIcon";
import {
  NoticeboardRound,
  MessagesRound,
  NeighboursRound,
  Home,
  Message,
  Profile,
  All,
  Communities,
  User,
  ConnectionUser,
} from "../../../assets/icons/sider";

export const getMenuLists = () => {
  return {
    menuListForAllVerified: [
      {
        menuList: {
          title: "",
          list: [
            {
              key: "1",
              label: "All Activities",
              to: "/home/all",
              icon: All,
              mobileIcon: HomeIcon,
            },
            {
              key: "2",
              label: "Profile",
              to: "/profile",
              icon: Profile,
            },

            {
              key: "3",
              label: "Messages",
              to: "/message",
              icon: Message,
            },
            {
              key: "4",
              label: "Connections",
              to: "/connections",
              icon: ConnectionUser,
            },
            {
              key: "5",
              label: "Communities",
              to: "/communities",
              icon: User,
            },
          ].filter(Boolean),
        },
      },
    ],

    menuList1: [
      {
        key: "All Activities",
        label: "All Activities",
        to: "/home/all",
        icon: NoticeboardRound,
      },
      {
        key: "11",
        label: "Noticeboard",
        to: "/home/noticeboard/2",
        icon: NoticeboardRound,
      },
      {
        key: "2",
        label: "Messages",
        to: "/message",
        icon: MessagesRound,
      },
      {
        key: "3",
        label: "Community Members",
        to: "/neighbours",
        icon: NeighboursRound,
      },
    ].filter(Boolean),
  };
};
