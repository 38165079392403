import React from 'react';

function AlertEditIcon({ fillColorPrimary, fillColorSecondary }) {
  return (
    <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1935_84250)">
          <circle cx="55.2717" cy="54.772" r="47.7717" fill={fillColorSecondary} opacity="0.2" />
          <g mask="url(#mask0_1935_84250)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M57.7121 37.819C59.6347 35.8934 62.7532 35.8922 64.6774 37.8164L69.1419 42.2809C71.0496 44.1887 71.0695 47.2771 69.1864 49.2091L52.6437 66.1821C51.3796 67.479 49.6459 68.2103 47.8355 68.2103L42.9046 68.21C40.6126 68.2099 38.7822 66.2993 38.8786 64.0077L39.0903 58.9748C39.1611 57.2924 39.8603 55.6978 41.0497 54.5066L57.7121 37.819ZM62.7785 39.7181C61.9038 38.8435 60.4864 38.8441 59.6124 39.7193L56.6327 42.7036L64.3007 50.3715L67.2632 47.3319C68.1192 46.4537 68.1102 45.0499 67.243 44.1827L62.7785 39.7181ZM42.9501 56.407L54.7338 44.6053L62.4247 52.2962L50.7205 64.3048C49.9621 65.083 48.9219 65.5218 47.8357 65.5217L42.9047 65.5215C42.1407 65.5215 41.5306 64.8846 41.5627 64.1207L41.7744 59.0879C41.8169 58.0784 42.2364 57.1217 42.9501 56.407ZM70.2557 68.0789C70.9975 68.0789 71.5989 67.4771 71.5989 66.7347C71.5989 65.9922 70.9975 65.3904 70.2557 65.3904H59.2876C58.5458 65.3904 57.9444 65.9922 57.9444 66.7347C57.9444 67.4771 58.5458 68.0789 59.2876 68.0789H70.2557Z" fill={fillColorPrimary}/>
          </g>
      </g>
    </svg>
  );
};
export default AlertEditIcon;