import React from 'react';

function SavedIcon({ fillColor }) {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="Vector">
        <path id="Vector_2" d="M15.9737 10.0511C15.9737 12.5035 15.9239 14.9596 15.9936 17.412C16.0335 18.832 15.9072 19.4406 14.4614 18.5314C12.67 17.4048 10.7456 16.5281 8.90765 15.4812C8.25622 15.1117 7.73108 15.1117 7.08297 15.4812C5.14861 16.5825 3.17105 17.5895 1.23337 18.6799C0.422398 19.1327 -0.0129989 19.2776 0.000295673 17.9518C0.043503 12.6883 0.0268848 7.42115 0.0102666 2.15766C0.00694295 0.730401 0.711555 0.0421263 1.91471 0.0312588C5.96955 -0.0122112 10.0244 -0.00858868 14.0792 0.0312588C15.289 0.0421263 15.9903 0.730401 15.9804 2.15404C15.9604 4.7876 15.9737 7.42115 15.9737 10.0511Z" fill={fillColor}/>
      </g>
    </svg>
  );
};
export default SavedIcon;