import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "./MobileSheet.scss";

import { NbButton } from "../CustomButton";
import { CloseIcon } from "../../../icons";

function Header({ title, onDismiss }) {
  return (
    <div className="align-items-center display-flex justify-content-space-between p2">
      <NbButton type="link" icon={CloseIcon} onClick={onDismiss} />
      <span className="title">{title}</span>
      <div className="w-p10" />
    </div>
  );
}

export function MobileSheet({ children, open, setOpen, headerTitle, footer }) {
  const onDismiss = () => setOpen(false);
  return (
    <BottomSheet
      open={open}
      header={<Header title={headerTitle} onDismiss={onDismiss} />}
      onDismiss={onDismiss}
      snapPoints={({ maxHeight }) => [maxHeight]}
      // expandOnContentDrag
      initialFocusRef={false}
      footer={footer}
      className="mobile-sheet-container"
    >
      {children}
    </BottomSheet>
  );
}
