/* eslint-disable react/no-danger */
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Mentions from "../../../assets/gobal/components/Mentions/Mentions";
import "./postSubjectMessage.scss";

function PostSubjectMessage({ subject, message, className, style }) {
  const [isShowMore, setShowMore] = useState(false);
  const lessMessage = useRef(message?.substr(0, 300));
  const ref = useRef(null);
  const customTheme = useSelector((state) => state.themeConfiguration);

  return (
    <div ref={ref} className={`heading-para mb4 ${className}`} style={style}>
      {subject &&
        (typeof subject === "string" ? (
          <h4 className="mb2 fnw-500 o-wrap-any">{subject}</h4>
        ) : (
          subject
        ))}
      {message &&
        (typeof message === "string" && message.length > 500 ? (
          <p
            style={{ whiteSpace: "pre-line" }}
            className="nb-base-text o-wrap-any"
          >
            {/* <span
              dangerouslySetInnerHTML={{
                __html: `${isShowMore ? message : lessMessage.current}`,
              }}
            /> */}
            <div className="post-mentions">
              <Mentions
                value={`${isShowMore ? message : lessMessage.current}`}
                readOnly
              />
            </div>
            <span
              className="nb-primary-text c-pointer"
              style={{ color: customTheme.primary_color }}
              onClick={() => {
                setShowMore(!isShowMore);
                if (isShowMore) {
                  window.scroll({
                    top: ref.current.offsetTop - 300,
                    behavior: "smooth",
                  });
                }
              }}
              role="button"
              tabIndex="0"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setShowMore(!isShowMore);
                  if (isShowMore) {
                    window.scroll({
                      top: ref.current.offsetTop - 300,
                      behavior: "smooth",
                    });
                  }
                }
              }}
            >
              {isShowMore ? " show less" : "... show more"}
            </span>
          </p>
        ) : (
          <p className="o-wrap-any" style={{ whiteSpace: "pre-line" }}>
            {/* <span dangerouslySetInnerHTML={{ __html: message }} /> */}
            <div className="post-mentions">
              <Mentions value={message} readOnly />
            </div>
          </p>
        ))}
    </div>
  );
}

export default PostSubjectMessage;

PostSubjectMessage.defaultProps = {
  subject: undefined,
  message: undefined,
  style: {},
  className: "",
};

PostSubjectMessage.propTypes = {
  subject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
};
