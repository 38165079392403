import { Post, Get } from "../../requests";

const getCommunity = (pageNumber, userId) => {
  /* Select computation member page filter re-check */
  return Get(
    `/communities?page=${pageNumber}${userId ? `&user_id=${userId}` : ""}`
  );
};

const getCommunityDetails = (id, pageNumber, newsiestFilter) => {
  /* Select computation member page filter re-check */
  let orderBy = "";
  if (newsiestFilter === "Newest Activity") {
    // url request for Newest post
    orderBy = "&order_by=is_latest_activity";
  } else if (newsiestFilter === "Oldest Posts") {
    // url request for Newest post
    orderBy = "&order_by=is_oldest_activity";
  }
  return Get(`/community-details/${id}?page=${pageNumber}${orderBy}`);
};

const getCommunityRequests = (pageNumber) => {
  return Get(`/communities?filter_by=request&page=${pageNumber}`);
};

const getRecommendedCommunities = (pageNumber) => {
  return Get(`/recommended-communities?page=${pageNumber}`);
};

const joinCommunity = (params) => {
  return Post("/join-community", params);
};

const leaveCommunity = (params) => {
  return Post(`/leave-community`, params);
};

const acceptConnections = (params) => {
  return Post(`/accept-community-request`, params);
};

const communityApis = {
  getCommunity,
  getCommunityDetails,
  getCommunityRequests,
  getRecommendedCommunities,
  joinCommunity,
  leaveCommunity,
  acceptConnections,
};

export default communityApis;
