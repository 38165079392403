import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import LoginModal from "./components/loginModal/loginModal";
// components
import Routes from "./routes";
import { getTheme } from "./store/Actions/themeConfigurationAction";
import DynamicTheme from "./api/services/dynamicTheme/dynamicTheme";

function App() {
  const dispatch = useDispatch();
  const customTheme = useSelector((state) => state.themeConfiguration);

  const globalStyles = `
    ::selection {
      background-color: ${customTheme.primary_color};
      color: white;
    }
    .custom-bullets {
      border: 1px solid ${customTheme.primary_color} !important;
      &:hover {
        background-color: ${customTheme.secondary_color} !important;
      }
      &:active {
        background-color: ${customTheme.primary_color} !important;
      }
    }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: ${customTheme.secondary_color} !important;
    }
    .image-gallery-icon:hover {
      color: ${customTheme.primary_color} !important;
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus {
      outline: none;
      border: 4px solid ${customTheme.secondary_color} !important;
    }
    .image-gallery-icon:focus {
      outline: 2px solid ${customTheme.secondary_color};
    }
    .image-gallery-thumbnail:hover {
      outline: none;
      border: 4px solid ${customTheme.primary_color} !important;
    }
    .ant-btn-default {
      border-color: ${customTheme.secondary_color} !important;
      color: ${customTheme.secondary_color} !important;
    }
    .ant-btn-default:hover {
      border-color: ${customTheme.primary_color} !important;
      color: ${customTheme.primary_color} !important;
    }
    .ant-btn:hover rect,
    .ant-btn:hover circle {
      stroke: ${customTheme.primary_color};
    }
    .ant-btn:hover .ant-btn-icon path{
      fill: ${customTheme.primary_color};
    }
    .no-hover-icon .ant-btn:hover .ant-btn-icon path{
      fill: revert-layer;
    }
    .ant-btn:hover.ant-btn-button path,
    .ant-btn-secondary rect,
    .ant-btn-secondary circle {
      stroke: white !important;
    }
    .ant-btn-secondary path{
      fill: white !important;
    }
    .ant-select-item-option:hover,
    .ant-select-item-option:active,
    .ant-select-item-option.ant-select-item-option-active {
      color: white !important;
    }
    .nav-item-active {
      background: #fff;
      border-radius: $base-border-radius;
      font-weight: bold;
    
      svg {
        * {
          stroke: ${customTheme.primary_color};
        }
      }
    }
  `;

  useEffect(() => {
    (async () => {
      try {
        const res = await DynamicTheme.GetDynamicTheme();

        dispatch(getTheme(res.data));
      } catch (e) {
        console.log(e, "eee");
      }
    })();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: customTheme.primary_color,
            colorPrimaryHover: customTheme.primary_color,
            colorSecondary: customTheme.secondary_color,
            colorSecondaryHover: customTheme.secondary_color,
            handleShadow: customTheme.primary_color,
            colorPrimaryBgHover: customTheme.primary_color,
            colorSecondaryBgHover: customTheme.secondary_color,
          },
          Switch: {
            colorPrimary: customTheme.primary_color,
            colorPrimaryHover: customTheme.primary_color,
            colorSecondary: customTheme.secondary_color,
            colorSecondaryHover: customTheme.secondary_color,
            handleShadow: customTheme.primary_color,
            colorPrimaryBgHover: customTheme.primary_color,
            colorSecondaryBgHover: customTheme.secondary_color,
          },
          Checkbox: {
            colorPrimary: customTheme.primary_color,
            colorPrimaryHover: customTheme.primary_color,
          },
          Tabs: {
            colorText: customTheme.primary_color,
            colorPrimary: customTheme.primary_color,
            colorPrimaryActive: customTheme.secondary_color,
            colorPrimaryHover: customTheme.secondary_color,
          },
          Select: {
            controlItemBgActive: customTheme.primary_color,
            controlItemBgHover: customTheme.secondary_color,
            colorPrimary: customTheme.secondary_color,
            colorPrimaryHover: customTheme.primary_color,
          },
          Menu: {
            colorPrimary: customTheme.primary_color,
          },
          Input: {
            colorPrimaryHover: customTheme.primary_color,
          },
          DatePicker: {
            cellRangeBorderColor: customTheme.secondary_color,
            colorLink: customTheme.secondary_color,
            colorLinkActive: customTheme.primary_color,
            colorLinkHover: customTheme.primary_color,
            colorPrimary: customTheme.primary_color,
            colorPrimaryHover: customTheme.primary_color,
          },
          Pagination: {
            itemActiveBg: customTheme.primary_color,
            colorPrimary: "white",
            colorPrimaryHover: "white",
          },
        },
      }}
    >
      <style>{globalStyles}</style>
      <Routes />
      <LoginModal />
    </ConfigProvider>
  );
}

export default App;
