/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";
import { Link, useHistory } from "react-router-dom";
import { slugify } from "../../../utils/helpers";
// components
import NbVerticalSideMenu from "../../../assets/gobal/components/nbVertocalSideMenu/nbVerticalSideMenu";

import { getMenuLists } from "./homeSiderMenuLists";
import FullLogo from "../../../assets/icons/dynamicIcons/logoIcon";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";

// hooks
import { notify, useNbRoutes, useProfileSwitchingGuard } from "../../../utils";
// actions
import tokenActions from "../../../store/auth/action";
import { ALL_POSTS_REMOVE } from "../../../store/constants";

// apis
import authApis from "../../../api/services/auth/auth";
// scss
import "../../../assets/gobal/components/nbVertocalSideMenu/nbVerticalSideMenu.scss";
import HomeIcon from "../../../assets/icons/dynamicIcons/homeIcon";
import "./homeSider.scss";
import { NbButton } from "../../../assets/gobal/components/CustomButton";
import communityApis from "../../../api/services/community/community";
import ConfirmModal from "../../../assets/gobal/components/confirmationModal/confirmationModal";

function HomeSider() {
  const me = useSelector((state) => state.me);
  const customTheme = useSelector((state) => state.themeConfiguration);
  const switchingGuard = useProfileSwitchingGuard();
  const history = useHistory();
  const dispatch = useDispatch();
  const nbRoutes = useNbRoutes();

  const { menuList1, menuListForAllVerified } = getMenuLists();

  const [activeMenuItem, setActiveMenuItem] = useState(menuList1[0]?.key);

  const leaveCommunity = async (id) => {
    const payload = {
      neighbourhood_id: id,
    };
    ConfirmModal(
      {
        title: "Are you sure want to leave the community?",
      },
      async (confirm) => {
        if (confirm) {
          try {
            const res = await communityApis.leaveCommunity(payload);
            if (res.data) {
              const updatedCommunities = [
                ...me.communities.filter((p) => p?.neighbourhood_id !== id),
              ];
              dispatch(
                tokenActions.saveMe({
                  ...me,
                  communities: [...updatedCommunities],
                })
              );
              notify("Left community successfully", "success");
            }
          } catch (err) {
            console.log(err);
            notify(err?.result?.[0] || err?.message, "error");
          }
        }
      }
    );
  };

  const getFormattedMenuListCommunities = (communities) => {
    return communities?.map((community) => {
      const communitySlug = slugify(community.name);
      return {
        key: community.neighbourhood_id,
        label: community.name,
        to: `/home/${communitySlug}/${community.neighbourhood_id}`,
        icon: "",
        // antd menu Props
        moreOptions: {
          items: [
            {
              key: `0${community.neighbourhood_id}`,
              label: "View Community Members",
              onClick: () => {
                history.push({
                  pathname: `/community-members`,
                  search: `?community_id=${community.neighbourhood_id}`,
                });
              },
            },
            {
              key: `1${community.neighbourhood_id}`,
              label: "Leave Community",
              onClick: () => {
                leaveCommunity(community.neighbourhood_id);
              },
            },
          ],
        },
      };
    });
  };

  const logout = async () => {
    try {
      await authApis.userLogout();
      dispatch(tokenActions.Token(null));
      dispatch(tokenActions.clearMe());
      window.localStorage.removeItem("signupAuthToken");
      if (nbRoutes.private) {
        nbRoutes.gotoLogin();
      }
      dispatch({
        type: ALL_POSTS_REMOVE,
      });
    } catch (err) {
      notify(err?.message, "error");
    }
  };

  return (
    <>
      <Link className="custom-logo ml6" to="/home/all">
        {customTheme.logo_url ? (
          <img src={customTheme.logo_url} alt="logo" />
        ) : (
          <FullLogo fillColor={customTheme.primary_color} />
        )}
      </Link>
      <Divider style={{ margin: "0.3rem  1rem 2rem 0" }} />
      {switchingGuard.isUser() && (
        <>
          {menuListForAllVerified.map((menuListItem, i) => {
            return (
              <NbVerticalSideMenu
                key={menuListItem.menuList.title}
                title={menuListItem.menuList.title}
                onItemClick={(item) => setActiveMenuItem(item.key)}
                active={activeMenuItem}
                menuList={menuListItem.menuList?.list.map((listItem) =>
                  listItem.key === "5"
                    ? {
                        ...listItem,
                        subMenu: me.communities?.length > 0 && (
                          <div className="nb-comunities-container">
                            <NbVerticalSideMenu
                              title=""
                              onItemClick={(item) =>
                                setActiveMenuItem(item.key)
                              }
                              active={activeMenuItem}
                              menuList={getFormattedMenuListCommunities(
                                me.communities
                              )}
                              className="communities"
                            />
                          </div>
                        ),
                      }
                    : { ...listItem }
                )}
                moreMenuList={menuListItem.menuList?.moreList}
                className="mainSider"
                // subMenuList={
                //   me.communities?.length > 0 && (
                //     <div className="nb-comunities-container">
                //       <NbVerticalSideMenu
                //         title=""
                //         onItemClick={(item) => setActiveMenuItem(item.key)}
                //         active={activeMenuItem}
                //         menuList={getFormattedMenuListCommunities(
                //           me.communities
                //         )}
                //         className="communities"
                //       />
                //     </div>
                //   )
                // }
              />
            );
          })}
          {/* communities for users of verified user */}
          {/* {me.communities?.length > 0 && (
            <div className="nb-comunities-container">
              <NbVerticalSideMenu
                title=""
                onItemClick={(item) => setActiveMenuItem(item.key)}
                active={activeMenuItem}
                menuList={getFormattedMenuListCommunities(me.communities)}
                className="communities"
              />
            </div>
          )} */}
          {me.is_login && (
            <div className="nb-vertical-side-menu ">
              <div className="nb-vertical-side-menu-item logout">
                <NbButton
                  type="outlined"
                  className="py5"
                  style={{ padding: "20px" }}
                  onClick={() => logout()}
                  icon={Logout}
                  btnText="Logout"
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HomeSider;
