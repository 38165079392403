import { Get } from "../../requests";

const GetDynamicTheme = () => {
  return Get("/site-setting");
};

const DynamicTheme = {
  GetDynamicTheme,
};

export default DynamicTheme;
