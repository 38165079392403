import { SET_THEME } from "../constants";

import postApis from "../../api/services/post/post";
import { store } from "../createStore";

// GET THEME
export function getTheme(payload) {
  return async (dispatch) => {
    dispatch({
      type: SET_THEME,
      payload,
    });
  };
}